import React from "react"
import {
  PageContentLayout,
  PageMain,
  PageRightSidebar,
} from "../../../../components/v2/layout/layout"
import { useCourse } from "../../../../hooks/data/courses/courses"
import Loader from "../../../../components/ui/loader"
import { generatePath, Link, Redirect, Route, Switch } from "react-router-dom"
import * as routes from "../../../../library/constants/routes"
import logoDefault from "../../../../assets/img/product.svg"
import { useIdParam } from "../../../../hooks/useIdParam"
import CourseSettingsSteps from "./CourseSettingsSteps/CourseSettingsSteps"
import CourseSettingsMain from "./CourseSettingsMain/CourseSettingsMain"
import CourseSettingsMembers from "./CourseSettingsMembers/CourseSettingsMembers"
import CourseSettingsRelease from "./CourseSettingsRelease/CourseSettingsRelease"
import VerticalMenu from "../../../../components/ui/verticalMenu"

const CourseSettings = () => {
  const knowmixId = useIdParam("knowmixId")
  const { course, isAvailable, canEdit, isSuccess, isLoading, error } =
    useCourse()

  if (!isSuccess && isLoading) return <Loader />

  if (
    [23000, 23001, 23002, 20004].includes(error?.error?.code) ||
    !(
      course?.is_admin ||
      course?.is_teacher ||
      course?.is_creator ||
      course?.is_product_admin
    ) ||
    course?.deleted ||
    course?.blocked
  ) {
    return (
      <Redirect
        to={generatePath(routes.KNOWMIX, {
          knowmixId,
        })}
      />
    )
  }

  const menuItems = [
    {
      path: generatePath(routes.KNOWMIX_SETTINGS, { knowmixId }),
      label: "Main information",
    },
    {
      path: generatePath(routes.KNOWMIX_SETTINGS_STEPS, { knowmixId }),
      label: "Steps",
    },
    {
      path: generatePath(routes.KNOWMIX_SETTINGS_MEMBERS, { knowmixId }),
      label: "Members",
    },
    {
      path: generatePath(routes.KNOWMIX_SETTINGS_PUBLIC, { knowmixId }),
      label: "Release",
    },
  ].filter(Boolean)

  return isSuccess ? (
    <PageContentLayout>
      <PageMain>
        <Switch>
          <Route exact path={routes.KNOWMIX_SETTINGS}>
            <CourseSettingsMain />
          </Route>
          <Route exact path={routes.KNOWMIX_SETTINGS_STEPS}>
            <CourseSettingsSteps />
          </Route>
          <Route exact path={routes.KNOWMIX_SETTINGS_MEMBERS}>
            <CourseSettingsMembers />
          </Route>
          <Route exact path={routes.KNOWMIX_SETTINGS_PUBLIC}>
            <CourseSettingsRelease />
          </Route>
        </Switch>
      </PageMain>
      <PageRightSidebar>
        <div className="box aside-header">
          <Link
            to={generatePath(routes.KNOWMIX, {
              knowmixId,
            })}
            className="box__content aside-header__content"
          >
            <img
              src={course?.photo || logoDefault}
              className="aside-header__img"
              alt=""
            />
            <div className="aside-header__info">
              <div className="aside-header__name">{course?.name}</div>
              <div className="aside-header__help">back to course</div>
            </div>
          </Link>
        </div>
        {!!course?.creator_group?.id && (
          <div className="box aside-header">
            <Link
              to={generatePath(routes.GROUP_SETTINGS_KNOWMIX, {
                groupId: course?.creator_group?.id,
              })}
              target={"_blank"}
              className="box__content aside-header__content"
            >
              <div className="color-primary font-weight-500">
                Open group courses
              </div>
            </Link>
          </div>
        )}
        <VerticalMenu menuItems={menuItems} />
      </PageRightSidebar>
    </PageContentLayout>
  ) : (
    <Loader />
  )
}

export default CourseSettings
