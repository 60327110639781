export const CALENDARS = {
  LIST: "calendars.list",
  CLEAR_LIST: "calendars.clear_list",
  CREATE: "calendars.create",
  UPDATE: "calendars.update",
  DELETE: "calendars.delete",
  GET: "calendars.get",
  COMPLETE: "calendars.complete",
  CLEAR_CURRENT_CALENDAR: "calendars.clear_current_calendar",

  EVENTS: {
    LIST: "events.list",
    CLEAR_LIST: "events.clear_list",
    GET: "events.get",
    CLEAR_CURRENT_EVENT: "events.clear_current_event",
    CREATE: "events.create",
    UPDATE: "events.update",
    DELETE: "events.delete",
  },

  BLOCKS: {
    LIST: "blocks.list",
    CLEAR_LIST: "blocks.clear_list",
    GET: "blocks.get",
    CLEAR_CURRENT_BLOCK: "blocks.clear_current_block",
    CREATE: "blocks.create",
    UPDATE: "blocks.update",
    DELETE: "blocks.delete",
  },

  HOLDS: {
    LIST: "holds.list",
    USER_LIST: "public_holds.list",
    CLEAR_LIST: "holds.clear_list",
    GET: "holds.get",
    CLEAR_CURRENT_BLOCK: "holds.clear_current_hold",
    CREATE: "holds.create",
    UPDATE: "holds.update",
    DELETE: "holds.delete",
  },

  APPOINTMENTS: {
    GET: "appointments.get",
    CREATE: "appointments.create",
    UPDATE: "appointments.update",
    DELETE: "appointments.delete",
  },
}

export const SCHEDULE = {
  UNITS: {
    LIST: "units.list",
    CLEAR_LIST: "units.clear_list",
    CREATE: "units.create",
    UPDATE: "units.update",
    GET: "units.get",
    DELETE: "units.delete",
    CLEAR_CURRENT_UNIT: "units.clear_current_unit",
  },

  SUBJECTS: {
    LIST: "subjects.list",
    CLEAR_LIST: "subjects.clear_list",
    CREATE: "subjects.create",
    UPDATE: "subjects.update",
    GET: "subjects.get",
    DELETE: "subjects.delete",
    CLEAR_CURRENT_SUBJECT: "subjects.clear_current_subject",
    ADD_MEMBER: "subjects.addMember",
    REMOVE_MEMBER: "subjects.removeMember",
    LIST_MEMBERS: "subjects.membersList",
    CLEAR_MEMBERS_LIST: "subjects.clear_members_list",
    USER_LIST: "subjects.listForStudent",
    CLEAR_USER_LIST: "subjects.clear_listForStudent",
  },

  SCHEDULES: {
    LIST: "schedules.list",
    CLEAR_LIST: "schedules.clear_list",
    GET: "schedules.get",
    CREATE: "schedules.create",
    UPDATE: "schedules.update",
    DELETE: "schedules.delete",
    CLEAR_CURRENT_SCHEDULE: "schedules.clear_current_schedule",

    PRIVATE_SCHOOL_CREATE: "schedules.private_school_create",
    PRIVATE_SCHOOL_DELETE: "schedules.private_school_delete",

    CALENDARS: {
      LIST: "schedules.calendars.list",
      CLEAR_LIST: "schedules.calendars.clear_list",
      GET: "schedules.calendars.get",
    },

    GET_FOR_MANAGE: "schedules.getForManage",
  },
}

export const APPOINTMENTS = {
  GET: "book_appointments.get",
  USER_INFO: "book_appointments.get_user",
  PUBLIC_HOLDS: "book_appointments.list_hold",
  AVAILABLE_SLOTS: "book_appointments.list_calendar",
  CREATE: "book_appointments.create",
  UPDATE_TYPES: "book_appointments.update_type",
  GET_TYPES: "book_appointments.list_type",
  LIST: "book_appointments.list",
  ACCEPT: "book_appointments.accept",
  DECLINE: "book_appointments.decline",
  CANCEL: "book_appointments.cancel",
  FORWARD: "book_appointments.forward",
  REMOVE: "book_appointments.remove",
  SOLICIT: {
    CREATE: "solicit_appointments.create",
    ACCEPT: "solicit_appointments.accept",
    DECLINE: "solicit_appointments.decline",
    CANCEL: "solicit_appointments.cancel",
    FORWARD: "solicit_appointments.forward",
    LIST: "solicit_appointments.list",
    GET: "solicit_appointments.get",
    GET_PUBLIC: "solicit_appointments.get_public",
    REMOVE: "solicit_appointments.remove",
  },
}
