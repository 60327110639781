import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import { Button } from "@material-ui/core"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Radio from "@material-ui/core/Radio"
import FormControl from "@material-ui/core/FormControl"
import {
  deleteGroup,
  updateGroup,
} from "../../../../../../library/store/actions/creators/groupsCreators"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import { generatePath } from "react-router-dom"
import * as routes from "../../../../../../library/constants/routes"
import { DateTimePicker } from "@material-ui/pickers"
import defaultDate, {
  defaultMaxDate,
} from "../../../../../../library/constants/defaultDates"
import { GROUP_TYPES } from "../../../../../../library/constants/groupTypes"
import getErrorText from "../../../../../../library/constants/errorTypes"
import { GROUPS } from "../../../../../../library/store/actions/types/groupsTypes"
import { safeGetEntity } from "../../../../../../library/utils/entities"
import GroupPicker from "../../../../../../components/v2/inputs/pickers/groupPicker"

class GroupSettingsMain extends Component {
  state = {
    type: this.props.groups.currentGroup.type,
    name: this.props.groups.currentGroup.name,
    district: safeGetEntity(this.props.groups.currentGroup?.district) || null,
    description: this.props.groups.currentGroup.description,
    closed: this.props.groups.currentGroup.closed,
    start_date: this.props.groups.currentGroup.start_date,
    end_date:
      this.props.groups.currentGroup.end_date !==
      this.props.groups.currentGroup.start_date
        ? this.props.groups.currentGroup.end_date
        : "",
    website: this.props.groups.currentGroup.website,
    country: this.props.groups.currentGroup.country,
    state: this.props.groups.currentGroup.state,
    city: this.props.groups.currentGroup.city,
    address: this.props.groups.currentGroup.address,
    zip_code: this.props.groups.currentGroup.zip_code,
    phone: this.props.groups.currentGroup.phone,
    isCreator: this.props.groups.currentGroup.is_creator,
    open: false,
  }

  async componentDidMount() {
    ValidatorForm.addValidationRule("isLink", (value) => {
      if (!value) return true
      return /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/.test(
        value
      )
    })
    ValidatorForm.addValidationRule("isPhone", (value) => {
      if (!value) return true
      return /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/.test(value)
    })
  }

  handleGroupAccess = (name) => (event) => {
    this.setState({
      closed: !JSON.parse(event.target.value),
    })
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    })
  }

  handleDateChange = (date, name) => {
    const parsedDate = date ? new Date(date).toISOString() : null
    this.setState({ [name]: parsedDate })
  }

  handleClickOpen = () => {
    if (this.state.type !== 5) {
      this.setState({ open: true })
    }
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  proceedDeleteGroup = async () => {
    const { id } = this.props.groups.currentGroup
    await this.props.deleteGroup({ id })
    this.handleClose()
    this.props.history.push({
      pathname: generatePath(routes.GROUP, { groupId: id }),
    })
  }

  processInfoSave = async () => {
    const { id, images, videos, blobs } = this.props.groups.currentGroup

    const {
      name,
      description,
      closed,
      start_date,
      end_date,
      website,
      country,
      state,
      city,
      address,
      zip_code,
      phone,
      type,
      district,
    } = this.state

    try {
      await this.props.updateGroup({
        id,
        name,
        description,
        closed,
        start_date,
        district_id: type === 4 ? district?.id || undefined : undefined,
        end_date:
          type === 2 ? (end_date ? end_date : defaultMaxDate) : undefined,
        website,
        country,
        state,
        city,
        address,
        zip_code,
        phone,
        attachments: [
          ...images.map((i) => i.id),
          ...videos.map((v) => v.id),
          ...blobs.map((b) => b.id),
        ],
      })
      await this.props.enqueueSnackbar("Information updated", {
        variant: "success",
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  render() {
    const {
      type,
      name,
      description,
      closed,
      start_date,
      end_date,
      website,
      country,
      state,
      city,
      address,
      zip_code,
      phone,
      isCreator,
      district,
    } = this.state

    const requireAddress = [3, 4, 5]
    const title = GROUP_TYPES[type].title.toLowerCase()
    return (
      <div className="box">
        <div className="box__heading">Main information</div>
        <div className="box__content">
          <ValidatorForm className="form" onSubmit={this.processInfoSave}>
            <div className="form__fieldset">
              <label htmlFor="name" className="form__label">
                {type === 1
                  ? "Group"
                  : GROUP_TYPES[type].compactTitle ||
                    GROUP_TYPES[type].title}{" "}
                name *
              </label>
              <div className="form__fieldbox">
                <div className="form__input">
                  <TextValidator
                    id="name"
                    name="name"
                    value={name}
                    placeholder={`Type ${type === 1 ? "group" : title} name`}
                    margin="normal"
                    fullWidth
                    validators={[
                      "required",
                      "minStringLength:3",
                      "maxStringLength:100",
                    ]}
                    errorMessages={[
                      "Field is required",
                      "Min length is 3 characters",
                      "Max length is 100 characters",
                    ]}
                    withRequiredValidator
                    onChange={this.handleChange("name")}
                  />
                </div>
              </div>
            </div>

            {type === 4 && (
              <GroupPicker
                value={district}
                onChange={(g) => this.setState({ district: g })}
                requestConfig={{
                  fetchConfig: {
                    apiUrl: GROUPS.SEARCH,
                    params: {
                      type: 5,
                    },
                  },
                  key: "groups",
                }}
                fieldName={"districts"}
                entityName={"district"}
              />
            )}

            <div className="form__fieldset">
              <label htmlFor="description" className="form__label">
                Description *
              </label>
              <div className="form__fieldbox">
                <div className="form__input">
                  <TextValidator
                    id="description"
                    name="description"
                    value={description}
                    placeholder={`Type a short ${
                      type === 1 ? "group" : title
                    } description`}
                    margin="normal"
                    fullWidth
                    multiline
                    validators={["required", "maxStringLength:5000"]}
                    errorMessages={[
                      "Field is required",
                      "Max length is 5000 characters",
                    ]}
                    withRequiredValidator
                    onChange={this.handleChange("description")}
                  />
                </div>
              </div>
            </div>

            <div className="form__fieldset">
              <label htmlFor="group-type" className="form__label">
                {type !== 2 ? "Group type *" : "Event type *"}
              </label>

              <div className="form__fieldbox">
                <div className="form__input">
                  <FormControl
                    component="fieldset"
                    id="group-type"
                    disabled={this.props.groups.currentGroup.paid}
                    fullWidth
                  >
                    <RadioGroup
                      aria-label="group-type"
                      name="groupType"
                      value={(!closed).toString()}
                      onChange={this.handleGroupAccess("closed")}
                    >
                      <FormControlLabel
                        value="true"
                        control={<Radio color="primary" />}
                        label="Open"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio color="primary" />}
                        label="Closed"
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  </FormControl>
                  <span className={"mt10 color-black-54"}>
                    Paid groups can only be closed
                  </span>
                </div>
              </div>
            </div>

            {type === 2 && (
              <>
                <div className="form__fieldset">
                  <label htmlFor="start-date" className="form__label">
                    Start date *
                  </label>
                  <div className="form__fieldbox">
                    <div className="form__input">
                      <DateTimePicker
                        id="start-date"
                        value={start_date !== defaultDate ? start_date : null}
                        emptyLabel="Select start date"
                        fullWidth
                        minDate={
                          new Date(start_date) >= new Date()
                            ? new Date()
                            : new Date(start_date)
                        }
                        format="d MMMM 'at' h:mm a"
                        margin="normal"
                        // disablePast
                        maxDate={
                          end_date ? new Date(end_date) : new Date(2100, 11, 31)
                        }
                        onChange={(date) =>
                          this.handleDateChange(date, "start_date")
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="form__fieldset">
                  <label htmlFor="finish-date" className="form__label">
                    End date
                  </label>
                  <div className="form__fieldbox">
                    <div className="form__input">
                      <DateTimePicker
                        id="finish-date"
                        value={end_date !== defaultMaxDate ? end_date : null}
                        emptyLabel="Select finish date"
                        fullWidth
                        clearable
                        invalidLabel="Not selected"
                        format="d MMMM 'at' h:mm a"
                        minDate={new Date(start_date)}
                        margin="normal"
                        onChange={(date) =>
                          this.handleDateChange(date, "end_date")
                        }
                      />
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className="form__fieldset">
              <label htmlFor="website" className="form__label">
                {`Website${type === 3 ? " *" : ""}`}
              </label>
              <div className="form__fieldbox">
                <div className="form__input">
                  <TextValidator
                    id="website"
                    name="website"
                    value={website}
                    placeholder={`Type ${type === 1 ? "group" : title} website`}
                    margin="normal"
                    fullWidth
                    validators={
                      type === 3
                        ? ["required", "isLink", "maxStringLength:250"]
                        : ["isLink", "maxStringLength:250"]
                    }
                    errorMessages={
                      type === 3
                        ? [
                            "Field is required",
                            "Invalid URL",
                            "Max length is 250 characters",
                          ]
                        : ["Invalid URL", "Max length is 250 characters"]
                    }
                    withRequiredValidator
                    onChange={this.handleChange("website")}
                  />
                </div>
              </div>
            </div>

            <div className="form__divider"></div>

            <div className="form__fieldset">
              <label htmlFor="country" className="form__label">
                {`Country${requireAddress.includes(type) ? " *" : ""}`}
              </label>
              <div className="form__fieldbox">
                <div className="form__input">
                  <TextValidator
                    id="country"
                    name="country"
                    value={country}
                    placeholder="Type country"
                    margin="normal"
                    fullWidth
                    validators={
                      requireAddress.includes(type)
                        ? ["required", "maxStringLength:50"]
                        : ["maxStringLength:50"]
                    }
                    errorMessages={
                      requireAddress.includes(type)
                        ? ["Field is required", "Max length is 50 characters"]
                        : ["Max length is 50 characters"]
                    }
                    withRequiredValidator
                    onChange={this.handleChange("country")}
                  />
                </div>
              </div>
            </div>

            <div className="form__fieldset">
              <label htmlFor="state" className="form__label">
                {`State${requireAddress.includes(type) ? " *" : ""}`}
              </label>
              <div className="form__fieldbox">
                <div className="form__input">
                  <TextValidator
                    id="state"
                    name="state"
                    value={state}
                    placeholder="Type state"
                    margin="normal"
                    fullWidth
                    validators={
                      requireAddress.includes(type)
                        ? ["required", "maxStringLength:50"]
                        : ["maxStringLength:50"]
                    }
                    errorMessages={
                      requireAddress.includes(type)
                        ? ["Field is required", "Max length is 50 characters"]
                        : ["Max length is 50 characters"]
                    }
                    withRequiredValidator
                    onChange={this.handleChange("state")}
                  />
                </div>
              </div>
            </div>

            <div className="form__fieldset">
              <label htmlFor="city" className="form__label">
                {`City${requireAddress.includes(type) ? " *" : ""}`}
              </label>
              <div className="form__fieldbox">
                <div className="form__input">
                  <TextValidator
                    id="city"
                    name="city"
                    value={city}
                    placeholder="Type city"
                    margin="normal"
                    fullWidth
                    validators={
                      requireAddress.includes(type)
                        ? ["required", "maxStringLength:50"]
                        : ["maxStringLength:50"]
                    }
                    errorMessages={
                      requireAddress.includes(type)
                        ? ["Field is required", "Max length is 50 characters"]
                        : ["Max length is 50 characters"]
                    }
                    withRequiredValidator
                    onChange={this.handleChange("city")}
                  />
                </div>
              </div>
            </div>

            <div className="form__fieldset">
              <label htmlFor="address" className="form__label">
                {`Address${requireAddress.includes(type) ? " *" : ""}`}
              </label>
              <div className="form__fieldbox">
                <div className="form__input">
                  <TextValidator
                    id="address"
                    name="address"
                    value={address}
                    placeholder="Type address"
                    margin="normal"
                    fullWidth
                    validators={
                      requireAddress.includes(type)
                        ? ["required", "maxStringLength:150"]
                        : ["maxStringLength:150"]
                    }
                    errorMessages={
                      requireAddress.includes(type)
                        ? ["Field is required", "Max length is 150 characters"]
                        : ["Max length is 150 characters"]
                    }
                    withRequiredValidator
                    onChange={this.handleChange("address")}
                  />
                </div>
              </div>
            </div>

            <div className="form__fieldset">
              <label htmlFor="zip" className="form__label">
                {`Zip code${requireAddress.includes(type) ? " *" : ""}`}
              </label>
              <div className="form__fieldbox">
                <div className="form__input">
                  <TextValidator
                    id="zip"
                    name="zip"
                    value={zip_code}
                    placeholder="Type zip code"
                    margin="normal"
                    fullWidth
                    validators={
                      requireAddress.includes(type)
                        ? ["required", "maxStringLength:30"]
                        : ["maxStringLength:30"]
                    }
                    errorMessages={
                      requireAddress.includes(type)
                        ? ["Field is required", "Max length is 30 characters"]
                        : ["Max length is 30 characters"]
                    }
                    withRequiredValidator
                    onChange={this.handleChange("zip_code")}
                  />
                </div>
              </div>
            </div>

            <div className="form__fieldset">
              <label htmlFor="phone" className="form__label">
                {`Phone number${requireAddress.includes(type) ? " *" : ""}`}
              </label>
              <div className="form__fieldbox">
                <div className="form__input">
                  <TextValidator
                    id="phone"
                    name="phone"
                    value={phone}
                    placeholder="Type phone number"
                    margin="normal"
                    fullWidth
                    validators={
                      requireAddress.includes(type)
                        ? ["required", "maxStringLength:15"]
                        : ["maxStringLength:15"]
                    }
                    errorMessages={
                      requireAddress.includes(type)
                        ? ["Field is required", "Max length is 15 characters"]
                        : ["Max length is 15 characters"]
                    }
                    withRequiredValidator
                    onChange={this.handleChange("phone")}
                  />
                </div>
              </div>
            </div>

            <Button
              color="primary"
              variant="contained"
              type="submit"
              className="form__submit mb15"
            >
              Save
            </Button>

            {isCreator && (
              <>
                <div className="form__divider"></div>
                <div className="f jcc aic color-black-54">
                  <div>
                    You can{" "}
                    <span
                      className={`color-primary ${type !== 5 ? "pointer" : ""}`}
                      onClick={() => this.handleClickOpen()}
                    >
                      delete this group.
                    </span>
                  </div>
                </div>
              </>
            )}
          </ValidatorForm>

          {this.state.open && (
            <Dialog
              open={this.state.open}
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Delete group from site?
              </DialogTitle>
              <DialogContent>
                <p className="color-black-54 font-16">
                  If you delete group you will not be able to view information
                  on the site. You can restore the group only through the
                  support site.
                </p>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleClose} color="primary">
                  Decline
                </Button>
                <Button
                  onClick={this.proceedDeleteGroup}
                  color="primary"
                  autoFocus
                >
                  Accept
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ auth, groups }) => ({ auth, groups })
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateGroup,
      deleteGroup,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(GroupSettingsMain))
