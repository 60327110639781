import React, { Component } from "react"
import "./group.scss"
import logoDefault from "../../../../assets/img/group-logo-default.png"
import companyIcon from "../../../../assets/img/group-icons/company-icon.svg"
import districtIcon from "../../../../assets/img/group-icons/district-icon.svg"
import schoolIcon from "../../../../assets/img/group-icons/school-icon.svg"
import oneTeacherSchoolIcon from "../../../../assets/img/group-icons/tutor-icon.svg"
import interestsIcon from "../../../../assets/img/group-icons/regular-group-icon.svg"
import eventIcon from "../../../../assets/img/group-icons/event-group-icon.svg"
import { generatePath, Link, Route } from "react-router-dom"
import * as routes from "../../../../library/constants/routes"
import SettingsOutlined from "@material-ui/icons/SettingsOutlined"
import { bindActionCreators } from "redux"
import {
  clearCurrentGroup,
  getGroup,
  getProductsLibrary,
  updateGroup,
} from "../../../../library/store/actions/creators/groupsCreators"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import Loader from "../../../../components/ui/loader"
import GroupHeader from "./groupComponents/groupHeader"
import BoxedList from "../../../../components/ui/boxedList"
import CameraAltOutlined from "@material-ui/icons/CameraAltOutlined"
import { uploadFiles } from "../../../../library/utils/uploadFiles"
import Button from "@material-ui/core/Button"
import GroupPosts from "./groupPosts/groupPosts"
import PostModal from "../../feed/postModal/postModal"
import NotFound from "../../../../components/notFound/notFound"
import GroupMultiTypesHorizontalList from "./groupComponents/groupMultiTypesHorizontalList"
import GroupError from "../groupError/groupError"
import ReplyOutlined from "@material-ui/icons/ReplyOutlined"
import { sharePost } from "../../../../library/store/actions/creators/postsCreators"
import getErrorText from "../../../../library/constants/errorTypes"
import AddToFolderModal from "../../bookmarks/folders/addToFolder/addToFolder"
import StarOutlined from "@material-ui/icons/StarOutlined"
import StarBorderOutlined from "@material-ui/icons/StarBorderOutlined"
import ErrorOutline from "@material-ui/icons/ErrorOutline"
import HowToRegIcon from "@material-ui/icons/HowToReg"
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined"
import {
  createBookmark,
  deleteBookmark,
  foldersList,
} from "../../../../library/store/actions/creators/bookmarksCreators"
import ComplaintsModal from "../../../../components/complaints/complaintsModal"
import ImagePickerModal from "../../../../components/imagePickerModal/imagePickerModal"
import { imageAspectRatios } from "../../../../library/constants/images"
import PublicCalendar from "./publicCalendar/publicCalendar"
import PlainLink from "../../../../components/ui/link"
import CalendarProvider from "../../calendar/CalendarContext"
import SaveEventToCalendar from "./SaveEventToCalendar"

class Group extends Component {
  state = {
    isClosed: this.props.groups.currentGroup
      ? this.props.groups.currentGroup.closed
      : true,
    participantsList: this.props.groups.currentGroup
      ? this.props.groups.currentGroup.participants
      : [],
    participantsNumber: this.props.groups.currentGroup
      ? this.props.groups.currentGroup.participants_number
      : 0,
    logo: null,
    openAvatarUpdate: false,
    uploadProgress: "",
    isUploading: false,
    dataReceived: false,
    error404: false,
    groupBlocked: false,
    groupDeleted: false,
    currentGroupAttachmentProducts: [],
    openAddToFolderDialog: false,
    bookmarked: this.props.groups.currentGroup
      ? !!this.props.groups.currentGroup.bookmark
      : false,
    complaintsModal: false,
    isReported: this.props.groups.currentGroup
      ? !!this.props.groups.currentGroup.is_reported
      : false,
    showCalendar: false,
    showSaveEventToCalendar: false,
  }

  showComplaintsModal = () => {
    this.setState({ complaintsModal: true })
  }
  hideComplaintsModal = () => {
    this.setState({ complaintsModal: false })
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve)
    })
  }

  async componentDidMount() {
    const id = parseInt(this.props.match.params.groupId)

    try {
      await this.props.getGroup({ id })

      if (!this.props.groups.currentGroup.tags.includes(1)) {
      }
      if (!this.props.groups.currentGroup.deleted) {
        await this.props.getProductsLibrary({ group_id: id })
      }
      await this.setStateAsync({
        isClosed: this.props.groups.currentGroup.closed,
        participantsList: this.props.groups.currentGroup.participants,
        participantsNumber: this.props.groups.currentGroup.participants_number,
        groupDeleted: this.props.groups.currentGroup.deleted,
        groupBlocked: this.props.groups.currentGroup.blocked,
        bookmarked: !!this.props.groups.currentGroup.bookmark,
        isReported: this.props.groups.currentGroup.is_reported,
        currentGroupAttachmentProducts:
          this.props.groups.currentGroupAttachmentProducts,
        dataReceived: true,
      })
    } catch ({ error }) {
      if (error.code === 23000) {
        return this.setState({ error404: true })
      } else {
        this.props.enqueueSnackbar(getErrorText(error.code), {
          variant: "error",
        })
      }
    }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const id = parseInt(this.props.match.params.groupId)

    if (this.props.match.params.groupId !== prevProps.match.params.groupId) {
      await this.setStateAsync({ dataReceived: false })
      try {
        await this.props.getGroup({ id })
        await this.setStateAsync({
          isClosed: this.props.groups.currentGroup.closed,
          dataReceived: true,
        })
      } catch ({ error }) {
        this.props.enqueueSnackbar(getErrorText(error.code), {
          variant: "error",
        })
      }
    } else if (
      this.props.groups.currentGroup &&
      this.props.groups.currentGroup !== prevProps.groups.currentGroup
    ) {
      this.setState({
        participantsList:
          this.props.groups.currentGroup &&
          this.props.groups.currentGroup.participants,
        participantsNumber:
          this.props.groups.currentGroup &&
          this.props.groups.currentGroup.participants_number,
        logo:
          this.props.groups.currentGroup && this.props.groups.currentGroup.logo,
        groupDeleted: this.props.groups.currentGroup.deleted,
        groupBlocked: this.props.groups.currentGroup.blocked,
        bookmarked: !!this.props.groups.currentGroup.bookmark,
      })
    }
    // else if (this.props.groups.currentGroup.logo !== prevProps.groups.currentGroup.logo) {
    //     this.setState({logo: this.props.groups.currentGroup.logo})
    // }
  }

  onUploadProgress = (progressEvent) => {
    let percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    )
    this.setState({ uploadProgress: percentCompleted })
  }

  handleClickOpen = (type) => {
    switch (type) {
      case "addToFolder":
        this.setState({ openAddToFolderDialog: true })
        break

      case "avatar":
        this.setState({ openAvatarUpdate: true })
        break

      default:
        return
    }
  }

  handleClose = (type) => {
    switch (type) {
      case "addToFolder":
        this.setState({ openAddToFolderDialog: false })
        break

      case "avatar":
        this.setState({ openAvatarUpdate: false })
        break

      default:
        return
    }
  }

  processLogoUpdate = async (e) => {
    const {
      auth: { userData },
    } = this.props
    const { id, images, blobs, videos } = this.props.groups.currentGroup
    const fileData = e.target.files[0]

    this.setStateAsync({ isUploading: true })
    try {
      this.setState({ logo: URL.createObjectURL(fileData) })
      const response = await uploadFiles(
        fileData,
        2,
        this.onUploadProgress,
        userData.token
      )
      await this.setStateAsync({
        isUploading: false,
        uploadProgress: "",
      })
      await this.props.updateGroup({
        id,
        logo: response.url,
        attachments: [
          ...videos.map((f) => f.id),
          ...blobs.map((f) => f.id),
          ...images.map((f) => f.id),
        ],
      })
      await this.props.getGroup({ id })
    } catch ({ error }) {
      this.setState({ logo: null })
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
    document.getElementById("logo-input").value = null
  }

  processLogoDelete = async () => {
    const { id, videos, blobs, images } = this.props.groups.currentGroup

    const defaultLogo = () => {
      switch (this.props.groups.currentGroup.type) {
        case 1:
          return interestsIcon

        case 2:
          return eventIcon

        case 3:
          return companyIcon

        case 4:
          return schoolIcon

        case 5:
          return districtIcon

        case 7:
          return oneTeacherSchoolIcon

        default:
          return logoDefault
      }
    }

    try {
      await this.props.updateGroup({
        id,
        logo: "",
        attachments: [
          ...videos.map((f) => f.id),
          ...blobs.map((f) => f.id),
          ...images.map((f) => f.id),
        ],
      })
      await this.setStateAsync({ logo: defaultLogo() })
      await this.props.getGroup({ id })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  updateImage = async (img) => {
    const defaultLogo = () => {
      switch (this.props.groups.currentGroup.type) {
        case 1:
          return interestsIcon

        case 2:
          return eventIcon

        case 3:
          return companyIcon

        case 4:
          return schoolIcon

        case 5:
          return districtIcon

        case 7:
          return oneTeacherSchoolIcon

        default:
          return logoDefault
      }
    }

    this.setState({ logo: img.cropped_image || defaultLogo() })
    await this.props.updateGroup({
      id: parseInt(this.props.match.params.groupId),
      full_image: img,
    })
    await this.props.getGroup({ id: parseInt(this.props.match.params.groupId) })
  }

  processShare = async () => {
    const { groups } = this.props
    try {
      await this.props.sharePost({ group_id: groups.currentGroup.id })
      await this.props.getGroup({ id: groups.currentGroup.id })
      this.props.enqueueSnackbar("Group has been shared", {
        variant: "success",
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  addToBookmarks = async () => {
    const {
      groups: {
        currentGroup: { id },
      },
    } = this.props

    try {
      await this.props.createBookmark({ group_id: id })
      await this.props.foldersList()
      this.setState({ bookmarked: true })
      await this.props.enqueueSnackbar("Saved to bookmarks", {
        autoHideDuration: 5000,
        action: (key) => {
          return (
            <>
              <Button
                color="primary"
                onClick={() => {
                  this.props.closeSnackbar(key)
                  this.props.history.push(routes.BOOKMARKS_GROUPS)
                }}
              >
                View
              </Button>
              {this.props.bookmarks.folders.length > 0 && (
                <Button
                  color="primary"
                  onClick={() => this.handleClickOpen("addToFolder")}
                >
                  Add to folder
                </Button>
              )}
            </>
          )
        },
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  deleteBookmark = async () => {
    const {
      groups: {
        currentGroup: { id },
      },
    } = this.props

    this.props
      .deleteBookmark({ group_id: id })
      .then(this.setState({ bookmarked: false }))
  }

  openSaveEventToCalendar = () => {
    this.setState({ showSaveEventToCalendar: true })
  }

  closeSaveEventToCalendar = () => {
    this.setState({ showSaveEventToCalendar: false })
  }

  render() {
    if (this.state.error404) {
      return <NotFound entity="Group" />
    }

    if (this.state.groupBlocked || this.state.groupDeleted) {
      return <GroupError group={this.props.groups.currentGroup} />
    }

    if (this.state.dataReceived) {
      const {
        logo,
        participantsList,
        participantsNumber,
        bookmarked,
        currentGroupAttachmentProducts,
      } = this.state
      const {
        id,
        type,
        name,
        connections,
        connections_number,
        products,
        products_number,
        closed,
        is_admin,
        is_member,
        creator,
        district,
        schools,
        images,
        videos,
        blobs,
        links,
        is_shared,
        tags,
        teachers_number,
        teachers,
        calendars_number,
        calendars,
        schedules_number,
        schedules,
      } = this.props.groups.currentGroup

      const defaultLogo = () => {
        switch (this.props.groups.currentGroup.type) {
          case 1:
            return interestsIcon

          case 2:
            return eventIcon

          case 3:
            return companyIcon

          case 4:
            return schoolIcon

          case 5:
            return districtIcon

          case 7:
            return oneTeacherSchoolIcon

          default:
            return logoDefault
        }
      }

      const groupLogo = () => {
        if (!this.props.groups.currentGroup.logo) {
          return defaultLogo()
        } else {
          return this.props.groups.currentGroup.logo
        }
      }

      const logoImg = !logo ? groupLogo() : logo

      const linkToProducts = generatePath(routes.GROUP_PRODUCTS, {
        groupId: id,
      })

      const settingsRoute = [4, 7].includes(type)
        ? routes.GROUP_SETTINGS_KNOWMIX
        : routes.GROUP_SETTINGS

      return (
        <div className="common-page__content">
          <ComplaintsModal
            open={this.state.complaintsModal}
            group_id={id}
            onClose={this.hideComplaintsModal}
            callback={() => this.setState({ isReported: true })}
          />
          <main className="common-page__main">
            <GroupHeader />
            {is_member &&
              !tags.includes(1) &&
              (!!images.length ||
                !!videos.length ||
                !!blobs.length ||
                !!links.length ||
                !!currentGroupAttachmentProducts.length) && (
                <GroupMultiTypesHorizontalList
                  title={"Attachments"}
                  id={id}
                  images={images.slice()}
                  videos={videos.slice()}
                  blobs={blobs.slice()}
                  links={links.slice()}
                  products={currentGroupAttachmentProducts.slice()}
                />
              )}
            <GroupPosts />
          </main>
          <aside className="common-page__sidebar">
            <div className="box">
              <div className="box__content">
                <div className="group-avatar">
                  <img src={logoImg} className="group-avatar__img" alt="Logo" />
                  {is_admin && (
                    <div
                      className="btn-update-bg"
                      onClick={() => this.handleClickOpen("avatar")}
                    >
                      <CameraAltOutlined />
                      <span className="btn-update-bg__text">Edit photo</span>
                    </div>
                  )}
                </div>

                <h4 className="subhead-2 group-name">{name}</h4>
              </div>
            </div>

            {(is_member || is_admin) && type === 2 && (
              <Button
                variant={"contained"}
                color={"primary"}
                onClick={this.openSaveEventToCalendar}
              >
                Save to Calendar
              </Button>
            )}

            {this.state.showSaveEventToCalendar && (
              <SaveEventToCalendar
                open={this.state.showSaveEventToCalendar}
                onClose={this.closeSaveEventToCalendar}
                group={this.props.groups.currentGroup}
              />
            )}

            <div className="box">
              <div className="box__content">
                {is_admin ? (
                  <Link
                    to={generatePath(settingsRoute, {
                      groupId: this.props.match.params.groupId,
                    })}
                    className="link"
                  >
                    <SettingsOutlined className="color-black-38 mr8" /> Manage
                  </Link>
                ) : (
                  <Link to={routes.CONTACT_FORM} className="link">
                    <HowToRegIcon className="color-black-38 mr8" /> Claim admin
                    rights
                  </Link>
                )}
              </div>
            </div>

            {is_member && [4, 5, 7].includes(type) && (
              <div className="box">
                <div className="box__content">
                  <PlainLink
                    to={generatePath(routes.GROUP_PUBLIC_CALENDAR, {
                      ...this.props.match.params,
                    })}
                  >
                    <>
                      <DateRangeOutlinedIcon className="color-black-38 mr8" />{" "}
                      Show group calendar
                    </>
                  </PlainLink>
                </div>
              </div>
            )}

            <div className="box">
              <div className="box__content">
                <div className="link mb15" onClick={this.processShare}>
                  <ReplyOutlined
                    className={`flip-h mr8 ${
                      is_shared ? "color-primary" : "color-black-38"
                    }`}
                  />{" "}
                  Share with friends
                </div>
                {!bookmarked ? (
                  <div
                    className={`link ${!is_admin && "mb15"}`}
                    onClick={this.addToBookmarks}
                  >
                    <StarBorderOutlined className="mr8 color-black-38" /> Add to
                    bookmarks
                  </div>
                ) : (
                  <div
                    className={`link ${!is_admin && "mb15"}`}
                    onClick={this.deleteBookmark}
                  >
                    <StarOutlined className="mr8 color-primary" /> Remove from
                    bookmarks
                  </div>
                )}
                {!is_admin && (
                  <div
                    className={`link ${
                      this.state.isReported ? "link--disabled" : ""
                    }`}
                    onClick={() =>
                      !this.state.isReported && this.showComplaintsModal()
                    }
                  >
                    <ErrorOutline className="color-black-38 mr8" /> Report
                  </div>
                )}
              </div>
            </div>

            {(!closed ||
              (closed && is_member && !tags.includes(1)) ||
              is_member) &&
              type === 2 && (
                <BoxedList
                  title="Organizer"
                  counter={""}
                  list={[creator]}
                  entity="users"
                />
              )}

            {(!closed || (closed && is_member && !tags.includes(1))) &&
              type === 4 &&
              !!district &&
              district.id !== 0 && (
                <BoxedList
                  title="District"
                  counter={""}
                  list={[{ ...district, type: 5 }]}
                  entity="groups"
                />
              )}

            {(!closed || is_member) &&
              type === 4 &&
              !!teachers_number &&
              teachers_number > 0 && (
                <BoxedList
                  title="Teachers"
                  counter={teachers_number}
                  list={teachers.slice(0, 5)}
                  entity="users"
                  linkShowAll={generatePath(routes.GROUP_TEACHERS, {
                    groupId: this.props.match.params.groupId,
                  })}
                />
              )}

            {(!closed || (closed && is_member && !tags.includes(1))) &&
              type === 5 &&
              schools.length > 0 && (
                <BoxedList
                  title="Schools"
                  counter={schools.length}
                  list={schools.map((s) => ({ ...s, type: 4 }))}
                  groupType={4}
                  entity="groups"
                  linkShowAll={generatePath(routes.GROUP_RELATED_SCHOOLS, {
                    groupId: this.props.match.params.groupId,
                  })}
                />
              )}

            {(!closed || is_member) &&
              type === 4 &&
              !!schedules_number &&
              schedules_number > 0 && (
                <BoxedList
                  title="Calendars"
                  counter={schedules_number}
                  list={schedules.slice(0, 5)}
                  entity="Calendars"
                  linkShowAll={generatePath(routes.GROUP_CALENDARS, {
                    groupId: this.props.match.params.groupId,
                  })}
                />
              )}

            {(!closed || is_member) &&
              type === 5 &&
              !!calendars_number &&
              calendars_number > 0 && (
                <BoxedList
                  title="Calendars"
                  counter={calendars_number}
                  list={calendars.slice(0, 5)}
                  entity="Calendars"
                  linkShowAll={generatePath(routes.GROUP_CALENDARS, {
                    groupId: this.props.match.params.groupId,
                  })}
                />
              )}

            {(!closed || (closed && is_member && !tags.includes(1))) &&
              participantsList.length > 0 && (
                <BoxedList
                  title="Members"
                  counter={participantsNumber}
                  list={participantsList.slice(0, 5)}
                  entity="users"
                  linkShowAll={generatePath(routes.GROUP_MEMBERS, {
                    groupId: this.props.match.params.groupId,
                  })}
                />
              )}

            {(!closed || (closed && is_member && !tags.includes(1))) &&
              connections.length > 0 && (
                <BoxedList
                  title="Related groups"
                  counter={connections_number}
                  list={connections.slice(0, 5)}
                  entity="groups"
                  linkShowAll={generatePath(routes.GROUP_RELATED_GROUPS, {
                    groupId: this.props.match.params.groupId,
                  })}
                />
              )}

            {(!closed || (closed && is_member && !tags.includes(1))) &&
              products_number > 0 && (
                <BoxedList
                  title="Products"
                  counter={products_number}
                  list={products}
                  entity="products"
                  linkShowAll={linkToProducts}
                />
              )}

            {/*<div className="order-0-xs">*/}
            {/*    <Ads/>*/}
            {/*</div>*/}
          </aside>
          {this.state.openAddToFolderDialog && (
            <AddToFolderModal
              open={this.state.openAddToFolderDialog}
              handleClose={() => this.handleClose("addToFolder")}
            />
          )}
          {this.state.openAvatarUpdate && (
            <ImagePickerModal
              // dimensions={{width: 50, x: 20, y: 20}}
              originImage={logoImg}
              defaultImage={defaultLogo()}
              open={this.state.openAvatarUpdate}
              onSave={this.updateImage}
              onClose={() => this.handleClose("avatar")}
              aspectRatio={imageAspectRatios.square}
            />
          )}

          {/*{this.state.showCalendar && (*/}
          {/*  <PublicCalendar*/}
          {/*    open={this.state.showCalendar}*/}
          {/*    onClose={() => this.setState({ showCalendar: false })}*/}
          {/*  />*/}
          {/*)}*/}

          <Route
            path={routes.GROUP_POST}
            render={(props) => (
              <PostModal
                {...props}
                groupInfo={this.props.groups.currentGroup}
                type="group"
              />
            )}
          />
          <Route
            path={routes.GROUP_PUBLIC_CALENDAR}
            render={(props) => (
              <CalendarProvider>
                <PublicCalendar
                  {...props}
                  hasAccessToCalendar={is_member && [4, 5, 7].includes(type)}
                />
              </CalendarProvider>
            )}
          />
        </div>
      )
    } else {
      return <Loader />
    }
  }
}

const mapStateToProps = ({ auth, groups, bookmarks }) => ({
  auth,
  groups,
  bookmarks,
})
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getGroup,
      updateGroup,
      clearCurrentGroup,
      sharePost,
      createBookmark,
      deleteBookmark,
      getProductsLibrary,
      foldersList,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Group))
