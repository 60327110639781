import React, { createContext, useContext, useEffect, useState } from "react"
import EventFormModal from "./components/modals/eventFormModal"
import EventDialog from "./components/eventsCalendar/components/eventDialog"
import { addMonths, differenceInMonths, subMonths } from "date-fns"
import { useParams, useRouteMatch } from "react-router-dom"
import * as routes from "../../../library/constants/routes"
import FullStepKnowmeetFormModal from "../knowmix/knowmixSingle/knowmixSettings/knowmixSettingsKnowmeets/uknowFormModal/fullStepKnowmeetFormModal"
import UknowCreatedDialog from "../knowmix/knowmixSingle/knowmixSettings/knowmixSettingsKnowmeets/uknowCreatedDialog/uknowCreatedDialog"
import { call } from "../../../library/networking/API"
import { GROUPS } from "../../../library/store/actions/types/groupsTypes"
import { useAlert } from "../../../hooks/useAlert"
import { useSelector } from "react-redux"
import MeetFormModal from "./components/meetFormModal/MeetFormModal"
import MeetDialog from "../knowmap/subpages/meets/MeetDialog"
import AppointmentBookingDialog from "../knowmap/subpages/bookings/appointmentBookingDialog"
import CohortingMeetTypeModal from "./components/eventsCalendar/components/CohortingMeetTypeModal"
import SolicitAppointmentFormModal from "../solicitAppointments/SolicitAppointmentFormModal"
import SolicitAppointmentsProvider from "../solicitAppointments/SolicitAppointmentsContext"
import AppointmentDialog from "./components/eventsCalendar/components/AppointmentDialog"

export const CalendarContext = createContext()

const list = [
  { id: 1, name: "Calendar 1", is_active: false },
  { id: 14, name: "Calendar 2", is_active: true },
  { id: 15, name: "Calendar 3", is_active: false },
  { id: 24, name: "Calendar 4", is_active: true },
]

export const useCalendar = () => {
  return useContext(CalendarContext)
}

const CalendarProvider = ({ contextType = "calendar", children }) => {
  const { path } = useRouteMatch()

  const params = useParams()

  const { errorAlert } = useAlert()

  const { id } = useSelector(({ auth }) => auth.userData)

  const [globalDate, setGlobalDate] = useState({
    date: new Date(),
    updatedBy: "default", //default|sidebar|main
  })

  const [activeRange, setActiveRange] = useState({
    date_from: subMonths(new Date(), 4),
    date_to: addMonths(new Date(), 4),
  })

  useEffect(() => {
    if (differenceInMonths(globalDate.date, activeRange.date_from) < 2) {
      setActiveRange((prevRange) => ({
        date_from: subMonths(prevRange.date_from, 4),
        date_to: subMonths(prevRange.date_to, 4),
      }))
    }

    if (differenceInMonths(activeRange.date_to, globalDate.date) < 2) {
      setActiveRange((prevRange) => ({
        date_from: addMonths(prevRange.date_from, 4),
        date_to: addMonths(prevRange.date_to, 4),
      }))
    }
  }, [globalDate])

  const [groups, setGroups] = useState([])
  const [groupsReceived, setGroupsReceived] = useState(false)

  useEffect(() => {
    ;(async () => {
      try {
        const response = await call(GROUPS.LIST, {
          user_id: id,
          offset: 0,
          limit: 100,
          types: [4, 5],
          active: true,
          managed: false,
          paid: false,
        })
        const data = await response.data.groups

        setGroups(data)
      } catch (e) {
        errorAlert(e)
      }

      setGroupsReceived(true)
    })()
  }, [])

  const [showCalendarFormModal, setShowCalendarFormModal] = useState(false)
  const [showEventFormModal, setShowEventFormModal] = useState(false)
  const [newEventData, setNewEventData] = useState({
    date_from: null,
    date_to: null,
  })
  const [showEventDialog, setShowEventDialog] = useState(false)
  const [activeEvent, setActiveEvent] = useState(undefined)
  const [activeEventType, setActiveEventType] = useState(undefined)

  const [showStepKnowmeetFormModal, setShowStepKnowmeetFormModal] =
    useState(false)

  const [showMeetFormModal, setShowMeetFormModal] = useState(false)

  const [createdKnowmeet, setCreatedKnowmeet] = useState(null)
  const [createdMeet, setCreatedMeet] = useState(null)
  const [isCreatedUknowDialogOpen, setIsCreatedUknowDialogOpen] =
    useState(false)
  const [isCreatedMeetDialogOpen, setIsCreatedMeetDialogOpen] = useState(false)

  const [cohortingMeetTypeOpen, setCohortingMeetTypeOpen] = useState(false)
  const [cohortingMeetType, setCohortingMeetType] = useState("1") // 1 - no course, 2 - for course

  const [
    isSolicitAppointmentFormModalOpen,
    setIsSolicitAppointmentFormModalOpen,
  ] = React.useState(false)

  const onCohortingMeetTypeOpen = () => setCohortingMeetTypeOpen(true)

  const onEventDialogOpen = (eventId, entityType) => {
    setActiveEvent(eventId)
    setActiveEventType(entityType)
    setShowEventDialog(true)
  }

  const onEventDialogClose = () => {
    setShowEventDialog(false)
    setActiveEvent(undefined)
    setActiveEventType(undefined)
  }

  const onEventFormModalOpen = ({ start, end } = {}) => {
    setNewEventData({
      date_from: start
        ? new Date(start.setHours(10, 0, 0)).toISOString()
        : null,
      date_to: start ? new Date(start.setHours(11, 0, 0)).toISOString() : null,
    })
    setShowEventFormModal(true)
  }

  const onEventFormModalClose = () => {
    setNewEventData({ date_from: null, date_to: null })
    setShowEventFormModal(false)
  }

  const onStepKnowmeetFormModalOpen = () => setShowStepKnowmeetFormModal(true)
  const onStepKnowmeetFormModalClose = () => setShowStepKnowmeetFormModal(false)

  const onMeetFormModalOpen = () => setShowMeetFormModal(true)
  const onMeetFormModalClose = () => setShowMeetFormModal(false)

  const onCreatedKnowmeetDialogOpen = (newKnowmeet) => {
    setCreatedKnowmeet(newKnowmeet)
    setIsCreatedUknowDialogOpen(true)
  }

  const onCreatedKnowmeetDialogClose = () => {
    setIsCreatedUknowDialogOpen(false)
    setCreatedKnowmeet(null)
  }

  const onCreatedMeetDialogOpen = (newMeet) => {
    setCreatedMeet(newMeet)
    setIsCreatedMeetDialogOpen(true)
  }

  const onCreatedMeetDialogClose = () => {
    setIsCreatedMeetDialogOpen(false)
    setCreatedMeet(null)
  }

  const onCohortingMeetTypeSubmit = () => {
    switch (cohortingMeetType) {
      case "1":
        onMeetFormModalOpen()
        break
      case "2":
        onStepKnowmeetFormModalOpen()
        break
      default:
        break
    }
    setCohortingMeetTypeOpen(false)
    setCohortingMeetType("1")
  }

  return (
    <CalendarContext.Provider
      value={{
        canChangeView: path === routes.CALENDAR,
        globalDate,
        setGlobalDate,
        activeRange,
        setActiveRange,
        list,
        showCalendarFormModal,
        setShowCalendarFormModal,
        onEventFormModalOpen: ({ start, end }, isEdit = false) => {
          onEventFormModalOpen({ start, end }, isEdit)
        },
        onEventDialogOpen: (eventId, entityType) =>
          onEventDialogOpen(eventId, entityType),
        onStepKnowmeetFormModalOpen,
        groups,
        groupsReceived,
        onMeetFormModalOpen,
        onCohortingMeetTypeOpen,
        onSolicitAppointmentFormModalOpen: () =>
          setIsSolicitAppointmentFormModalOpen(true),
      }}
    >
      <>
        {children}
        {showEventFormModal && (
          <EventFormModal
            onSubmit={onEventDialogClose}
            chosenTime={newEventData}
            open={showEventFormModal}
            handleClose={onEventFormModalClose}
            activeAppointmentId={activeEvent}
            activeRange={activeRange}
            groupId={parseInt(params.groupId, 10) || undefined}
            contextType={contextType}
          />
        )}
        {showStepKnowmeetFormModal && (
          <FullStepKnowmeetFormModal
            open={showStepKnowmeetFormModal}
            handleClose={onStepKnowmeetFormModalClose}
            openKnowmeetCreatedDialog={onCreatedKnowmeetDialogOpen}
          />
        )}

        {showMeetFormModal && (
          <MeetFormModal
            open={showMeetFormModal}
            handleClose={onMeetFormModalClose}
            openMeetCreatedDialog={onCreatedMeetDialogOpen}
          />
        )}

        {isSolicitAppointmentFormModalOpen && (
          <SolicitAppointmentsProvider
            onClose={() => setIsSolicitAppointmentFormModalOpen(false)}
          >
            <SolicitAppointmentFormModal
              open={isSolicitAppointmentFormModalOpen}
            />
          </SolicitAppointmentsProvider>
        )}
        {showEventDialog && (
          <>
            {activeEventType === "events" && (
              <EventDialog
                open={showEventDialog}
                onClose={onEventDialogClose}
                activeEvent={activeEvent}
                activeEventType={activeEventType}
                contextType={contextType}
              />
            )}
            {activeEventType === "appointments" && (
              <AppointmentDialog
                open={showEventDialog}
                onClose={onEventDialogClose}
                activeAppointment={activeEvent}
                contextType={contextType}
                activeRange={activeRange}
              />
            )}
            {activeEventType === "book_appointments" ||
              (activeEventType === "solicit_appointments" && (
                <AppointmentBookingDialog
                  open={showEventDialog}
                  onClose={onEventDialogClose}
                  activeBookingId={activeEvent}
                  bookingType={activeEventType}
                />
              ))}
            {activeEventType === "public_meetings" && (
              <MeetDialog
                open={showEventDialog}
                activeMeet={activeEvent}
                handleClose={onEventDialogClose}
                isConfirmable={false}
              />
              // <EventDialog
              //     open={showEventDialog}
              //     onClose={onEventDialogClose}
              //     activeEvent={activeEvent}
              //     activeEventType={activeEventType}
              // />
            )}
          </>
        )}
        {isCreatedUknowDialogOpen && !!createdKnowmeet && (
          <UknowCreatedDialog
            uknow={createdKnowmeet}
            open={isCreatedUknowDialogOpen}
            handleClose={onCreatedKnowmeetDialogClose}
          />
        )}
        {isCreatedMeetDialogOpen && !!createdMeet && (
          <UknowCreatedDialog
            meet={createdMeet}
            open={isCreatedMeetDialogOpen}
            handleClose={onCreatedMeetDialogClose}
          />
        )}

        {cohortingMeetTypeOpen && (
          <CohortingMeetTypeModal
            open={cohortingMeetTypeOpen}
            onClose={() => setCohortingMeetTypeOpen(false)}
            setType={setCohortingMeetType}
            type={cohortingMeetType}
            onSubmit={onCohortingMeetTypeSubmit}
          />
        )}
      </>
    </CalendarContext.Provider>
  )
}

export default CalendarProvider
