import React from "react"
import Dialog from "@material-ui/core/Dialog/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import FormRadioGroup from "../../../../../../components/formComponents/formRadioGroup"
import Button from "@material-ui/core/Button/Button"

const CohortingMeetTypeModal = ({ open, onClose, setType, type, onSubmit }) => {
  const [selectedType, setSelectedType] = React.useState(type)

  const onChange = (v) => {
    setType(v)
    setSelectedType(v)
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      <DialogTitle className={"text-center"}>
        Choose AI Cohorting meet type
      </DialogTitle>
      <DialogContent>
        <FormRadioGroup
          leftLabel={false}
          onChange={onChange}
          value={selectedType}
          options={[
            { label: "Meet with no Course Frame", value: "1" },
            { label: "Meet with Course Frame", value: "2" },
          ]}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onSubmit} color="primary">
          Next
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CohortingMeetTypeModal
