import ReplyOutlined from "@material-ui/icons/ReplyOutlined"
import StarBorderOutlined from "@material-ui/icons/StarBorderOutlined"
import StarOutlined from "@material-ui/icons/StarOutlined"
import ErrorOutline from "@material-ui/icons/ErrorOutline"
import ComplaintsProvider, {
  useComplaintsModal,
} from "../../../../hooks/modals/useComplaintsModal"
import BookmarksProvider, {
  useBookmarksModal,
} from "../../../../hooks/modals/useBookmarksModal"
import { mutationTypes } from "../../../../library/utils/reactQuery"
import withErrorBoundary from "../../../../hocs/guards/withErrorBoundary"
import styled from "@emotion/styled"
import React from "react"
import { Box } from "../../../tw/layout"
import { usePostsMutations } from "../../../../hooks/data/feed/posts"
import { PlainButton } from "../index"

const ItemsContainer = styled.div`
  display: grid;
  row-gap: 15px;
`
const EntityOperationsBox = ({
  isShared,
  isBookmarked,
  isReported,
  canReport,
  queryKey,
  operationParams = {},
  pathToBookmarks,
  onShareSuccess,
  enableBookmarks = true,
  enableReports = true,
  enableShares = true,
  noContainer = false,
}) => {
  const Container = noContainer ? React.Fragment : Box
  return (
    <Container>
      <BookmarksProvider
        keyToUpdate={queryKey}
        pathToBookmarks={pathToBookmarks}
        mutationType={mutationTypes.record}
        enabled={enableBookmarks}
      >
        <ComplaintsProvider
          entityUpdater={(u) => ({ ...u, is_reported: true })}
          keyToUpdate={queryKey}
          params={operationParams}
          enabled={enableReports}
        >
          <Items
            operationParams={operationParams}
            isShared={isShared}
            onShareSuccess={onShareSuccess}
            isBookmarked={isBookmarked}
            isReported={isReported}
            canReport={canReport}
            enableBookmarks={enableBookmarks}
            enableReports={enableReports}
            enableShares={enableShares}
          />
        </ComplaintsProvider>
      </BookmarksProvider>
    </Container>
  )
}

const Items = ({
  isShared,
  isBookmarked,
  isReported,
  canReport,
  onShareSuccess,
  operationParams,
  enableShares,
  enableReports,
  enableBookmarks,
}) => {
  const { createBookmark, deleteBookmark } = useBookmarksModal()
  const { onComplainModalOpen } = useComplaintsModal()
  const { share } = usePostsMutations()

  const onCreateBookmark = async () => {
    await createBookmark({
      params: operationParams,
      updater: (entity, bId) => ({
        ...entity,
        bookmark: { id: bId },
      }),
    })
  }

  const onDeleteBookmark = async () => {
    await deleteBookmark({
      params: operationParams,
      updater: (entity) => ({
        ...entity,
        bookmark: undefined,
      }),
    })
  }

  const onShare = async () => {
    await share.mutateAsync(operationParams)
    if (typeof onShareSuccess === "function") onShareSuccess()
  }

  return (
    <ItemsContainer>
      {enableShares && (
        <PlainButton onClick={onShare}>
          <ReplyOutlined
            className={`flip-h ${
              isShared ? "color-primary" : "color-black-38"
            }`}
          />
          <span className={"ml8"}>Share with friends</span>
        </PlainButton>
      )}
      {enableBookmarks &&
        (!isBookmarked ? (
          <PlainButton onClick={onCreateBookmark}>
            <StarBorderOutlined className="color-black-38" />
            <span className={"ml8"}>Add to bookmarks</span>
          </PlainButton>
        ) : (
          <PlainButton onClick={onDeleteBookmark}>
            <StarOutlined className="color-primary" />
            <span className={"ml8"}>Remove from bookmarks</span>
          </PlainButton>
        ))}
      {enableReports && canReport && (
        <PlainButton onClick={onComplainModalOpen} disabled={isReported}>
          <ErrorOutline className="color-black-38" />
          <span className={"ml8"}>
            {isReported ? "Reported by you" : "Report"}
          </span>
        </PlainButton>
      )}
    </ItemsContainer>
  )
}

export default withErrorBoundary(EntityOperationsBox)
