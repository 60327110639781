import React from "react"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import Button from "@material-ui/core/Button"
import { getGroupAddress } from "../../../../library/utils/groups"
import { useAlert } from "../../../../hooks/useAlert"
import { call } from "../../../../library/networking/API"
import { CALENDARS } from "../../../../library/store/actions/types/calendarTypes"

const SaveEventToCalendar = ({ open, onClose, group }) => {
  const { errorAlert, successAlert } = useAlert()

  const onSubmit = async () => {
    const data = {
      name: group.name,
      description: group.description,
      location: getGroupAddress(group),
      conference_link: group.website,
      date_from: group.start_date,
      date_to: group.end_date,
    }

    try {
      await call(CALENDARS.APPOINTMENTS.CREATE, {
        ...data,
        should_send_ical: true,
      })
      successAlert("Appointment saved to your calendar")
    } catch (e) {
      errorAlert(e)
    }
  }
  return (
    <Dialog open={open} onClose={onClose} maxWidth={"md"}>
      <DialogTitle id="alert-dialog-title">
        Save as a calendar appointment?
      </DialogTitle>
      <DialogContent>
        <p className={"mb-4"}>
          We will add an appointment to your calendar with the details that you
          can see on the group page.
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button color="primary" onClick={onSubmit}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SaveEventToCalendar
