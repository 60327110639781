import React, { Component } from "react"
import { Route, Switch } from "react-router-dom"
import * as routes from "../../../library/constants/routes"
import ProductsList from "./productsList/productsList"
import VerticalMenu from "../../../components/ui/verticalMenu/verticalMenu"
import ProductsListFilter from "./productsList/productsListFilter/productsListFilter"

class Marketplace extends Component {
  render() {
    const menuItems = [
      {
        path: routes.MARKETPLACE,
        label: "Marketplace",
      },
      {
        path: routes.MARKETPLACE_PURCHASE_HISTORY,
        label: "My products",
      },
      // {
      //     path: routes.MARKETPLACE_MY_PRODUCTS,
      //     label: 'My managed products'
      // }
    ]

    return (
      <div className="common-page__content common-page__content--static">
        <main className="common-page__main">
          <Switch>
            <Route
              exact
              path={routes.MARKETPLACE}
              render={(props) => <ProductsList {...props} type="all" />}
            />
            <Route
              exact
              path={routes.MARKETPLACE_PURCHASE_HISTORY}
              render={(props) => <ProductsList {...props} type="my" />}
            />
            <Route
              exact
              path={routes.MARKETPLACE_MY_PRODUCTS}
              render={(props) => <ProductsList {...props} type="purchase" />}
            />
          </Switch>
        </main>
        <aside className="common-page__sidebar">
          <VerticalMenu menuItems={menuItems} />
          <ProductsListFilter
            hiddenFields={["states", "standardSubjects", "standardsGrades"]}
          />
        </aside>
      </div>
    )
  }
}

export default Marketplace
