import React, { useEffect } from "react"
import styled from "@emotion/styled"
import TermsAndConditions from "../termsAndConditions/termsAndConditions"
import ContactForm from "../contactForm"
import { devices } from "../../../library/constants/styles/responsive"
import Invenstory from "../../main/invenstory"
import PricingPage from "../pricing"
import Calendar from "../../main/calendar"
import AppPromote from "../appPromote"
import AboutUs from "../aboutUs/aboutUs"
import PromoPage from "../promoPage/promoPage"
import HowTo from "../howTo/howTo"
import UknowInfoPage from "../uknow/uknowInfoPage"
import { useLocation } from "react-router-dom"
import AccountsInfoMain from "../accounts/accountsInfoMain"
import AccountsInfoLearner from "../accounts/accountsInfoLearner"
import AccountsInfoEducator from "../accounts/accountsInfoEducator"
import AccountsInfoAgent from "../accounts/accountsInfoAgent"
import AccountsInfoOrganization from "../accounts/accountsInfoOrganization"
import TeacherIntro from "../TeacherIntro"

const Container = styled.div`
  margin: 0 auto;
  width: ${(props) => (props.fullWidthContainer ? "100%" : "1170px")};
  height: ${(props) =>
    props.fullHeightContainer
      ? props.isLoggedIn
        ? "calc(100vh - 50px)"
        : "calc(100vh - 100px)"
      : "auto"};

  @media ${devices.xs} {
    width: 100vw;
    height: auto;
    padding: ${(props) => (props.fullWidthContainer ? 0 : "0 10px")};
  }
`

const Content = styled.div`
  margin-top: ${(props) => props.marginTop};
  height: ${(props) => (props.fullHeightContent ? "100%" : "auto")};
`

const InfoPage = (props) => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  const content = () => {
    switch (props.pageType) {
      case "terms":
        return <TermsAndConditions />

      case "how-to":
        return <HowTo />

      case "feed-back":
        return <ContactForm />

      case "app-promote":
        return <AppPromote />

      case "about-us":
        return <AboutUs />

      case "promote":
        return <PromoPage />

      case "invenstory":
        return <Invenstory />

      case "calendar":
        return <Calendar />

      case "pricing":
        return <PricingPage />

      case "uknow-info":
        return <UknowInfoPage />

      case "accounts-info":
        return <AccountsInfoMain />

      case "accounts-info-learner":
        return <AccountsInfoLearner />

      case "accounts-info-educator":
        return <AccountsInfoEducator />

      case "accounts-info-agent":
        return <AccountsInfoAgent />

      case "accounts-info-organization":
        return <AccountsInfoOrganization />

      case "teacher-intro":
        return <TeacherIntro />

      default:
        return null
    }
  }

  const fullWidthContainer = [
    "pricing",
    "about-us",
    "promote",
    "uknow-info",
    "accounts-info",
    "accounts-info-learner",
    "accounts-info-educator",
    "accounts-info-agent",
    "accounts-info-organization",
    "teacher-intro",
  ].includes(props.pageType)

  const fullHeightContainer = ["app-promote"].includes(props.pageType)

  const marginTop = () => {
    switch (props.pageType) {
      case "pricing":
      case "app-promote":
      case "about-us":
      case "promote":
      case "uknow-info":
      case "accounts-info":
      case "accounts-info-learner":
      case "accounts-info-educator":
      case "accounts-info-agent":
      case "accounts-info-organization":
        return 0

      case "terms":
      case "feed-back":
        return "50px"

      default:
        return "15px"
    }
  }

  return (
    <Container
      fullWidthContainer={fullWidthContainer}
      fullHeightContainer={fullHeightContainer}
      isLoggedIn={props.isLoggedIn}
    >
      <Content marginTop={marginTop()} fullHeightContent={fullHeightContainer}>
        {content()}
      </Content>
    </Container>
  )
}

export default InfoPage
