import React from "react"
import { DatePicker as MuiDatePicker } from "@material-ui/pickers"
import { useField } from "formik"
import { isAfter, isBefore } from "date-fns"

const DatePicker = ({
  name,
  placeholder,
  maxDate,
  minDate,
  initialFocusedDate,
  disableFuture,
  disablePast,
  views,
  format,
}) => {
  const [field, meta, helpers] = useField(name)

  const minDefaultDate = new Date(1910, 1)
  const maxDefaultDate = new Date(2099, 12)
  const renderedValue =
    field.value === null ||
    isBefore(new Date(field.value), minDefaultDate) ||
    isAfter(new Date(field.value), maxDefaultDate)
      ? null
      : new Date(field.value)

  return (
    <MuiDatePicker
      {...field}
      id={name}
      emptyLabel={placeholder}
      invalidLabel={placeholder}
      fullWidth
      clearable
      margin={"normal"}
      views={views}
      format={format || "d MMMM yyyy"}
      value={renderedValue}
      minDate={minDate || minDefaultDate}
      maxDate={maxDate || maxDefaultDate}
      initialFocusedDate={initialFocusedDate || new Date()}
      disableFuture={disableFuture}
      disablePast={disablePast}
      onChange={(v) => helpers.setValue(v)}
      error={meta.touched && Boolean(meta.error)}
      helperText={(meta.touched && meta.error) || null}
    />
  )
}

export default DatePicker
