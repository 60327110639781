import React, { useState } from "react"
import styled from "@emotion/styled"
import { colors } from "../../../../../library/constants/styles/colors"
import { devices } from "../../../../../library/constants/styles/responsive"
import { usePostsMutations } from "../../../../../hooks/data/feed/posts"
import { useCurrentUser } from "../../../../../hooks/data/user/useUser"
import { useGroup } from "../../../../../hooks/data/groups/groups"
import { generatePath, Link } from "react-router-dom"
import UserAvatar from "../../../../userAvatar"
import ClickAwayListener from "@material-ui/core/ClickAwayListener/ClickAwayListener"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import PhotoOutlined from "@material-ui/icons/PhotoOutlined"
import AttachFileOutlined from "@material-ui/icons/AttachFileOutlined"
import FileLoader from "../../../../formComponents/fileLoader"
import { useIdParam } from "../../../../../hooks/useIdParam"
import * as routes from "../../../../../library/constants/routes"
import { Form, Formik } from "formik"
import { TextField } from "@material-ui/core"
import LoadingButton from "../../../controls/buttons/loadingButton"
import * as yup from "yup"
import { useAlert } from "../../../../../hooks/useAlert"
import { useSelector } from "react-redux"

const DropdownContainer = styled.div`
  position: relative;
`

const DropdownBtn = styled.button`
  display: flex;
  color: ${colors.black38};
`

const DropdownContentWrapper = styled.div`
  position: absolute;
  top: calc(100% + 10px);
  left: -10px;
  z-index: 1;
`

const DropdownContent = styled.div`
  position: relative;
  min-width: 160px;
  padding: 15px;
  background-color: ${colors.white};
  box-shadow: 0 0 2px 0 rgba(210, 210, 210, 0.5);
  border-radius: 2px;

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -6px;
    left: 14px;
    width: 15px;
    height: 15px;
    transform: rotate(45deg);
    border-radius: 1px;
    background-color: ${colors.white};
    box-shadow: 0 0 2px 0 rgba(210, 210, 210, 0.5);

    @media ${devices.xs} {
      right: 12px;
    }
  }
`

const validationSchema = yup.object({
  message: yup.string().max(5000),
})

const PostCreate = ({ postsListQueryKey }) => {
  const userId = useIdParam("userId")
  const groupId = useIdParam("groupId")

  const { errorAlert } = useAlert()

  // const { user } = useUser({ id: userId })
  const { user: currentUser, avatar } = useCurrentUser()
  const { token } = useSelector(({ auth }) => auth.userData)
  const { group } = useGroup()

  const { createPost } = usePostsMutations({ queryKey: postsListQueryKey })

  const initialValues = {
    message: "",
  }

  const [attachments, setAttachments] = useState({
    blobs: [],
    images: [],
    videos: [],
  })
  const [isFieldActive, setIsFieldActive] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [isPostPersonal, setIsPostPersonal] = useState(true)

  const handleCheckboxChange = () => {
    setIsPostPersonal(!isPostPersonal)
  }

  const toggleNewPostForm = () => {
    setIsFieldActive(!isFieldActive)
  }

  const onSubmit = async ({ message }, { resetForm }) => {
    try {
      await createPost.mutateAsync({
        message,
        personal: isPostPersonal,
        attachments: [
          ...attachments.blobs,
          ...attachments.images,
          ...attachments.videos,
        ].map((a) => a.id),
        user_id: userId || currentUser.id,
        group_id: groupId || undefined,
      })
      resetForm()
      setIsFieldActive(false)
    } catch (e) {
      errorAlert(e)
    }
  }

  const creatorAvatar = group ? group.logo : avatar

  const creatorUrl = group
    ? generatePath(routes.GROUP, { groupId })
    : currentUser
      ? generatePath(routes.USER, { userId: currentUser?.id })
      : ""

  const hasAttachments =
    !!attachments.blobs.length ||
    !!attachments.images.length ||
    !!attachments.videos.length

  return (
    <div className="post-create box">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(fk) => (
          <Form>
            <div className={`f aic ${isFieldActive ? "mb15" : ""}`}>
              <Link to={creatorUrl} className="asfs">
                {!group || isPostPersonal ? (
                  <UserAvatar
                    user={currentUser}
                    photo={currentUser?.photo}
                    className={"mr-4"}
                  />
                ) : (
                  <div className="post-create__avatar">
                    <img
                      src={creatorAvatar}
                      alt="Logo"
                      className="user-avatar"
                    />
                  </div>
                )}
              </Link>

              {!!group && (
                <ClickAwayListener onClickAway={() => setIsDropdownOpen(false)}>
                  <DropdownContainer>
                    <DropdownBtn
                      type="button"
                      onClick={() =>
                        setIsDropdownOpen((prevState) => !prevState)
                      }
                    >
                      {isDropdownOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </DropdownBtn>
                    {isDropdownOpen && (
                      <DropdownContentWrapper>
                        <DropdownContent>
                          <FormControlLabel
                            control={
                              <Checkbox
                                autoFocus
                                checked={!isPostPersonal}
                                onChange={handleCheckboxChange}
                                value={"Post as group"}
                              />
                            }
                            label="Post as group"
                          />
                        </DropdownContent>
                      </DropdownContentWrapper>
                    )}
                  </DropdownContainer>
                </ClickAwayListener>
              )}

              {!isFieldActive ? (
                <div
                  className="post-create__placeholder"
                  onClick={toggleNewPostForm}
                >
                  What’s happening?
                </div>
              ) : (
                <div className="full-width">
                  <TextField
                    name="message"
                    className="post-create__input"
                    placeholder="What’s happening?"
                    multiline
                    autoFocus
                    fullWidth
                    value={fk.values.message}
                    onChange={fk.handleChange}
                    margin="none"
                  />
                </div>
              )}
              <div className="post-create__add-attachment">
                <label
                  htmlFor="img-input"
                  onClick={() =>
                    !isFieldActive ? toggleNewPostForm() : undefined
                  }
                >
                  <PhotoOutlined className="post-create__btn-icon" />
                </label>
              </div>
              <div className="post-create__add-attachment">
                <label
                  htmlFor="attachments-input"
                  onClick={() =>
                    !isFieldActive ? toggleNewPostForm() : undefined
                  }
                >
                  <AttachFileOutlined className="post-create__btn-icon" />
                </label>
              </div>
            </div>

            {isFieldActive && (
              <>
                <div className="f jcfs aifs full-width">
                  <div className="post-create__images">
                    <FileLoader
                      videos={attachments.videos}
                      images={attachments.images}
                      blobs={attachments.blobs}
                      onUpload={({ blobs, images, videos }) =>
                        setAttachments({ blobs, images, videos })
                      }
                      token={token}
                    />
                  </div>
                </div>
                <div className="f jcfe aic full-width">
                  <LoadingButton
                    type="submit"
                    size="small"
                    disabled={
                      !hasAttachments && !fk.values.message.replace(/\s/g, "")
                    }
                    isLoading={createPost.isLoading}
                    isLoadingText={"Posting"}
                  >
                    Post
                  </LoadingButton>
                </div>
              </>
            )}
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default PostCreate
