import React from "react"
import BoxPage from "../../../../../../components/v2/layout/boxPage/boxPage"
import { useIdParam } from "../../../../../../hooks/useIdParam"
import SchoolCalendarPicker from "../../../../../../components/v2/inputs/pickers/schoolCalendarPicker"
import SegmentPicker from "../../../../../../components/v2/inputs/pickers/segmentPicker"
import SectionPicker from "../../../../../../components/v2/inputs/pickers/sectionPicker"
import { useScheduleAssignment } from "./useScheduleAssignment"
import { useToggle } from "../../../../../../hooks/useToggle"
import GradePicker from "../../../../../../components/v2/inputs/pickers/gradePicker"
import { calendarGrades } from "../../../../../../library/constants/educationalDictionary"
import { weekDays } from "../../../../../../library/utils/timeConvert"
import { rotations } from "../../../../../../library/constants/groupTypes"
import ScheduleAssignmentTable from "./components/ScheduleAssignmentTable"
import TeacherScheduleAssignmentFormModal from "./components/TeacherScheduleAssignmentFormModal"
import {
  GROUPS,
  SCHOOLS,
} from "../../../../../../library/store/actions/types/groupsTypes"
import UserPicker from "../../../../../../components/v2/inputs/pickers/userPicker"
import { getUserName } from "../../../../../../library/utils/user/userUtils"
import { getObjectById } from "../../../../../../library/utils/arrays"
import UserLink from "../../../../../../components/v2/utils/UserLink"

const SchoolScheduleLearners = () => {
  const groupId = useIdParam("groupId")
  const today = new Date().getDay()

  const {
    filters,
    onFilterChange,
    onFilterChangeValue,
    filterErrors,
    blocksQuery: { blocks, isBlocksSuccess, blocksQueryKey, refetchBlocks },
    tracksQuery: { tracks, isTracksSuccess, refetchTracks },
    sectionRotations,
    columns,
    activeDay,
    setActiveDay,
  } = useScheduleAssignment({ type: "learner" })

  const rows = React.useMemo(() => {
    return isBlocksSuccess && isTracksSuccess
      ? tracks.map((t, i) => {
          return [
            t.name,
            ...blocks.map((b) => ({
              ...b,
              track: b.tracks.find((bt) => bt.id === t.id),
              enabled: !!b.tracks.find((bt) => bt.id === t.id),
            })),
          ]
        })
      : []
  }, [blocks, tracks])

  const [isAssignationModalOpen, toggleAssignationModal] = useToggle(false)
  const [activeCell, setActiveCell] = React.useState({
    block: null,
    track: null,
  })

  const initAssignation = ({ block, track }) => {
    setActiveCell({ block, track })
    toggleAssignationModal()
  }

  const onAssignationModalClose = () => {
    toggleAssignationModal()
    setActiveCell({ block: null, track: null })
  }

  return (
    <BoxPage title={"Learner schedule"}>
      <div className={"mb20"}>
        <SchoolCalendarPicker
          groupId={groupId}
          value={filters.schedule}
          hasError={filterErrors.schedule}
          onChange={onFilterChange("schedule")}
        />
        <GradePicker
          grades={calendarGrades}
          value={filters.grade}
          onChange={onFilterChange("grade")}
        />
        <SegmentPicker
          onChange={onFilterChange("segment")}
          hasError={filterErrors.segment}
          value={filters.segment}
          scheduleId={filters.schedule?.id}
          grade={filters.grade?.id}
          isWaitingForOtherFieldsMessage={"First select schedule"}
        />
        <SectionPicker
          onChange={onFilterChange("section")}
          hasError={filterErrors.section}
          value={filters.section}
          segmentId={filters.segment?.id}
        />
        <hr className={"my-2 border-divider"} />
        <UserPicker
          value={filters.learner}
          onChange={onFilterChangeValue("learner")}
          userLabel={"learner"}
          requestConfig={{
            fetchConfig: {
              apiUrl: SCHOOLS.STUDENTS.LIST,
              params: {
                group_id: groupId,
                grade: filters.grade?.id,
              },
            },
            key: "users",
          }}
        />
      </div>
      <div>
        {!!filters.section && (
          <div className="box">
            <nav className="h-tabs">
              <ul className="h-tabs__nav-list">
                {Object.entries(weekDays).map(([key, value], tabIdx) => {
                  return (
                    <li
                      key={value}
                      className={`relative text-center h-tabs__item ${
                        activeDay === key ? "h-tabs__item--active" : ""
                      }`}
                      onClick={() => setActiveDay(key)}
                    >
                      <p className="h-tabs__link border-b-0 leading-none">
                        {value}
                      </p>
                      <p className={"text-xs text-black38 leading-none"}>
                        {rotations[sectionRotations[tabIdx]]}
                      </p>
                      {Number(key) === today && (
                        <div
                          className={
                            "absolute top-0 right-0 w-1 h-1 rounded-full bg-primary"
                          }
                        />
                      )}
                    </li>
                  )
                })}
              </ul>
            </nav>
          </div>
        )}
        {isBlocksSuccess &&
          isTracksSuccess &&
          !!filters.schedule &&
          !!filters.learner && (
            <>
              {!!blocks.length ? (
                <ScheduleAssignmentTable
                  rows={rows}
                  columns={columns}
                  dataReceived={true}
                  onCellClick={initAssignation}
                  type={"learner"}
                  getCellContent={(cell, cellIdx) => {
                    const student = cell?.track?.slot?.students.find(
                      (s) => s.user_id === filters.learner.id
                    )
                    const subject = cell?.track?.slot?.subject
                    return (
                      <>
                        {!!subject.id && (
                          <p className={"text-black54 text-xs"}>
                            {getObjectById(calendarGrades, subject.grade).name}{" "}
                            grade, {subject.name}
                          </p>
                        )}
                        {!!student && (
                          <UserLink
                            user={student}
                            linkClassName={"!leading-none"}
                            key={student.user_id}
                          />
                        )}
                      </>
                    )
                  }}
                />
              ) : (
                <p className={"pt15 text-center text-black54"}>
                  Blocks not found for this day
                </p>
              )}
            </>
          )}
      </div>
      {isAssignationModalOpen && (
        <TeacherScheduleAssignmentFormModal
          open={isAssignationModalOpen}
          onClose={onAssignationModalClose}
          activeCell={activeCell}
          schoolSchedule={filters.schedule}
          blocksQueryKey={blocksQueryKey}
          type={"learner"}
        />
      )}
    </BoxPage>
  )
}

export default SchoolScheduleLearners
