import SuperSelect from "../select/superSelect"
import {
  getUserName,
  getUserPhoto,
  User,
} from "../../../../library/utils/user/userUtils"
import { Chip } from "@material-ui/core"
import InfoIcon from "@material-ui/icons/Info"
import { colors } from "../../../../library/constants/styles/colors"
import React from "react"
import UserInfoTooltip from "../../utils/UserInfoTooltip"

const UserPicker = ({
  value,
  onChange,
  hasError = false,
  errorMessage,
  required = false,
  multiple = false,
  fieldName = "user",
  requestConfig = {},
  emptyListMessage,
  userLabel = "user",
  isWaitingForOtherFields = false,
  isWaitingForOtherFieldsMessage,
  leftLabel = true,
  getDisabledValue,
}) => {
  return (
    <SuperSelect
      leftLabel={leftLabel}
      value={value}
      onChange={onChange}
      // onChange={console.log}
      requestConfig={requestConfig}
      fieldName={fieldName}
      entityName={userLabel}
      required={required}
      multiple={multiple}
      getItemPhoto={getUserPhoto}
      hasError={hasError}
      errorMessage={errorMessage}
      emptyListMessage={emptyListMessage}
      getValueName={getUserName}
      isWaitingForOtherFields={isWaitingForOtherFields}
      isWaitingForOtherFieldsMessage={"First select group"}
      getDisabledValue={getDisabledValue}
      renderTags={(v, getTagProps) =>
        v.map((option, index) => {
          const user = new User(option)
          return (
            <Chip
              key={user.info.id}
              label={
                <div className={"f aic"}>
                  <UserInfoTooltip user={option}>
                    <InfoIcon
                      style={{
                        marginRight: 5,
                        fontSize: 20,
                        color: colors.primary,
                        cursor: "pointer",
                      }}
                    />
                  </UserInfoTooltip>
                  {user.name}
                </div>
              }
              {...getTagProps({ index })}
            />
          )
        })
      }
    />
  )
}

export default UserPicker
