import React from "react"
import { Route, Switch } from "react-router-dom"
import * as routes from "../../../../library/constants/routes"
import { knowmapRoutes } from "../../../../library/constants/routes"
import UserProfile from "../../user/userProfile"
import UserProducts from "../../user/userProducts/userProducts"
import UserProfileEdit from "../../user/userProfileEdit/userProfileEdit"
import UserProfileSettings from "../../user/userProfileSettings/userProfileSettings"
import Search from "../../search/search"
import Friends from "../../friends"
import KnowCred from "../../knowCred"
import Groups from "../../groups"
import Group from "../../groups/group/group"
import GroupProducts from "../../groups/group/groupProducts"
import GroupSettings from "../../groups/group/groupSettings"
import GroupMembers from "../../groups/group/groupMembers"
import GroupRelatedGroups from "../../groups/group/groupRelatedGroups"
import DistrictConnectedSchools from "../../groups/group/districtConnectedSchools"
import Conversations from "../../conversations"
import Marketplace from "../../marketplace/marketplace"
import Product from "../../marketplace/product/product"
import ProductSettings from "../../marketplace/productSettings/productSettings"
import InfoPage from "../../../info/infoPage/infoPage"
import GroupAttachments from "../../groups/group/groupAttachments"
import NewsFeed from "../../feed/newsFeed"
import Standards from "../../standards"
import Standard from "../../standards/Standard"
import Bookmarks from "../../bookmarks/bookmarks"
import Notifications from "../../notifications"
import Knowmix from "../../knowmix/knowmix"
import KnowmixKnowcredForm from "../../knowmix/knowmixKnowcredForm/knowmixKnowcredForm"
import KnowmixGrades from "../../knowmix/knowmixSingle/knowmixGrades/knowmixGrades"
import KnowMixWalkthrough from "../../knowmix/knowmixSingle/knowMixWalkthrough"
import KnowMixCheck from "../../knowmix/knowmixSingle/knowMixCheck"
import KnowMixFullCheck from "../../knowmix/knowmixSingle/knowMixFullCheck"
import GroupSettingsCalendarGrade from "../../groups/group/groupSettings/groupSettingsCalendar/groupSettingsCalendarGrades/subpages/groupSettingsCalendarGrade"
import GroupSettingsCalendar from "../../groups/group/groupSettings/groupSettingsCalendar/groupSettingsCalendar/groupSettingsCalendar"
import GroupSettingsCalendarSubject from "../../groups/group/groupSettings/groupSettingsCalendar/groupSettingsCalendarSubjects/subpages/groupSettingsCalendarSubject/groupSettingsCalendarSubject"
import GroupCalendars from "../../groups/group/groupCalendars"
import Segment from "../../groups/group/groupSettings/groupSettingsCalendar/groupSettingsCalendarSegments/subpages/segment/segment"
import SegmentSection from "../../groups/group/groupSettings/groupSettingsCalendar/groupSettingsCalendarSegments/subpages/segmentSections/subpages/segmentSection/segmentSection"
import HomePage from "../../homePage/homePage"
import Knowmap from "../../knowmap/knowmap"
import PacingCalendar from "../../groups/group/groupSettings/groupSettingsCalendar/pacingCalendars/subpages/pacingCalendar"
import ErrorBoundary from "../../../../components/v2/ErrorBoundary"
import CoursePage from "../../knowmix/CoursePage/CoursePage"
import CourseSettings from "../../knowmix/courseSettings/CourseSettings"

const LayoutWithSidebar = (props) => {
  return (
    <div className="container">
      <div className="common-page">
        {/*<aside className="sidebar">*/}
        {/*  <div className="sidebar__container">*/}
        {/*    <SidebarNav {...props} />*/}
        {/*    /!*<Ads/>*!/*/}
        {/*  </div>*/}
        {/*</aside>*/}
        <ErrorBoundary>
          <Switch>
            <Route exact path={routes.HOME}>
              <HomePage />
            </Route>

            <Route exact path={routes.NOTIFICATIONS}>
              <Notifications />
            </Route>

            <Route
              exact
              path={[
                routes.USER,
                routes.USER_POST,
                routes.BOOK_MEET_AUTHORIZED,
              ]}
            >
              <UserProfile />
            </Route>

            <Route exact path={routes.NEWS}>
              <NewsFeed />
            </Route>

            <Route exact path={routes.USER_PRODUCTS}>
              <UserProducts />
            </Route>

            <Route
              exact
              path={[
                routes.PROFILE_EDIT,
                routes.PROFILE_EDIT_CONTACTS,
                routes.PROFILE_EDIT_EDUCATION,
                routes.PROFILE_EDIT_WORK,
                routes.PROFILE_EDIT_PERSONAL,
              ]}
            >
              <UserProfileEdit />
            </Route>

            <Route
              exact
              path={[
                routes.PROFILE_SETTINGS,
                routes.PROFILE_SETTINGS_NOTIFICATIONS,
                routes.PROFILE_SETTINGS_BLACKLIST,
                routes.PROFILE_SETTINGS_VERIFICATION,
                routes.PROFILE_SETTINGS_HOLDS,
                routes.PROFILE_SETTINGS_INTEGRATIONS,
                routes.PROFILE_SETTINGS_CONFERENCE_LINK,
                routes.PROFILE_SETTINGS_APPOINTMENT_LINKS,
                routes.PROFILE_SETTINGS_APPOINTMENT_TYPES,
                routes.PROFILE_SETTINGS_SUBSCRIPTIONS,
              ]}
            >
              <UserProfileSettings />
            </Route>

            <Route
              exact
              path={[
                routes.SEARCH,
                routes.SEARCH_USERS,
                routes.SEARCH_GROUPS,
                routes.SEARCH_GROUPS_INTERESTS,
                routes.SEARCH_GROUPS_DISTRICTS,
                routes.SEARCH_GROUPS_SCHOOLS,
                routes.SEARCH_GROUPS_COMPANIES,
                routes.SEARCH_GROUPS_EVENTS,
                routes.SEARCH_PRODUCTS,
              ]}
            >
              <Search />
            </Route>

            <Route
              exact
              path={[
                routes.FRIENDS,
                routes.FRIENDS_REQUESTS_PENDING,
                routes.FRIENDS_REQUESTS_OUTGOING,
                routes.FRIENDS_SEARCH,
              ]}
            >
              <Friends />
            </Route>

            <Route
              exact
              path={[
                routes.KNOWCRED,
                routes.KNOWCRED_VALIDATED,
                routes.KNOWCRED_GRANTED,
                routes.KNOWCRED_AWARDED,
                routes.KNOWCRED_VALID,
                routes.KNOWCRED_SOCIAL,
                routes.KNOWCRED_SOCIAL_VALIDATED,
                routes.KNOWCRED_SOCIAL_GRANTED,
                routes.KNOWCRED_EXTRA,
                routes.KNOWCRED_EXTRA_VALIDATED,
                routes.KNOWCRED_EXTRA_GRANTED,
                routes.KNOWCRED_EXTRA_AWARDED,
                routes.KNOWCRED_EXTRA_VALID,
                routes.KNOWCRED_GRADES,
                routes.KNOWCRED_GRADES_VALIDATED,
                routes.KNOWCRED_GRADES_GRANTED,
                routes.KNOWCRED_GRADES_AWARDED,
                routes.KNOWCRED_GRADES_VALID,
                routes.KNOWCRED_CERTIFICATES,
                routes.KNOWCRED_CERTIFICATES_VALIDATED,
                routes.KNOWCRED_CERTIFICATES_GRANTED,
                routes.KNOWCRED_CERTIFICATES_AWARDED,
                routes.KNOWCRED_CERTIFICATES_VALID,
                routes.KNOWCRED_DIPLOMAS,
                routes.KNOWCRED_DIPLOMAS_VALIDATED,
                routes.KNOWCRED_DIPLOMAS_GRANTED,
                routes.KNOWCRED_DIPLOMAS_AWARDED,
                routes.KNOWCRED_DIPLOMAS_VALID,
                routes.KNOWCRED_SINGLE,
                routes.KNOWCRED_CREATE,
                routes.KNOWCRED_EDIT,
              ]}
            >
              <KnowCred />
            </Route>

            <Route exact path={routes.GROUPS} component={Groups} />
            <Route
              exact
              path={routes.GROUP_ATTACHMENTS}
              render={(props) => {
                return <GroupAttachments type="all" {...props} />
              }}
            />
            <Route
              exact
              path={routes.GROUP_ATTACHMENTS_LINKS}
              render={(props) => {
                return <GroupAttachments type="links" {...props} />
              }}
            />
            <Route
              exact
              path={routes.GROUP_ATTACHMENTS_FILES}
              render={(props) => {
                return <GroupAttachments type="files" {...props} />
              }}
            />
            <Route
              exact
              path={routes.GROUP_ATTACHMENTS_PRODUCTS}
              render={(props) => {
                return <GroupAttachments type="products" {...props} />
              }}
            />
            <Route
              exact
              path={routes.GROUP_SETTINGS}
              component={GroupSettings}
            />
            <Route
              exact
              path={routes.GROUP_SETTINGS_INVENSTORY}
              component={GroupSettings}
            />
            <Route
              exact
              path={routes.GROUP_SETTINGS_INVENSTORY_OUTGOING}
              component={GroupSettings}
            />
            <Route
              exact
              path={routes.GROUP_SETTINGS_ATTACHMENTS}
              component={GroupSettings}
            />
            <Route
              exact
              path={routes.GROUP_SETTINGS_ATTACHMENTS_LINKS}
              component={GroupSettings}
            />
            <Route
              exact
              path={routes.GROUP_SETTINGS_ATTACHMENTS_FILES}
              component={GroupSettings}
            />
            <Route
              exact
              path={routes.GROUP_SETTINGS_ATTACHMENTS_PRODUCTS}
              component={GroupSettings}
            />
            <Route exact path={routes.GROUPS_MANAGED} component={Groups} />
            <Route exact path={routes.GROUPS_SEARCH} component={Groups} />
            <Route
              exact
              path={routes.GROUPS_SEARCH_SCHOOLS}
              component={Groups}
            />
            <Route
              exact
              path={routes.GROUPS_SEARCH_ONE_TEACHER_SCHOOLS}
              component={Groups}
            />
            <Route
              exact
              path={routes.GROUPS_SEARCH_DISTRICTS}
              component={Groups}
            />
            <Route
              exact
              path={routes.GROUPS_SEARCH_INTERESTS}
              component={Groups}
            />
            <Route
              exact
              path={routes.GROUPS_SEARCH_COMPANIES}
              component={Groups}
            />
            <Route
              exact
              path={routes.GROUPS_SEARCH_EVENTS}
              component={Groups}
            />
            <Route
              exact
              path={routes.GROUPS_REQUESTS_PENDING}
              component={Groups}
            />
            <Route
              exact
              path={routes.GROUPS_REQUESTS_OUTGOING}
              component={Groups}
            />
            <Route exact path={routes.GROUPS_FAVORITE} component={Groups} />
            <Route exact path={routes.GROUPS_RECOMMENDED} component={Groups} />
            <Route exact path={routes.GROUPS_INTERESTS} component={Groups} />
            <Route
              exact
              path={routes.GROUPS_INTERESTS_MANAGED}
              component={Groups}
            />
            <Route exact path={routes.GROUPS_DISTRICTS} component={Groups} />
            <Route
              exact
              path={routes.GROUPS_DISTRICTS_MANAGED}
              component={Groups}
            />
            <Route exact path={routes.GROUPS_SCHOOLS} component={Groups} />
            <Route
              exact
              path={routes.GROUPS_SCHOOLS_MANAGED}
              component={Groups}
            />
            <Route
              exact
              path={routes.GROUPS_ONE_TEACHER_SCHOOLS}
              component={Groups}
            />
            <Route
              exact
              path={routes.GROUPS_ONE_TEACHER_SCHOOLS_MANAGED}
              component={Groups}
            />
            <Route exact path={routes.GROUPS_COMPANIES} component={Groups} />
            <Route
              exact
              path={routes.GROUPS_COMPANIES_MANAGED}
              component={Groups}
            />
            <Route exact path={routes.GROUPS_EVENTS} component={Groups} />
            <Route
              exact
              path={routes.GROUPS_EVENTS_MANAGED}
              component={Groups}
            />
            <Route exact path={routes.GROUP} component={Group} />
            <Route exact path={routes.GROUP_POST} component={Group} />
            <Route
              exact
              path={routes.GROUP_PUBLIC_CALENDAR}
              component={Group}
            />
            <Route
              exact
              path={routes.GROUP_PRODUCTS}
              component={GroupProducts}
            />
            <Route
              exact
              path={routes.GROUP_SETTINGS}
              component={GroupSettings}
            />
            <Route
              exact
              path={routes.GROUP_SETTINGS_MAIN_INFO}
              component={GroupSettings}
            />
            <Route
              exact
              path={routes.GROUP_SETTINGS_SUBSCRIPTION}
              component={GroupSettings}
            />
            <Route
              exact
              path={routes.GROUP_SETTINGS_COMMENTS}
              component={GroupSettings}
            />
            <Route
              exact
              path={routes.GROUP_SETTINGS_POSTS}
              component={GroupSettings}
            />
            <Route
              exact
              path={routes.GROUP_SETTINGS_PRODUCTS}
              component={GroupSettings}
            />
            <Route
              exact
              path={routes.GROUP_SETTINGS_RELATED_GROUPS}
              component={GroupSettings}
            />
            <Route
              exact
              path={routes.GROUP_SETTINGS_KNOWMIX}
              component={GroupSettings}
            />
            <Route
              exact
              path={routes.GROUP_SETTINGS_MEMBERS}
              component={GroupSettings}
            />
            <Route
              exact
              path={routes.GROUP_SETTINGS_MANAGEMENT}
              component={GroupSettings}
            />
            <Route
              exact
              path={routes.GROUP_SETTINGS_SUPER_ADMINS}
              component={GroupSettings}
            />
            <Route
              exact
              path={routes.GROUP_SETTINGS_REQUESTS}
              component={GroupSettings}
            />
            <Route
              exact
              path={routes.GROUP_SETTINGS_INVITE}
              component={GroupSettings}
            />
            <Route
              exact
              path={routes.GROUP_SETTINGS_SCHOOLS_ROSTERING}
              component={GroupSettings}
            />
            <Route
              exact
              path={routes.GROUP_SETTINGS_SPACES}
              component={GroupSettings}
            />
            <Route
              exact
              path={routes.GROUP_SETTINGS_HOLDS}
              component={GroupSettings}
            />
            <Route
              exact
              path={routes.SCHOOL_SCHEDULE_TEACHERS}
              component={GroupSettings}
            />
            <Route
              exact
              path={routes.SCHOOL_SCHEDULE_LEARNERS}
              component={GroupSettings}
            />
            <Route
              exact
              path={routes.SCHOOL_SCHEDULE_SUBJECT_TIME}
              component={GroupSettings}
            />
            <Route
              exact
              path={routes.GROUP_SETTINGS_DISTRICT_CALENDARS}
              component={GroupSettings}
            />
            <Route
              exact
              path={routes.GROUP_SETTINGS_SCHOOL_CALENDARS}
              component={GroupSettings}
            />
            <Route
              exact
              path={routes.GROUP_SETTINGS_PACING_CALENDARS}
              component={GroupSettings}
            />
            <Route
              exact
              path={routes.GROUP_SETTINGS_PACING_CALENDAR}
              component={PacingCalendar}
            />
            <Route
              exact
              path={routes.GROUP_SETTINGS_PACING_CALENDAR_UNITS}
              component={PacingCalendar}
            />
            <Route
              exact
              path={routes.GROUP_SETTINGS_PACING_CALENDAR_UNITS_CALENDAR}
              component={PacingCalendar}
            />
            <Route
              exact
              path={routes.GROUP_SETTINGS_SCHOOL_CALENDAR_SUBJECTS}
              component={GroupSettingsCalendar}
            />
            <Route
              exact
              path={routes.GROUP_SETTINGS_DISTRICT_CALENDAR_SUBJECTS}
              component={GroupSettingsCalendar}
            />
            <Route
              exact
              path={routes.GROUP_SETTINGS_SCHOOL_CALENDAR_SUBJECT}
              component={GroupSettingsCalendarSubject}
            />
            <Route
              exact
              path={routes.GROUP_SETTINGS_DISTRICT_CALENDAR_SUBJECT}
              component={GroupSettingsCalendarSubject}
            />
            <Route
              exact
              path={routes.GROUP_SETTINGS_SCHOOL_CALENDAR_UNITS}
              component={GroupSettingsCalendarSubject}
            />
            <Route
              exact
              path={routes.GROUP_SETTINGS_DISTRICT_CALENDAR_UNITS}
              component={GroupSettingsCalendarSubject}
            />
            <Route
              exact
              path={routes.GROUP_SETTINGS_SCHOOL_CALENDAR_SEGMENTS}
              component={GroupSettingsCalendar}
            />
            <Route
              exact
              path={routes.GROUP_SETTINGS_SCHOOL_CALENDAR_SEGMENT_SECTIONS}
              component={Segment}
            />
            <Route
              exact
              path={
                routes.GROUP_SETTINGS_SCHOOL_CALENDAR_SEGMENT_SECTION_ROTATIONS
              }
              component={SegmentSection}
            />
            <Route
              exact
              path={
                routes.GROUP_SETTINGS_SCHOOL_CALENDAR_SEGMENT_SECTION_BLOCKS
              }
              component={SegmentSection}
            />
            <Route
              exact
              path={
                routes.GROUP_SETTINGS_SCHOOL_CALENDAR_SEGMENT_SECTION_TRACKS
              }
              component={SegmentSection}
            />
            <Route
              exact
              path={routes.GROUP_SETTINGS_CALENDAR_GRADES}
              component={GroupSettingsCalendar}
            />
            <Route
              exact
              path={routes.GROUP_SETTINGS_CALENDAR_GRADE}
              component={GroupSettingsCalendarGrade}
            />
            <Route
              exact
              path={routes.GROUP_SETTINGS_CALENDAR_GRADE_MEMBERS}
              component={GroupSettingsCalendarGrade}
            />
            <Route
              exact
              path={routes.GROUP_SETTINGS_CALENDAR_GRADE_INVITES}
              component={GroupSettingsCalendarGrade}
            />
            <Route
              exact
              path={routes.GROUP_SETTINGS_SCHOOL_CALENDAR_SCHEDULE}
              component={GroupSettingsCalendar}
            />
            <Route exact path={routes.GROUP_MEMBERS} component={GroupMembers} />
            <Route
              exact
              path={routes.GROUP_RELATED_GROUPS}
              component={GroupRelatedGroups}
            />
            <Route
              exact
              path={routes.GROUP_RELATED_SCHOOLS}
              component={DistrictConnectedSchools}
            />
            <Route
              exact
              path={routes.GROUP_CALENDARS}
              component={GroupCalendars}
            />
            <Route
              exact
              path={routes.GROUP_TEACHERS}
              render={(props) => {
                return <GroupMembers {...props} memberType={"teacher"} />
              }}
            />
            <Route
              exact
              path={routes.CONVERSATIONS}
              component={Conversations}
            />
            <Route
              exact
              path={routes.CONVERSATIONS_NEW}
              component={Conversations}
            />
            <Route
              exact
              path={routes.CONVERSATIONS_FAVORITE}
              component={Conversations}
            />
            <Route
              exact
              path={routes.CONVERSATIONS_UNREAD}
              component={Conversations}
            />
            <Route exact path={routes.CONVERSATION} component={Conversations} />
            <Route exact path={routes.MARKETPLACE} component={Marketplace} />
            <Route
              exact
              path={routes.MARKETPLACE_FAVORITES}
              component={Marketplace}
            />
            <Route
              exact
              path={routes.MARKETPLACE_PURCHASE_HISTORY}
              component={Marketplace}
            />
            <Route
              exact
              path={routes.MARKETPLACE_MY_PRODUCTS}
              component={Marketplace}
            />
            <Route exact path={routes.PRODUCT} component={Product} />
            <Route
              exact
              path={routes.PRODUCT_SETTINGS}
              component={ProductSettings}
            />
            <Route
              exact
              path={routes.PRODUCT_SETTINGS_MAIN_INFO}
              component={ProductSettings}
            />
            <Route
              exact
              path={routes.PRODUCT_SETTINGS_ATTACHMENTS}
              component={ProductSettings}
            />
            <Route
              exact
              path={routes.PRODUCT_SETTINGS_USAGE_DETAILS}
              component={ProductSettings}
            />
            <Route
              exact
              path={routes.PRODUCT_SETTINGS_ADDITIONAL_INFO}
              component={ProductSettings}
            />
            <Route
              exact
              path={routes.PRODUCT_SETTINGS_MARKET_SPECIFICS}
              component={ProductSettings}
            />
            <Route
              exact
              path={routes.PRODUCT_SETTINGS_REVIEWER_INFO}
              component={ProductSettings}
            />
            <Route
              exact
              path={routes.PRODUCT_SETTINGS_SALES}
              component={ProductSettings}
            />
            <Route
              exact
              path={routes.PRODUCT_SETTINGS_DEMO_REQUESTS}
              component={ProductSettings}
            />
            <Route exact path={routes.STANDARDS} component={Standards} />
            <Route exact path={routes.STANDARD} component={Standard} />
            <Route
              exact
              path={routes.TERMS_AND_CONDITIONS}
              render={(props) => <InfoPage {...props} pageType="terms" />}
            />

            <Route
              exact
              path={[
                routes.BOOKMARKS,
                routes.BOOKMARKS_PEOPLE,
                routes.BOOKMARKS_GROUPS,
                routes.BOOKMARKS_POSTS,
                routes.BOOKMARKS_KNOWCRED,
                routes.BOOKMARKS_PRODUCTS,
                routes.BOOKMARKS_RECORDS,
                routes.BOOKMARKS_STANDARDS,
                routes.FOLDER,
                routes.FOLDER_PEOPLE,
                routes.FOLDER_GROUPS,
                routes.FOLDER_POSTS,
                routes.FOLDER_KNOWCRED,
                routes.FOLDER_PRODUCTS,
                routes.FOLDER_RECORDS,
                routes.FOLDER_STANDARDS,
              ]}
            >
              <Bookmarks />
            </Route>

            <Route exact path={routes.KNOWMIX_LIST} component={Knowmix} />
            <Route
              exact
              path={routes.KNOWMIX_LIST_MANAGED}
              component={Knowmix}
            />
            <Route
              exact
              path={routes.KNOWMIX_LIST_STARTED}
              component={Knowmix}
            />
            <Route
              exact
              path={routes.KNOWMIX_LIST_STARTED_MANAGED}
              component={Knowmix}
            />
            <Route
              exact
              path={routes.KNOWMIX_LIST_NOT_STARTED}
              component={Knowmix}
            />
            <Route
              exact
              path={routes.KNOWMIX_LIST_NOT_STARTED_MANAGED}
              component={Knowmix}
            />
            <Route
              exact
              path={routes.KNOWMIX_LIST_FINISHED}
              component={Knowmix}
            />
            <Route
              exact
              path={routes.KNOWMIX_LIST_FINISHED_MANAGED}
              component={Knowmix}
            />
            <Route
              exact
              path={routes.KNOWMIX_LIST_MY_GRADES}
              component={Knowmix}
            />
            <Route
              exact
              path={routes.KNOWMIX_LIST_MY_GRADES_STARTED}
              component={Knowmix}
            />
            <Route
              exact
              path={routes.KNOWMIX_LIST_MY_GRADES_FINISHED}
              component={Knowmix}
            />
            <Route exact path={routes.SUBMITTED_WORKS} component={Knowmix} />
            <Route
              exact
              path={routes.SUBMITTED_WORKS_EXAMINED}
              component={Knowmix}
            />
            <Route
              exact
              path={routes.SUBMITTED_WORKS_STEPS}
              component={Knowmix}
            />
            <Route
              exact
              path={routes.SUBMITTED_WORKS_STEPS_EXAMINED}
              component={Knowmix}
            />
            <Route
              exact
              path={routes.SUBMITTED_WORKS_KNOWMIX}
              component={Knowmix}
            />
            <Route
              exact
              path={routes.SUBMITTED_WORKS_KNOWMIX_EXAMINED}
              component={Knowmix}
            />

            <Route exact path={routes.KNOWMIX} component={CoursePage} />
            <Route
              exact
              path={[
                routes.KNOWMIX_SETTINGS,
                routes.KNOWMIX_SETTINGS_STEPS,
                routes.KNOWMIX_SETTINGS_MEMBERS,
                routes.KNOWMIX_SETTINGS_PUBLIC,
              ]}
              component={CourseSettings}
            />
            <Route
              exact
              path={routes.KNOWMIX_GRADES}
              component={KnowmixGrades}
            />
            <Route
              exact
              path={routes.KNOWMIX_GRADES_CHECK}
              component={KnowMixFullCheck}
            />
            <Route
              exact
              path={routes.KNOWMIX_CREATE_KNOWCRED}
              component={KnowmixKnowcredForm}
            />
            <Route
              exact
              path={routes.KNOWMIX_WALKTHROUGH}
              component={KnowMixWalkthrough}
            />
            <Route exact path={routes.KNOWMIX_CHECK} component={KnowMixCheck} />
            <Route
              exact
              path={[
                ...knowmapRoutes,
                // routes.INCOMING_SOLICIT_APPOINTMENT_AUTHORIZED,
              ]}
              component={Knowmap}
            />
          </Switch>
        </ErrorBoundary>
      </div>
    </div>
  )
}

export default LayoutWithSidebar
