import React, { Component } from "react"
import * as routes from "../../../library/constants/routes"
import { generatePath, Link, Route, Switch, withRouter } from "react-router-dom"
import KnowCredForm from "./knowCredForm"
import KnowCredSingle from "./knowCredSingle"
import KnowCredList from "./knowCredList"
import { connect } from "react-redux"
import VerticalMenu from "../../../components/ui/verticalMenu"
import { bindActionCreators } from "redux"
import { getPerson } from "../../../library/store/actions/creators/personCreators"
import avatarDefault from "../../../assets/img/user_avatar.svg"
import NotFound from "../../../components/notFound/notFound"
import getErrorText from "../../../library/constants/errorTypes"
import { ReplyOutlined } from "@material-ui/icons"
import { sharePost } from "../../../library/store/actions/creators/postsCreators"
import {
  knowCredGet,
  updateKnowcredPublicStatus,
} from "../../../library/store/actions/creators/knowCredsCreators"
import AddToFolderModal from "../bookmarks/folders/addToFolder/addToFolder"
import StarOutlined from "@material-ui/icons/StarOutlined"
import StarBorderOutlined from "@material-ui/icons/StarBorderOutlined"
import Button from "@material-ui/core/Button/Button"
import {
  createBookmark,
  deleteBookmark,
  foldersList,
} from "../../../library/store/actions/creators/bookmarksCreators"
import { withSnackbar } from "notistack"
import ComplaintsModal from "../../../components/complaints/complaintsModal"
import ErrorOutline from "@material-ui/icons/ErrorOutline"
import LockOutlined from "@material-ui/icons/LockOutlined"
import LockOpenOutlined from "@material-ui/icons/LockOpenOutlined"
import styled from "@emotion/styled"

const validatedRoutes = [
  routes.KNOWCRED_VALIDATED,
  routes.KNOWCRED_SOCIAL_VALIDATED,
  routes.KNOWCRED_EXTRA_VALIDATED,
  routes.KNOWCRED_GRADES_VALIDATED,
  routes.KNOWCRED_CERTIFICATES_VALIDATED,
  routes.KNOWCRED_DIPLOMAS_VALIDATED,
]

const grantedRoutes = [
  routes.KNOWCRED_GRANTED,
  routes.KNOWCRED_SOCIAL_GRANTED,
  routes.KNOWCRED_EXTRA_GRANTED,
  routes.KNOWCRED_GRADES_GRANTED,
  routes.KNOWCRED_CERTIFICATES_GRANTED,
  routes.KNOWCRED_DIPLOMAS_GRANTED,
]

const awardedRoutes = [
  routes.KNOWCRED_AWARDED,
  routes.KNOWCRED_EXTRA_AWARDED,
  routes.KNOWCRED_GRADES_AWARDED,
  routes.KNOWCRED_CERTIFICATES_AWARDED,
  routes.KNOWCRED_DIPLOMAS_AWARDED,
]

const validRoutes = [
  routes.KNOWCRED_VALID,
  routes.KNOWCRED_EXTRA_VALID,
  routes.KNOWCRED_GRADES_VALID,
  routes.KNOWCRED_CERTIFICATES_VALID,
  routes.KNOWCRED_DIPLOMAS_VALID,
]

const Controls = styled.div`
  & > div:not(:last-child) {
    margin-bottom: 15px;
  }
`

class KnowCred extends Component {
  state = {
    type: "my-profile",
    userInfo: {},
    dataReceived: false,
    error404: false,
    bookmarked: false,
    openAddToFolderDialog: false,
    complaintsModal: false,
    isReported: this.props.knowCreds.currentKnowCred
      ? this.props.knowCreds.currentKnowCred.is_reported
      : false,
    isPublic: this.props.knowCreds.currentKnowCred
      ? this.props.knowCreds.currentKnowCred.public
      : true,
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve)
    })
  }

  async componentDidMount() {
    const {
      auth: { userData },
    } = this.props

    if (this.props.match.params.userId) {
      const personId = parseInt(this.props.match.params.userId)
      if (userData.id === personId) {
        this.setState({
          type: "my-profile",
          userInfo: this.props.auth.userData,
          dataReceived: true,
          error404: false,
        })
      } else {
        try {
          await this.props.getPerson(userData.token, personId)
          this.setState({
            type: "other-profile",
            userInfo: this.props.person.personData,
            dataReceived: true,
            error404: false,
          })
        } catch ({ error }) {
          this.props.enqueueSnackbar(getErrorText(error.code), {
            variant: "error",
          })
        }
      }
    }
  }

  async componentDidUpdate(prevProps) {
    const {
      auth: { userData },
    } = this.props

    if (this.props.match.params.userId) {
      const personId = parseInt(this.props.match.params.userId)
      if (this.props.match.params.userId !== prevProps.match.params.userId) {
        this.setState({ dataReceived: false })
        if (userData.id === personId) {
          this.setState({
            type: "my-profile",
            dataReceived: true,
            error404: false,
          })
        } else {
          try {
            await this.props.getPerson(userData.token, personId)
            await this.setState({
              type: "other-profile",
              userInfo: this.props.person.personData,
              dataReceived: true,
              error404: false,
            })
          } catch ({ error }) {
            this.props.enqueueSnackbar(getErrorText(error.code), {
              variant: "error",
            })
          }
        }
      }
    } else if (
      this.props.knowCreds.currentKnowCred &&
      this.props.knowCreds.currentKnowCred !==
        prevProps.knowCreds.currentKnowCred
    ) {
      this.setState({
        bookmarked: !!this.props.knowCreds.currentKnowCred.bookmark,
        isReported: this.props.knowCreds.currentKnowCred.is_reported,
        isPublic: this.props.knowCreds.currentKnowCred.public,
      })
    }
  }

  showError = (hasError) => {
    this.setState({ error404: hasError })
  }

  handleClickOpen = (type) => {
    switch (type) {
      case "addToFolder":
        this.setState({ openAddToFolderDialog: true })
        break

      default:
        return
    }
  }

  handleClose = (type) => {
    switch (type) {
      case "addToFolder":
        this.setState({ openAddToFolderDialog: false })
        break

      default:
        return
    }
  }

  addToBookmarks = async () => {
    const {
      knowCreds: {
        currentKnowCred: { id },
      },
    } = this.props

    try {
      await this.props.createBookmark({ knowcred_id: id })
      await this.props.foldersList()
      this.setState({ bookmarked: true })
      await this.props.enqueueSnackbar("Saved to bookmarks", {
        autoHideDuration: 5000,
        action: (key) => {
          return (
            <>
              <Button
                color="primary"
                onClick={() => {
                  this.props.closeSnackbar(key)
                  this.props.history.push(routes.BOOKMARKS_KNOWCRED)
                }}
              >
                View
              </Button>
              {this.props.bookmarks.folders.length > 0 && (
                <Button
                  color="primary"
                  onClick={() => this.handleClickOpen("addToFolder")}
                >
                  Add to folder
                </Button>
              )}
            </>
          )
        },
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  deleteBookmark = async () => {
    const {
      knowCreds: {
        currentKnowCred: { id },
      },
    } = this.props

    this.props
      .deleteBookmark({ knowcred_id: id })
      .then(this.setState({ bookmarked: false }))
  }

  processShare = async () => {
    const { knowCreds } = this.props
    try {
      await this.props.sharePost({ knowcred_id: knowCreds.currentKnowCred.id })
      await this.props.knowCredGet({ id: knowCreds.currentKnowCred.id })
      this.props.enqueueSnackbar("Cred has been shared", {
        variant: "success",
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }
  showComplaintsModal = () => {
    this.setState({ complaintsModal: true })
  }
  hideComplaintsModal = () => {
    this.setState({ complaintsModal: false })
  }

  changeVisibility = async (is_public) => {
    try {
      await this.props.updateKnowcredPublicStatus({
        id: this.props.knowCreds.currentKnowCred.id,
        is_public,
      })
      this.setState({ isPublic: is_public })
      this.props.enqueueSnackbar(
        `Cred status changed to ${is_public ? "public" : "private"}`,
        { variant: "success" }
      )
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  composeMenuItems = () => {
    const {
      auth: { userData },
      match: {
        params: { userId },
        path,
      },
    } = this.props
    if (grantedRoutes.includes(path)) {
      return [
        {
          path: generatePath(routes.KNOWCRED_GRANTED, {
            userId: userId || userData.id,
          }),
          label: "All Creds",
        },
        {
          path: generatePath(routes.KNOWCRED_SOCIAL_GRANTED, {
            userId: userId || userData.id,
          }),
          label: "Social Creds",
        },
        {
          path: generatePath(routes.KNOWCRED_EXTRA_GRANTED, {
            userId: userId || userData.id,
          }),
          label: "Extra - Curricular Creds",
        },
        {
          path: generatePath(routes.KNOWCRED_GRADES_GRANTED, {
            userId: userId || userData.id,
          }),
          label: "Grades/Marks Creds",
        },
        {
          path: generatePath(routes.KNOWCRED_CERTIFICATES_GRANTED, {
            userId: userId || userData.id,
          }),
          label: "Certificates Creds",
        },
        {
          path: generatePath(routes.KNOWCRED_DIPLOMAS_GRANTED, {
            userId: userId || userData.id,
          }),
          label: "Diplomas & Degrees Creds",
        },
      ]
    } else if (awardedRoutes.includes(path)) {
      return [
        {
          path: generatePath(routes.KNOWCRED_AWARDED, {
            userId: userId || userData.id,
          }),
          label: "All Creds",
        },
        {
          path: generatePath(routes.KNOWCRED_EXTRA_AWARDED, {
            userId: userId || userData.id,
          }),
          label: "Extra - Curricular Creds",
        },
        {
          path: generatePath(routes.KNOWCRED_GRADES_AWARDED, {
            userId: userId || userData.id,
          }),
          label: "Grades/Marks Creds",
        },
        {
          path: generatePath(routes.KNOWCRED_CERTIFICATES_AWARDED, {
            userId: userId || userData.id,
          }),
          label: "Certificates Creds",
        },
        {
          path: generatePath(routes.KNOWCRED_DIPLOMAS_AWARDED, {
            userId: userId || userData.id,
          }),
          label: "Diplomas & Degrees Creds",
        },
      ]
    } else if (validRoutes.includes(path)) {
      return [
        {
          path: generatePath(routes.KNOWCRED_VALID, {
            userId: userId || userData.id,
          }),
          label: "All Creds",
        },
        {
          path: generatePath(routes.KNOWCRED_EXTRA_VALID, {
            userId: userId || userData.id,
          }),
          label: "Extra - Curricular Creds",
        },
        {
          path: generatePath(routes.KNOWCRED_GRADES_VALID, {
            userId: userId || userData.id,
          }),
          label: "Grades/Marks Creds",
        },
        {
          path: generatePath(routes.KNOWCRED_CERTIFICATES_VALID, {
            userId: userId || userData.id,
          }),
          label: "Certificates Creds",
        },
        {
          path: generatePath(routes.KNOWCRED_DIPLOMAS_VALID, {
            userId: userId || userData.id,
          }),
          label: "Diplomas & Degrees Creds",
        },
      ]
    } else if (validatedRoutes.includes(path)) {
      return [
        { path: routes.KNOWCRED_VALIDATED, label: "All Creds" },
        { path: routes.KNOWCRED_SOCIAL_VALIDATED, label: "Social Creds" },
        {
          path: routes.KNOWCRED_EXTRA_VALIDATED,
          label: "Extra - Curricular Creds",
        },
        { path: routes.KNOWCRED_GRADES_VALIDATED, label: "Grades/Marks Creds" },
        {
          path: routes.KNOWCRED_CERTIFICATES_VALIDATED,
          label: "Certificates Creds",
        },
        {
          path: routes.KNOWCRED_DIPLOMAS_VALIDATED,
          label: "Diplomas & Degrees Creds",
        },
      ]
    } else {
      return [
        {
          path: generatePath(routes.KNOWCRED, {
            userId: userId || userData.id,
          }),
          label: "All Creds",
        },
        {
          path: generatePath(routes.KNOWCRED_SOCIAL, {
            userId: userId || userData.id,
          }),
          label: "Social Creds",
        },
        {
          path: generatePath(routes.KNOWCRED_EXTRA, {
            userId: userId || userData.id,
          }),
          label: "Extra - Curricular Creds",
        },
        {
          path: generatePath(routes.KNOWCRED_GRADES, {
            userId: userId || userData.id,
          }),
          label: "Grades/Marks Creds",
        },
        {
          path: generatePath(routes.KNOWCRED_CERTIFICATES, {
            userId: userId || userData.id,
          }),
          label: "Certificates Creds",
        },
        {
          path: generatePath(routes.KNOWCRED_DIPLOMAS, {
            userId: userId || userData.id,
          }),
          label: "Diplomas & Degrees Creds",
        },
      ]
    }
  }

  render() {
    const {
      auth: { userData },
      knowCreds,
    } = this.props
    const { type, userInfo, dataReceived, error404, bookmarked, isPublic } =
      this.state

    if (error404) {
      return <NotFound entity="Cred" />
    }

    return (
      <div className="common-page__content">
        <main className="common-page__main">
          <Switch>
            <Route
              exact
              path={routes.KNOWCRED}
              render={(props) => {
                return (
                  <KnowCredList
                    {...props}
                    showError={this.showError}
                    type={this.state.type}
                  />
                )
              }}
            />
            <Route
              exact
              path={routes.KNOWCRED_VALIDATED}
              render={(props) => {
                return (
                  <KnowCredList
                    {...props}
                    showError={this.showError}
                    type={this.state.type}
                  />
                )
              }}
            />
            <Route
              exact
              path={routes.KNOWCRED_GRANTED}
              render={(props) => {
                return (
                  <KnowCredList
                    {...props}
                    showError={this.showError}
                    type={this.state.type}
                  />
                )
              }}
            />
            <Route
              exact
              path={routes.KNOWCRED_AWARDED}
              render={(props) => {
                return (
                  <KnowCredList
                    {...props}
                    showError={this.showError}
                    type={this.state.type}
                  />
                )
              }}
            />
            <Route
              exact
              path={routes.KNOWCRED_VALID}
              render={(props) => {
                return (
                  <KnowCredList
                    {...props}
                    showError={this.showError}
                    type={this.state.type}
                  />
                )
              }}
            />
            <Route
              exact
              path={routes.KNOWCRED_SOCIAL}
              render={(props) => {
                return (
                  <KnowCredList
                    {...props}
                    showError={this.showError}
                    type={this.state.type}
                  />
                )
              }}
            />
            <Route
              exact
              path={routes.KNOWCRED_SOCIAL_VALIDATED}
              render={(props) => {
                return (
                  <KnowCredList
                    {...props}
                    showError={this.showError}
                    type={this.state.type}
                  />
                )
              }}
            />
            <Route
              exact
              path={routes.KNOWCRED_SOCIAL_GRANTED}
              render={(props) => {
                return (
                  <KnowCredList
                    {...props}
                    showError={this.showError}
                    type={this.state.type}
                  />
                )
              }}
            />
            <Route
              exact
              path={routes.KNOWCRED_EXTRA}
              render={(props) => {
                return (
                  <KnowCredList
                    {...props}
                    showError={this.showError}
                    type={this.state.type}
                  />
                )
              }}
            />
            <Route
              exact
              path={routes.KNOWCRED_EXTRA_VALIDATED}
              render={(props) => {
                return (
                  <KnowCredList
                    {...props}
                    showError={this.showError}
                    type={this.state.type}
                  />
                )
              }}
            />
            <Route
              exact
              path={routes.KNOWCRED_EXTRA_GRANTED}
              render={(props) => {
                return (
                  <KnowCredList
                    {...props}
                    showError={this.showError}
                    type={this.state.type}
                  />
                )
              }}
            />
            <Route
              exact
              path={routes.KNOWCRED_EXTRA_AWARDED}
              render={(props) => {
                return (
                  <KnowCredList
                    {...props}
                    showError={this.showError}
                    type={this.state.type}
                  />
                )
              }}
            />
            <Route
              exact
              path={routes.KNOWCRED_EXTRA_VALID}
              render={(props) => {
                return (
                  <KnowCredList
                    {...props}
                    showError={this.showError}
                    type={this.state.type}
                  />
                )
              }}
            />
            <Route
              exact
              path={routes.KNOWCRED_GRADES}
              render={(props) => {
                return (
                  <KnowCredList
                    {...props}
                    showError={this.showError}
                    type={this.state.type}
                  />
                )
              }}
            />
            <Route
              exact
              path={routes.KNOWCRED_GRADES_VALIDATED}
              render={(props) => {
                return (
                  <KnowCredList
                    {...props}
                    showError={this.showError}
                    type={this.state.type}
                  />
                )
              }}
            />
            <Route
              exact
              path={routes.KNOWCRED_GRADES_GRANTED}
              render={(props) => {
                return (
                  <KnowCredList
                    {...props}
                    showError={this.showError}
                    type={this.state.type}
                  />
                )
              }}
            />
            <Route
              exact
              path={routes.KNOWCRED_GRADES_AWARDED}
              render={(props) => {
                return (
                  <KnowCredList
                    {...props}
                    showError={this.showError}
                    type={this.state.type}
                  />
                )
              }}
            />
            <Route
              exact
              path={routes.KNOWCRED_GRADES_VALID}
              render={(props) => {
                return (
                  <KnowCredList
                    {...props}
                    showError={this.showError}
                    type={this.state.type}
                  />
                )
              }}
            />
            <Route
              exact
              path={routes.KNOWCRED_CERTIFICATES}
              render={(props) => {
                return (
                  <KnowCredList
                    {...props}
                    showError={this.showError}
                    type={this.state.type}
                  />
                )
              }}
            />
            <Route
              exact
              path={routes.KNOWCRED_CERTIFICATES_VALIDATED}
              render={(props) => {
                return (
                  <KnowCredList
                    {...props}
                    showError={this.showError}
                    type={this.state.type}
                  />
                )
              }}
            />
            <Route
              exact
              path={routes.KNOWCRED_CERTIFICATES_GRANTED}
              render={(props) => {
                return (
                  <KnowCredList
                    {...props}
                    showError={this.showError}
                    type={this.state.type}
                  />
                )
              }}
            />
            <Route
              exact
              path={routes.KNOWCRED_CERTIFICATES_AWARDED}
              render={(props) => {
                return (
                  <KnowCredList
                    {...props}
                    showError={this.showError}
                    type={this.state.type}
                  />
                )
              }}
            />
            <Route
              exact
              path={routes.KNOWCRED_CERTIFICATES_VALID}
              render={(props) => {
                return (
                  <KnowCredList
                    {...props}
                    showError={this.showError}
                    type={this.state.type}
                  />
                )
              }}
            />
            <Route
              exact
              path={routes.KNOWCRED_DIPLOMAS}
              render={(props) => {
                return (
                  <KnowCredList
                    {...props}
                    showError={this.showError}
                    type={this.state.type}
                  />
                )
              }}
            />
            <Route
              exact
              path={routes.KNOWCRED_DIPLOMAS_VALIDATED}
              render={(props) => {
                return (
                  <KnowCredList
                    {...props}
                    showError={this.showError}
                    type={this.state.type}
                  />
                )
              }}
            />
            <Route
              exact
              path={routes.KNOWCRED_DIPLOMAS_GRANTED}
              render={(props) => {
                return (
                  <KnowCredList
                    {...props}
                    showError={this.showError}
                    type={this.state.type}
                  />
                )
              }}
            />
            <Route
              exact
              path={routes.KNOWCRED_DIPLOMAS_AWARDED}
              render={(props) => {
                return (
                  <KnowCredList
                    {...props}
                    showError={this.showError}
                    type={this.state.type}
                  />
                )
              }}
            />
            <Route
              exact
              path={routes.KNOWCRED_DIPLOMAS_VALID}
              render={(props) => {
                return (
                  <KnowCredList
                    {...props}
                    showError={this.showError}
                    type={this.state.type}
                  />
                )
              }}
            />

            <Route
              exact
              path={routes.KNOWCRED_CREATE}
              render={(props) => {
                return <KnowCredForm {...props} showError={this.showError} />
              }}
            />

            <Route
              exact
              path={routes.KNOWCRED_SINGLE}
              render={(props) => {
                return <KnowCredSingle {...props} showError={this.showError} />
              }}
            />

            <Route
              exact
              path={routes.KNOWCRED_EDIT}
              render={(props) => {
                return <KnowCredForm {...props} showError={this.showError} />
              }}
            />
          </Switch>
        </main>

        <aside className="common-page__sidebar">
          {dataReceived && type === "other-profile" && (
            <div className="box aside-header">
              <Link
                to={generatePath(routes.USER, { userId: userInfo.id })}
                className="box__content aside-header__content"
              >
                <img
                  src={userInfo.photo || avatarDefault}
                  className="aside-header__img"
                  alt=""
                />
                <div className="aside-header__info">
                  <div className="aside-header__name">
                    {`${userInfo.first_name} ${userInfo.last_name}`}
                  </div>
                  <div className="aside-header__help">back to page</div>
                </div>
              </Link>
            </div>
          )}
          {this.props.match.path === routes.KNOWCRED_SINGLE &&
            !!knowCreds.currentKnowCred &&
            !!knowCreds.currentKnowCred.id && (
              <div className="box">
                {this.state.complaintsModal && (
                  <ComplaintsModal
                    open={this.state.complaintsModal}
                    knowcred_id={knowCreds.currentKnowCred.id}
                    onClose={this.hideComplaintsModal}
                    callback={() => this.setState({ isReported: true })}
                  />
                )}
                <div className="box__content">
                  <Controls>
                    {isPublic && (
                      <div className="link" onClick={this.processShare}>
                        <ReplyOutlined
                          className={`flip-h ${
                            !!knowCreds.currentKnowCred &&
                            knowCreds.currentKnowCred.is_shared
                              ? "color-primary"
                              : "color-black-38"
                          } mr8`}
                        />{" "}
                        Share with friends
                      </div>
                    )}

                    {!bookmarked ? (
                      <div className={`link`} onClick={this.addToBookmarks}>
                        <StarBorderOutlined className="mr8 color-black-38" />{" "}
                        Add to bookmarks
                      </div>
                    ) : (
                      <div className={`link`} onClick={this.deleteBookmark}>
                        <StarOutlined className="mr8 color-primary" /> Remove
                        from bookmarks
                      </div>
                    )}

                    {knowCreds.currentKnowCred.owner.id === userData.id &&
                      (!isPublic ? (
                        <div
                          className={"link"}
                          onClick={() => this.changeVisibility(true)}
                        >
                          <LockOutlined className="mr8 color-primary" /> Make
                          public
                        </div>
                      ) : (
                        <div
                          className={"link"}
                          onClick={() => this.changeVisibility(false)}
                        >
                          <LockOpenOutlined className="mr8 color-black-38" />{" "}
                          Make private
                        </div>
                      ))}

                    {knowCreds.currentKnowCred.creator.id !== userData.id && (
                      <div
                        className={`link ${
                          this.state.isReported ? "link--disabled" : ""
                        }`}
                        onClick={() =>
                          !this.state.isReported && this.showComplaintsModal()
                        }
                      >
                        <ErrorOutline className="color-black-38 mr8" /> Report
                      </div>
                    )}
                  </Controls>
                </div>
              </div>
            )}

          {this.props.match.path !== routes.KNOWCRED_SINGLE && (
            <VerticalMenu menuItems={this.composeMenuItems()} />
          )}
        </aside>
        {this.state.openAddToFolderDialog && (
          <AddToFolderModal
            open={this.state.openAddToFolderDialog}
            handleClose={() => this.handleClose("addToFolder")}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = ({ auth, person, knowCreds, bookmarks }) => ({
  auth,
  person,
  knowCreds,
  bookmarks,
})
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getPerson,
      sharePost,
      knowCredGet,
      createBookmark,
      deleteBookmark,
      foldersList,
      updateKnowcredPublicStatus,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withSnackbar(KnowCred)))
