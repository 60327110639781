import React from "react"
import { connect } from "react-redux"
import { getStandard } from "../../../../library/store/actions/creators/standardsCreators"
import { sharePost } from "../../../../library/store/actions/creators/postsCreators"
import { bindActionCreators } from "redux"
import { ReplyOutlined } from "@material-ui/icons"
import Loader from "../../../../components/ui/loader"
import getErrorText from "../../../../library/constants/errorTypes"
import { withSnackbar } from "notistack"
import { standardsGrades as grades } from "../../../../library/constants/educationalDictionary"
import { statesAndThames } from "../../../../library/constants/statesAndThames"
import ExpandableText from "../../../../components/expandableText"
import StarOutlined from "@material-ui/icons/StarOutlined"
import StarBorderOutlined from "@material-ui/icons/StarBorderOutlined"
import Button from "@material-ui/core/Button/Button"
import * as routes from "../../../../library/constants/routes"
import {
  createBookmark,
  deleteBookmark,
  foldersList,
} from "../../../../library/store/actions/creators/bookmarksCreators"
import AddToFolderModal from "../../bookmarks/folders/addToFolder/addToFolder"

class Standard extends React.PureComponent {
  state = {
    dataReceived: false,
    standard: null,
    bookmarked: false,
    openAddToFolderDialog: false,
  }

  async componentDidMount() {
    const {
      match: { params },
    } = this.props
    try {
      await this.props.getStandard({ id: parseInt(params.standardId) })
      this.setState({
        standard: this.props.standards.currentStandard,
        bookmarked: !!this.props.standards.currentStandard.bookmark,
        dataReceived: true,
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  handleClickOpen = (type) => {
    switch (type) {
      case "addToFolder":
        this.setState({ openAddToFolderDialog: true })
        break

      default:
        return
    }
  }

  handleClose = (type) => {
    switch (type) {
      case "addToFolder":
        this.setState({ openAddToFolderDialog: false })
        break

      default:
        return
    }
  }

  addToBookmarks = async () => {
    const {
      standards: {
        currentStandard: { id },
      },
    } = this.props

    try {
      await this.props.createBookmark({ standard_id: id })
      await this.props.foldersList()
      this.setState({ bookmarked: true })
      await this.props.enqueueSnackbar("Saved to bookmarks", {
        autoHideDuration: 5000,
        action: (key) => {
          return (
            <>
              <Button
                color="primary"
                onClick={() => {
                  this.props.closeSnackbar(key)
                  this.props.history.push(routes.BOOKMARKS_STANDARDS)
                }}
              >
                View
              </Button>
              {this.props.bookmarks.folders.length > 0 && (
                <Button
                  color="primary"
                  onClick={() => this.handleClickOpen("addToFolder")}
                >
                  Add to folder
                </Button>
              )}
            </>
          )
        },
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  deleteBookmark = async () => {
    const {
      standards: {
        currentStandard: { id },
      },
    } = this.props
    try {
      this.props
        .deleteBookmark({ standard_id: id })
        .then(this.setState({ bookmarked: false }))
      this.props.enqueueSnackbar("Standard deleted from bookmarks", {
        variant: "success",
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  processShare = async () => {
    const { standard } = this.state
    try {
      await this.props.sharePost({ standard_id: standard.id })
      await this.props.getStandard({ id: standard.id })
      this.setState({
        standard: this.props.standards.currentStandard,
      })
      this.props.enqueueSnackbar("Standard shared", { variant: "success" })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  render() {
    const { standard, bookmarked, dataReceived } = this.state
    if (!standard) return null
    const state = statesAndThames[standard.state - 1]
    const subjects = state["subjects"]
    return (
      <div className="common-page__content common-page__content--static">
        {standard && (
          <main className="common-page__main">
            <div className="box">
              <div className="box__heading">{standard.name}</div>
              <div className="box__content">
                <table className="product-tile__info">
                  <tbody>
                    <tr>
                      <td className={`product-tile__info-label pb4`}>
                        <span>State:</span>
                      </td>
                      <td className={`product-tile__info-value pb4`}>
                        <span>{state.name}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="product-tile__info-label">
                        <span>Subject:</span>
                      </td>
                      <td className="product-tile__info-value">
                        <span>
                          {subjects.find((s) => s.id === standard.subject).name}
                        </span>
                      </td>
                    </tr>
                    {!!standard.grades.length && (
                      <tr>
                        <td className="product-tile__info-label">
                          <span>Grades:</span>
                        </td>
                        <td className="product-tile__info-value">
                          <div>
                            {standard.grades
                              .map((g) => grades.find((gr) => gr.id === g).name)
                              .join(", ")}
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <ExpandableText limit={250} text={standard.description} />
              </div>
            </div>
          </main>
        )}
        {!dataReceived && <Loader />}
        <aside className="common-page__sidebar">
          <div className="box">
            <div className="box__content">
              <div className="link mb15" onClick={this.processShare}>
                <ReplyOutlined
                  className={`flip-h mr8 ${
                    standard && standard.is_shared
                      ? "color-primary"
                      : "color-black-38"
                  }`}
                />{" "}
                Share with friends
              </div>

              {!bookmarked ? (
                <div className="link" onClick={this.addToBookmarks}>
                  <StarBorderOutlined className="mr8 color-black-38" /> Add to
                  bookmarks
                </div>
              ) : (
                <div className="link" onClick={this.deleteBookmark}>
                  <StarOutlined className="mr8 color-primary" /> Remove from
                  bookmarks
                </div>
              )}
            </div>
          </div>
        </aside>
        {this.state.openAddToFolderDialog && (
          <AddToFolderModal
            open={this.state.openAddToFolderDialog}
            handleClose={() => this.handleClose("addToFolder")}
          />
        )}
      </div>
    )
  }
}

const mapState = ({standards, bookmarks}) => ({standards, bookmarks});
const mapDispatch = dispatch => bindActionCreators({
    getStandard,
    sharePost,
    createBookmark,
    deleteBookmark,
    foldersList,
}, dispatch);
export default connect(mapState, mapDispatch)(withSnackbar(Standard));
