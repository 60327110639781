import React, { Component } from "react"

import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import AddOutlined from "@material-ui/icons/AddOutlined"
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel"
import Radio from "@material-ui/core/Radio/Radio"
import FormControl from "@material-ui/core/FormControl/FormControl"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import Button from "@material-ui/core/Button/Button"
import { DateTimePicker } from "@material-ui/pickers"
import { GROUP_TYPES } from "../../../../../library/constants/groupTypes"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import { createGroup } from "../../../../../library/store/actions/creators/groupsCreators"
import { generatePath, withRouter } from "react-router-dom"
import * as routes from "../../../../../library/constants/routes"
import {
  phoneValidation,
  urlValidation,
} from "../../../../../library/utils/validators"
import FormHelperText from "@material-ui/core/FormHelperText"
import defaultDate, {
  defaultMaxDate,
} from "../../../../../library/constants/defaultDates"
import getErrorText from "../../../../../library/constants/errorTypes"
import { GROUPS } from "../../../../../library/store/actions/types/groupsTypes"
import { call } from "../../../../../library/networking/API"
import MultiSelect from "../../../../../components/ui/multiSelect"

class GroupCreateForm extends Component {
  state = {
    type: this.props.type,
    name: "",
    description: "",
    website: "",
    closed: false,
    start_date: null,
    end_date: null,
    activeAddress: false,
    country: "",
    state: "",
    city: "",
    address: "",
    zip_code: "",
    phone: "",
    addressError: false,
    mainContentError: false,
    dateError: false,
    districtsList: [],
    chosenDistrict: null,
    districtsReceived: true,
    districtRequest: "",
  }

  async componentDidMount() {
    ValidatorForm.addValidationRule("isLink", (value) => urlValidation(value))
    ValidatorForm.addValidationRule("isPhone", (value) =>
      phoneValidation(value)
    )
    if (this.props.type === 4) {
      try {
        this.setState({ districtsReceived: false })
        const districts = await this.getDistricts()
        this.setState({
          districtsList: districts,
          districtsReceived: true,
        })
      } catch ({ error }) {
        this.props.enqueueSnackbar(getErrorText(error.code), {
          variant: "error",
        })
      }
    }
  }

  getDistricts = async ({ offset = 0, limit = 100 } = {}) => {
    const { token } = this.props.auth.userData
    const response = await call(GROUPS.SEARCH, {
      token,
      name: this.state.districtRequest || undefined,
      type: 5,
      limit,
      offset,
    })
    const data = await response.data
    if (response.status === 200) {
      return data.groups
    }
    throw data.error
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    })
  }

  handleGroupAccess = (name) => (event) => {
    this.setState({
      closed: !JSON.parse(event.target.value),
    })
  }

  handleDistrictRequestChange = async (value) => {
    this.setState(
      {
        districtRequest: value,
        districtsList: [],
        districtsReceived: false,
      },
      async (_) => {
        const districts = await this.getDistricts()
        this.setState({
          districtsList: districts,
          districtsReceived: true,
        })
      }
    )
  }

  handleDistrictsScroll = async () => {
    if (!this.state.districtsReceived) return
    this.setState({ districtsReceived: false })
    const districts = await this.getDistricts({
      offset: this.state.districtsList.length,
    })
    this.setState({
      districtsList: [...this.state.districtsList, ...districts],
      districtsReceived: true,
    })
  }

  handleDistrictsChoice = (district) => {
    this.setState({
      chosenDistrict: district,
    })
  }

  handleDateChange = (date, name) => {
    const parsedDate = date ? new Date(date).toISOString() : null
    this.setState({ [name]: parsedDate })
    name === "start_date" &&
      this.setState({
        dateError: !parsedDate,
      })
  }

  handleCancelButton = () => {
    const { activeAddress } = this.state

    activeAddress
      ? this.setState({ activeAddress: false })
      : this.props.handleCancelButton()
  }

  processAddressSave = () => {
    const {
      type,
      name,
      description,
      closed,
      start_date,
      end_date,
      website,
      country,
      state,
      city,
      address,
      zip_code,
      phone,
    } = this.state

    this.props
      .createGroup({
        type,
        name,
        description,
        closed,
        start_date: start_date || undefined,
        end_date: end_date || undefined,
        website,
        country,
        state,
        city,
        address,
        zip_code,
        phone,
      })
      .then((groupId) =>
        this.props.history.push({
          pathname: generatePath(routes.GROUP, { groupId }),
        })
      )
  }

  processInfoSave = () => {
    const {
      type,
      name,
      description,
      closed,
      website,
      country,
      state,
      city,
      address,
      zip_code,
      phone,
      addressError,
      mainContentError,
      dateError,
      chosenDistrict,
    } = this.state
    if (addressError || mainContentError || dateError) return
    const start_date = this.state.start_date
      ? this.state.start_date
      : new Date().toISOString()
    const end_date =
      this.state.end_date !== "" ? this.state.end_date : this.state.start_date
    this.props
      .createGroup({
        type,
        name,
        description,
        closed,
        district_id:
          type === 4
            ? (chosenDistrict && chosenDistrict.value) || undefined
            : undefined,
        start_date:
          type === 2 ? (start_date ? start_date : defaultDate) : undefined,
        end_date:
          type === 2 ? (end_date ? end_date : defaultMaxDate) : undefined,
        website,
        country,
        state,
        city,
        address,
        zip_code,
        phone,
      })
      .then((groupId) =>
        this.props.history.push({
          pathname: generatePath(routes.GROUP, { groupId }),
        })
      )
  }

  validateCustomFields = () => {
    const {
      type,
      name,
      description,
      start_date,
      website,
      country,
      state,
      city,
      address,
      zip_code,
      phone,
      activeAddress,
    } = this.state
    const requireAddress = [3, 4, 5, 7]
    if (
      requireAddress.includes(type) &&
      (!address || !country || !state || !city || !zip_code || !phone)
    ) {
      this.setState({
        addressError: true,
      })
    } else {
      this.setState({
        addressError: false,
      })
    }
    if (
      activeAddress &&
      (!name ||
        !description ||
        (type === 3 && !website) ||
        (type === 2 && !start_date))
    ) {
      this.setState({
        mainContentError: true,
      })
    } else {
      this.setState({
        mainContentError: false,
      })
    }
    if (type === 2 && !start_date) {
      this.setState({
        dateError: true,
      })
    } else {
      this.setState({
        dateError: false,
      })
    }
  }

  render() {
    const {
      type,
      name,
      description,
      closed,
      start_date,
      end_date,
      website,
      country,
      state,
      city,
      address,
      zip_code,
      phone,
      activeAddress,
      addressError,
      mainContentError,
      dateError,
      chosenDistrict,
      districtsList,
    } = this.state
    const requireAddress = [3, 4, 5, 7]
    const groupPlaceholder = GROUP_TYPES[type].title.toLowerCase()
    return (
      <ValidatorForm onSubmit={this.processInfoSave} instantValidate={true}>
        <DialogContent>
          <div className="group-create">
            <div className="group-create__header">
              <h3 className="group-create__title">
                {!activeAddress ? GROUP_TYPES[type].title : "Address"}
              </h3>
            </div>
            {!activeAddress ? (
              <div className="form form--modal">
                <div className="form__fieldset">
                  <label htmlFor="name" className="form__label">
                    {type === 1
                      ? "Group"
                      : GROUP_TYPES[type].compactTitle ||
                        GROUP_TYPES[type].title}{" "}
                    name *
                  </label>
                  <div className="form__fieldbox">
                    <div className="form__input">
                      <TextValidator
                        id="name"
                        name="name"
                        key="name"
                        value={name}
                        placeholder={`Type ${
                          type === 1 ? "group" : groupPlaceholder
                        } name`}
                        className="edit-form__input"
                        margin="normal"
                        fullWidth
                        validators={[
                          "required",
                          "minStringLength:3",
                          "maxStringLength:100",
                        ]}
                        errorMessages={[
                          "Field is required",
                          "Min length is 3 characters",
                          "Max length is 100 characters",
                        ]}
                        withRequiredValidator
                        onChange={this.handleChange("name")}
                      />
                    </div>
                  </div>
                </div>

                {type === 4 && (
                  <div className="form__fieldset">
                    <label htmlFor="name" className="form__label">
                      District
                    </label>
                    <div className="form__fieldbox">
                      <div className="form__input form__input--select">
                        <MultiSelect
                          list={districtsList}
                          placeholder="Select district"
                          defaultValue={chosenDistrict}
                          onInputChange={this.handleDistrictRequestChange}
                          onChange={this.handleDistrictsChoice}
                          onScroll={this.handleDistrictsScroll}
                        />
                      </div>
                    </div>
                  </div>
                )}

                <div className="form__fieldset">
                  <label htmlFor="description" className="form__label">
                    Description *
                  </label>
                  <div className="form__fieldbox">
                    <div className="form__input">
                      <TextValidator
                        id="description"
                        name="description"
                        key="description"
                        value={description}
                        placeholder={`Type a short ${
                          type === 1 ? "group" : groupPlaceholder
                        } description`}
                        className="edit-form__input"
                        margin="normal"
                        fullWidth
                        multiline
                        validators={["required", "maxStringLength:5000"]}
                        errorMessages={[
                          "Field is required",
                          "Max length is 5000 characters",
                        ]}
                        withRequiredValidator
                        onChange={this.handleChange("description")}
                      />
                    </div>
                  </div>
                </div>

                <div className="form__fieldset">
                  <label htmlFor="group-type" className="form__label">
                    {type !== 2 ? "Group type *" : "Event type *"}
                  </label>
                  <div className="form__fieldbox">
                    <div className="form__input">
                      <FormControl component="fieldset" id="group-type">
                        <RadioGroup
                          aria-label="group-type"
                          name="groupType"
                          value={(!closed).toString()}
                          onChange={this.handleGroupAccess("closed")}
                        >
                          <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label="Open"
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label="Closed"
                            labelPlacement="end"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                </div>

                {type === 2 && (
                  <>
                    <div className="form__fieldset">
                      <label htmlFor="start-date" className="form__label">
                        Start date *
                      </label>
                      <div className="form__fieldbox">
                        <div className="form__input">
                          <FormControl error={dateError} fullWidth>
                            <DateTimePicker
                              id="start-date"
                              value={start_date}
                              emptyLabel="Select start date"
                              invalidLabel="Select start date"
                              allowKeyboardControl={false}
                              fullWidth
                              clearable
                              format="d MMMM 'at' h:mm a"
                              margin="normal"
                              disablePast
                              error={dateError}
                              maxDate={
                                end_date
                                  ? new Date(end_date)
                                  : new Date(2100, 11, 31, 23, 59, 59)
                              }
                              onChange={(date) =>
                                this.handleDateChange(date, "start_date")
                              }
                            />
                            {dateError && (
                              <FormHelperText style={{ color: "#9a3c1c" }}>
                                Field is required
                              </FormHelperText>
                            )}
                          </FormControl>
                        </div>
                      </div>
                    </div>

                    <div className="form__fieldset">
                      <label htmlFor="finish-date" className="form__label">
                        End date
                      </label>
                      <div className="form__fieldbox">
                        <div className="form__input">
                          <DateTimePicker
                            id="finish-date"
                            value={end_date}
                            emptyLabel="Select end date"
                            invalidLabel="Select end date"
                            allowKeyboardControl={false}
                            fullWidth
                            clearable
                            format="d MMMM 'at' h:mm a"
                            minDate={
                              start_date
                                ? new Date(start_date)
                                : new Date(1910, 1, 1, 23, 59, 59)
                            }
                            margin="normal"
                            onChange={(date) =>
                              this.handleDateChange(date, "end_date")
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <div className="form__fieldset">
                  <label htmlFor="website" className="form__label">
                    {`Website${type === 3 ? " *" : ""}`}
                  </label>
                  <div className="form__fieldbox">
                    <div className="form__input">
                      <TextValidator
                        id="website"
                        name="website"
                        value={website}
                        placeholder={`Type ${
                          type === 1 ? "group" : groupPlaceholder
                        } website`}
                        className="edit-form__input"
                        margin="normal"
                        fullWidth
                        validators={
                          type === 3
                            ? ["required", "isLink", "maxStringLength:250"]
                            : ["isLink", "maxStringLength:250"]
                        }
                        errorMessages={
                          type === 3
                            ? [
                                "Field is required",
                                "Invalid URL",
                                "Max length is 250 characters",
                              ]
                            : ["Invalid URL", "Max length is 250 characters"]
                        }
                        withRequiredValidator
                        onChange={this.handleChange("website")}
                      />
                    </div>
                  </div>
                </div>
                <div className="form__fieldset">
                  <label className="form__label">
                    {`Address${requireAddress.includes(type) ? " *" : ""}`}
                  </label>
                  <div className="form__fieldbox">
                    <div className="form__input">
                      <button
                        className="form__add-input"
                        type="button"
                        onClick={() => this.setState({ activeAddress: true })}
                      >
                        <AddOutlined /> Add
                      </button>
                      <FormControl error={addressError}>
                        {addressError && (
                          <FormHelperText style={{ color: "#9a3c1c" }}>
                            Field is required
                          </FormHelperText>
                        )}
                      </FormControl>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="form form--modal">
                {mainContentError && (
                  <div className="form__fieldset">
                    <span className="form__label"></span>
                    <div className="form__fieldbox">
                      <div className="form__input">
                        <FormControl error={mainContentError}>
                          <FormHelperText style={{ color: "#9a3c1c" }}>
                            Back and fill all required fields
                          </FormHelperText>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                )}

                <div className="form__fieldset">
                  <label htmlFor="country" className="form__label">
                    {`Country${requireAddress.includes(type) ? " *" : ""}`}
                  </label>
                  <div className="form__fieldbox">
                    <div className="form__input">
                      <TextValidator
                        id="country"
                        name="country"
                        key="country"
                        value={country}
                        placeholder="Type country"
                        className="edit-form__input"
                        margin="normal"
                        fullWidth
                        validators={
                          requireAddress.includes(type)
                            ? ["required", "maxStringLength:50"]
                            : ["maxStringLength:50"]
                        }
                        errorMessages={
                          requireAddress.includes(type)
                            ? [
                                "Field is required",
                                "Max length is 50 characters",
                              ]
                            : ["Max length is 50 characters"]
                        }
                        withRequiredValidator
                        onChange={this.handleChange("country")}
                      />
                    </div>
                  </div>
                </div>

                <div className="form__fieldset">
                  <label htmlFor="state" className="form__label">
                    {`State${requireAddress.includes(type) ? " *" : ""}`}
                  </label>
                  <div className="form__fieldbox">
                    <div className="form__input">
                      <TextValidator
                        id="state"
                        name="state"
                        key="state"
                        value={state}
                        placeholder="Type state"
                        className="edit-form__input"
                        margin="normal"
                        fullWidth
                        validators={
                          requireAddress.includes(type)
                            ? ["required", "maxStringLength:50"]
                            : ["maxStringLength:50"]
                        }
                        errorMessages={
                          requireAddress.includes(type)
                            ? [
                                "Field is required",
                                "Max length is 50 characters",
                              ]
                            : ["Max length is 50 characters"]
                        }
                        withRequiredValidator
                        onChange={this.handleChange("state")}
                      />
                    </div>
                  </div>
                </div>

                <div className="form__fieldset">
                  <label htmlFor="city" className="form__label">
                    {`City${requireAddress.includes(type) ? " *" : ""}`}
                  </label>
                  <div className="form__fieldbox">
                    <div className="form__input">
                      <TextValidator
                        id="city"
                        name="city"
                        value={city}
                        placeholder="Type city"
                        className="edit-form__input"
                        margin="normal"
                        fullWidth
                        validators={
                          requireAddress.includes(type)
                            ? ["required", "maxStringLength:50"]
                            : ["maxStringLength:50"]
                        }
                        errorMessages={
                          requireAddress.includes(type)
                            ? [
                                "Field is required",
                                "Max length is 50 characters",
                              ]
                            : ["Max length is 50 characters"]
                        }
                        withRequiredValidator
                        onChange={this.handleChange("city")}
                      />
                    </div>
                  </div>
                </div>

                <div className="form__fieldset">
                  <label htmlFor="address" className="form__label">
                    {`Address${requireAddress.includes(type) ? " *" : ""}`}
                  </label>
                  <div className="form__fieldbox">
                    <div className="form__input">
                      <TextValidator
                        id="address"
                        name="address"
                        value={address}
                        placeholder="Type company address"
                        className="edit-form__input"
                        margin="normal"
                        fullWidth
                        validators={
                          requireAddress.includes(type)
                            ? ["required", "maxStringLength:150"]
                            : ["maxStringLength:150"]
                        }
                        errorMessages={
                          requireAddress.includes(type)
                            ? [
                                "Field is required",
                                "Max length is 150 characters",
                              ]
                            : ["Max length is 150 characters"]
                        }
                        withRequiredValidator
                        onChange={this.handleChange("address")}
                      />
                    </div>
                  </div>
                </div>

                <div className="form__fieldset">
                  <label htmlFor="zip" className="form__label">
                    {`Zip code${requireAddress.includes(type) ? " *" : ""}`}
                  </label>
                  <div className="form__fieldbox">
                    <div className="form__input">
                      <TextValidator
                        id="zip"
                        name="zip"
                        value={zip_code}
                        placeholder="Type zip code"
                        className="edit-form__input"
                        margin="normal"
                        fullWidth
                        validators={
                          requireAddress.includes(type)
                            ? ["required", "maxStringLength:30"]
                            : ["maxStringLength:30"]
                        }
                        errorMessages={
                          requireAddress.includes(type)
                            ? [
                                "Field is required",
                                "Max length is 30 characters",
                              ]
                            : ["Max length is 30 characters"]
                        }
                        withRequiredValidator
                        onChange={this.handleChange("zip_code")}
                      />
                    </div>
                  </div>
                </div>

                <div className="form__fieldset">
                  <label htmlFor="phone" className="form__label">
                    {`Phone number${requireAddress.includes(type) ? " *" : ""}`}
                  </label>
                  <div className="form__fieldbox">
                    <div className="form__input">
                      <TextValidator
                        id="phone"
                        name="phone"
                        value={phone}
                        placeholder="Type phone number"
                        className="edit-form__input"
                        margin="normal"
                        fullWidth
                        validators={
                          requireAddress.includes(type)
                            ? ["required", "maxStringLength:20"]
                            : ["maxStringLength:20"]
                        }
                        errorMessages={
                          requireAddress.includes(type)
                            ? [
                                "Field is required",
                                "Max length is 20 characters",
                              ]
                            : ["Max length is 20 characters"]
                        }
                        withRequiredValidator
                        onChange={this.handleChange("phone")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.handleCancelButton}
            variant="text"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="text"
            color="primary"
            onClick={this.validateCustomFields}
          >
            Save
          </Button>
        </DialogActions>
      </ValidatorForm>
    )
  }
}

const mapStateToProps = ({ auth, groups }) => ({ auth, groups })
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ createGroup }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withSnackbar(GroupCreateForm)))
