import React from "react";
import { useSelector } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import * as routes from "../../../library/constants/routes";
import { TRACKING_COURSES_CREDS } from "../../../library/constants/routes";
import VerticalMenu from "../../../components/ui/verticalMenu/verticalMenu";
import KnowmapTeacherKnowmixKnowmeets
  from "./subpages/uknow/knowmapTeacherKnowmixKnowmeets";
import KnowmapLearnerKnowmixKnowmeets
  from "./subpages/uknow/knowmapLearnerKnowmixKnowmeets";
import ManagedMeets from "./subpages/meets/ManagedMeets";
import IncomingMeets from "./subpages/meets/IncomingMeets";
import IncomingBookings from "./subpages/bookings/IncomingBookings";
import OutgoingBookings from "./subpages/bookings/OutgoingBookings";
import LearnerTrackingCoursesAll
  from "./subpages/Courses/learner/LearnerTrackingCoursesAll";
import LearnerTrackingCoursesProgress
  from "./subpages/Courses/learner/LearnerTrackingCoursesProgress";
import LearnerTrackingCoursesGrades
  from "./subpages/Courses/learner/LearnerTrackingCoursesGrades";
import TeacherTrackingCoursesManaged
  from "./subpages/Courses/teacher/TeacherTrackingCoursesManaged";
import TeacherTrackingCoursesStudentProgress
  from "./subpages/Courses/teacher/TeacherTrackingCoursesStudentProgress";
import TeacherTrackingCoursesCourseGrading
  from "./subpages/Courses/teacher/TeacherTrackingCoursesCourseGrading";
import TeacherTrackingCoursesStepsGrading
  from "./subpages/Courses/teacher/TeacherTrackingCoursesStepsGrading";
import LearnerTrackingCoursesCreds
  from "./subpages/Courses/learner/LearnerTrackingCoursesCreds";
import TeacherTrackingCoursesCreds
  from "./subpages/Courses/teacher/TeacherTrackingCoursesCreds";

const KnowMap = () => {
  const { role } = useSelector(({ auth }) => auth.userData)
  const { path } = useRouteMatch()

  const menuItems = [
    {
      path: routes.KNOWMAP_INCOMING_APPOINTMENT_BOOKINGS,
      label: "Appointment bookings",
      type: "parent",
      markAsActive: false,
    },
    {
      path: routes.KNOWMAP_INCOMING_APPOINTMENT_BOOKINGS,
      label: "Incoming",
      type: "child",
      key: "incoming-booking",
    },
    {
      path: routes.KNOWMAP_OUTGOING_APPOINTMENT_BOOKINGS,
      label: "Outgoing",
      type: "child",
    },
    role !== 4 && {
      path: routes.KNOWMAP_INCOMING_MEETS,
      label: "Meets",
      type: "parent",
      markAsActive: false,
    },
    role !== 4 && {
      path: routes.KNOWMAP_INCOMING_MEETS,
      label: "Incoming",
      type: "child",
      key: "incoming-meet",
    },
    role !== 4 && {
      path: routes.KNOWMAP_MEETS,
      label: "Managed",
      type: "child",
    },
    role !== 4 && {
      path: routes.KNOWMAP_UKNOW_KNOWMIX,
      label: "Courses managed Meets",
    },
    // role !== 4 && {
    //   path: routes.KNOWMAP_KNOWMIX,
    //   label: "Course grading",
    //   type: "parent",
    //   markAsActive: false,
    // },
    // role !== 4 && {
    //   path: routes.KNOWMAP_KNOWMIX,
    //   label: "Whole course",
    //   type: "child",
    // },
    // role !== 4 && {
    //   path: routes.KNOWMAP_KNOWMIX_STEPS,
    //   label: "Steps grading",
    //   type: "child",
    // },
    // role !== 4 && {
    //   path:
    //     role === 1 ? routes.KNOWMAP_KNOWCRED_VALID : routes.KNOWMAP_KNOWCRED,
    //   label: "Creds",
    // },

    role === 1 && {
      path: routes.TRACKING_COURSES,
      label: "My Courses",
      type: "parent",
    },

    role === 1 && {
      path: routes.TRACKING_COURSES_MY_PROGRESS,
      label: "My Progress",
      type: "child",
    },
    role === 1 && {
      path: routes.TRACKING_COURSES_MY_GRADES,
      label: "My Grades",
      type: "child",
    },
    role === 1 && {
      path: TRACKING_COURSES_CREDS,
      label: "Creds",
      type: "child",
    },
    role === 2 && {
      path: routes.TRACKING_COURSES_MANAGED,
      label: "Managed Courses",
      type: "parent",
    },
    role === 2 && {
      path: routes.TRACKING_COURSES_STUDENT_PROGRESS,
      label: "Student Progress",
      type: "child",
    },
    role === 2 && {
      path: routes.TRACKING_COURSES_COURSE_GRADING,
      label: "Course grading",
      type: "child",
    },
    role === 2 && {
      path: routes.TRACKING_COURSES_STEPS_GRADING,
      label: "Steps grading",
      type: "child",
    },
    role === 2 && {
      path: TRACKING_COURSES_CREDS,
      label: "Creds",
      type: "child",
    },
  ].filter(Boolean)

  return (
    <div className="common-page__content">
      <main className="common-page__main">
        <Switch>
          {/*<Route*/}
          {/*  exact*/}
          {/*  path={routes.KNOWMAP_UKNOW}*/}
          {/*  component={*/}
          {/*    role === 1 ? KnowmapLearnerKnowmeets : KnowmapTeacherUknow*/}
          {/*  }*/}
          {/*/>*/}
          {/*<Route*/}
          {/*  exact*/}
          {/*  path={routes.KNOWMAP_UKNOW_STEPS}*/}
          {/*  component={*/}
          {/*    role === 1 ? KnowmapLearnerKnowmeets : KnowmapTeacherUknow*/}
          {/*  }*/}
          {/*/>*/}
          <Route
            exact
            path={[
              routes.KNOWMAP_INCOMING_APPOINTMENT_BOOKINGS,
              routes.INCOMING_SOLICIT_APPOINTMENT_AUTHORIZED,
            ]}
            component={IncomingBookings}
          />
          <Route
            exact
            path={routes.KNOWMAP_OUTGOING_APPOINTMENT_BOOKINGS}
            component={OutgoingBookings}
          />
          <Route exact path={[routes.KNOWMAP_MEETS]} component={ManagedMeets} />
          <Route
            exact
            path={[routes.KNOWMAP_INCOMING_MEETS, routes.KNOWMAP_INCOMING_MEET]}
            component={IncomingMeets}
          />
          <Route
            exact
            path={[
              routes.KNOWMAP_UKNOW_KNOWMIX,
              routes.KNOWMAP_INCOMING_KNOWMEET,
            ]}
            component={
              role === 1
                ? KnowmapLearnerKnowmixKnowmeets
                : KnowmapTeacherKnowmixKnowmeets
            }
          />
          {/*<Route*/}
          {/*  exact*/}
          {/*  path={routes.KNOWMAP_INCOMING_KNOWMEET}*/}
          {/*  component={KnowmapLearnerKnowmixKnowmeets}*/}
          {/*/>*/}
          {/*<Route*/}
          {/*  exact*/}
          {/*  path={routes.KNOWMAP_KNOWMIX}*/} 
          {/*  component={*/}
          {/*    role === 1 ? KnowmapLearnerKnowmix : KnowmapTeacherKnowmix*/}
          {/*  }*/}
          {/*/>*/}
          {/*<Route*/}
          {/*  exact*/}
          {/*  path={routes.KNOWMAP_KNOWMIX_STEPS}*/}
          {/*  component={*/}
          {/*    role === 1*/}
          {/*      ? KnowmapLearnerKnowmixSteps*/}
          {/*      : KnowmapTeacherKnowmixSteps*/}
          {/*  }*/}
          {/*/>*/}
          {/*<Route*/}
          {/*  exact*/}
          {/*  path={routes.KNOWMAP_KNOWCRED}*/}
          {/*  component={KnowmapTeacherKnowcred}*/}
          {/*/>*/}
          {/*<Route*/}
          {/*  exact*/}
          {/*  path={[*/}
          {/*    routes.KNOWMAP_KNOWCRED_AWARDED,*/}
          {/*    routes.KNOWMAP_KNOWCRED_VALID,*/}
          {/*  ]}*/}
          {/*  component={KnowmapLearnerKnowcred}*/}
          {/*/>*/}
          <Route
            exact
            path={routes.TRACKING_COURSES}
            component={LearnerTrackingCoursesAll}
          />
          <Route
            exact
            path={routes.TRACKING_COURSES_MY_PROGRESS}
            component={LearnerTrackingCoursesProgress}
          />
          <Route
            exact
            path={routes.TRACKING_COURSES_MY_GRADES}
            component={LearnerTrackingCoursesGrades}
          />
          <Route
            exact
            path={routes.TRACKING_COURSES_MANAGED}
            component={TeacherTrackingCoursesManaged}
          />
          <Route
            exact
            path={routes.TRACKING_COURSES_STUDENT_PROGRESS}
            component={TeacherTrackingCoursesStudentProgress}
          />
          <Route
            exact
            path={routes.TRACKING_COURSES_COURSE_GRADING}
            component={TeacherTrackingCoursesCourseGrading}
          />
          <Route
            exact
            path={routes.TRACKING_COURSES_STEPS_GRADING}
            component={TeacherTrackingCoursesStepsGrading}
          />
          <Route
            exact
            path={routes.TRACKING_COURSES_CREDS}
            component={
              role === 1
                ? LearnerTrackingCoursesCreds
                : TeacherTrackingCoursesCreds
            }
          />
        </Switch>
      </main>
      <aside className="common-page__sidebar">
        <VerticalMenu menuItems={menuItems} />
      </aside>
    </div>
  )
}

export default KnowMap
