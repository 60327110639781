import useEntityPicker from "../../../../hooks/useEntityPicker"
import PickerSelect from "../select/pickerSelect"
import { GROUPS } from "../../../../library/store/actions/types/groupsTypes"
import { useEffect } from "react"

const SpacePicker = ({
  value = "",
  onChange,
  groupId,
  hasError = false,
  errorMessage = "Field is required",
  required = false,
  multiple,
}) => {
  const { fetchList, list, dataReceived, isWaitingForOtherFields } =
    useEntityPicker({
      fetchConfig: {
        apiUrl: GROUPS.SPACES.LIST,
        params: {
          school_id: groupId,
          offset: 0,
          limit: 100,
        },
      },
      key: "spaces",
      loadOnMount: !!groupId,
    })

  useEffect(() => {
    if (groupId) {
      fetchList()
    }
  }, [groupId])

  const entityName = multiple ? "spaces" : "space"

  return (
    <PickerSelect
      name={"space"}
      entityName={entityName}
      value={value}
      onChange={onChange}
      required={required}
      hasError={hasError}
      errorMessage={errorMessage}
      disabled={!dataReceived}
      list={list}
      isWaitingForOtherFields={isWaitingForOtherFields}
      isWaitingForOtherFieldsMessage={"First select school"}
    />
  )
}

export default SpacePicker
