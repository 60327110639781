import React, { Component } from "react"
import "./productSettings.scss"
import { generatePath, Link, Redirect, Route, Switch } from "react-router-dom"
import * as routes from "../../../../library/constants/routes"
import ProductSettingsMain from "./productSettingsMain/productSettingsMain"
import ProductSettingsAttachments from "./productSettingsAttachments/productSettingsAttachments"
import ProductSettingsUsageDetails from "./productSettingsUsageDetails/productSettingsUsageDetails"
import ProductSettingsAdditionalInfo from "./productSettingsAdditionalInfo/productSettingsAdditionalInfo"
import ProductSettingsMarketSpecifics from "./productSettingsMarketSpecifics/productSettingsMarketSpecifics"
import ProductSettingsReviewerInfo from "./productSettingsReviewerInfo/productSettingsReviewerInfo"
import ProductSettingsSales from "./productSettingsSales/productSettingsSales"
import VerticalMenu from "../../../../components/ui/verticalMenu"
import { getProduct } from "../../../../library/store/actions/creators/productsCreators"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import { bindActionCreators } from "redux"
import Loader from "../../../../components/ui/loader"
import logoDefault from "../../../../assets/img/product.svg"
import NotFound from "../../../../components/notFound/notFound"
import getErrorText from "../../../../library/constants/errorTypes"
import ProductSettingsDemoRequests from "./productSettingsDemoRequests"

class ProductSettings extends Component {
  state = {
    logo: null,
    dataReceived: false,
    error404: false,
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve)
    })
  }

  async componentDidMount() {
    const {
      match: { params },
    } = this.props

    try {
      await this.props.getProduct({ id: parseInt(params.productId) })
      await this.setStateAsync({
        logo: this.props.products.currentProduct.image,
        dataReceived: true,
      })
    } catch ({ error }) {
      if (error.code === 30000) {
        this.setState({ error404: true })
      } else {
        this.props.enqueueSnackbar(getErrorText(error.code), {
          variant: "error",
        })
      }
    }
  }

  render() {
    const {
      match: { params },
    } = this.props
    const { logo, dataReceived, error404 } = this.state

    const hideMainSettingsMenuChildren = ![
      routes.PRODUCT_SETTINGS,
      routes.PRODUCT_SETTINGS_ATTACHMENTS,
      routes.PRODUCT_SETTINGS_USAGE_DETAILS,
      routes.PRODUCT_SETTINGS_ADDITIONAL_INFO,
      routes.PRODUCT_SETTINGS_MARKET_SPECIFICS,
      routes.PRODUCT_SETTINGS_REVIEWER_INFO,
    ].includes(this.props.match.path)

    const menuItems = [
      {
        path: generatePath(routes.PRODUCT_SETTINGS, {
          productId: this.props.match.params.productId,
        }),
        type: "parent",
        label: "Settings",
        markAsActive: false,
      },
      {
        path: generatePath(routes.PRODUCT_SETTINGS, {
          productId: this.props.match.params.productId,
        }),
        type: "child",
        label: "Main information",
        hidden: hideMainSettingsMenuChildren,
      },
      {
        path: generatePath(routes.PRODUCT_SETTINGS_ATTACHMENTS, {
          productId: this.props.match.params.productId,
        }),
        type: "child",
        label: "Product attachments",
        hidden: hideMainSettingsMenuChildren,
      },
      {
        path: generatePath(routes.PRODUCT_SETTINGS_USAGE_DETAILS, {
          productId: this.props.match.params.productId,
        }),
        type: "child",
        label: "Usage details",
        hidden: hideMainSettingsMenuChildren,
      },
      {
        path: generatePath(routes.PRODUCT_SETTINGS_ADDITIONAL_INFO, {
          productId: this.props.match.params.productId,
        }),
        type: "child",
        label: "Additional information",
        hidden: hideMainSettingsMenuChildren,
      },
      {
        path: generatePath(routes.PRODUCT_SETTINGS_MARKET_SPECIFICS, {
          productId: this.props.match.params.productId,
        }),
        type: "child",
        label: "Market specifics",
        hidden: hideMainSettingsMenuChildren,
      },
      {
        path: generatePath(routes.PRODUCT_SETTINGS_REVIEWER_INFO, {
          productId: this.props.match.params.productId,
        }),
        type: "child",
        label: "Reviewer information",
        hidden: hideMainSettingsMenuChildren,
      },
      {
        path: generatePath(routes.PRODUCT_SETTINGS_SALES, {
          productId: this.props.match.params.productId,
        }),
        label: "Sales",
      },
      {
        path: generatePath(routes.PRODUCT_SETTINGS_DEMO_REQUESTS, {
          productId: this.props.match.params.productId,
        }),
        label: "Demo requests",
      },
    ]

    if (error404) {
      return <NotFound entity="Product" />
    }

    return (
      <div className="common-page__content">
        <main className="common-page__main">
          {dataReceived ? (
            this.props.products.currentProduct.can_edit ? (
              <Switch>
                <Route
                  exact
                  path={routes.PRODUCT_SETTINGS_MAIN_INFO}
                  component={ProductSettingsMain}
                />
                <Route
                  exact
                  path={routes.PRODUCT_SETTINGS_ATTACHMENTS}
                  component={ProductSettingsAttachments}
                />
                <Route
                  exact
                  path={routes.PRODUCT_SETTINGS_USAGE_DETAILS}
                  component={ProductSettingsUsageDetails}
                />
                <Route
                  exact
                  path={routes.PRODUCT_SETTINGS_ADDITIONAL_INFO}
                  component={ProductSettingsAdditionalInfo}
                />
                <Route
                  exact
                  path={routes.PRODUCT_SETTINGS_MARKET_SPECIFICS}
                  component={ProductSettingsMarketSpecifics}
                />
                <Route
                  exact
                  path={routes.PRODUCT_SETTINGS_REVIEWER_INFO}
                  component={ProductSettingsReviewerInfo}
                />
                <Route
                  exact
                  path={routes.PRODUCT_SETTINGS_SALES}
                  component={ProductSettingsSales}
                />
                <Route
                  exact
                  path={routes.PRODUCT_SETTINGS_DEMO_REQUESTS}
                  component={ProductSettingsDemoRequests}
                />
              </Switch>
            ) : (
              <Redirect
                to={generatePath(routes.PRODUCT, {
                  productId: this.props.match.params.productId,
                })}
              />
            )
          ) : (
            <Loader />
          )}
        </main>
        <aside className="common-page__sidebar">
          {dataReceived && (
            <div className="box aside-header">
              <Link
                to={generatePath(routes.PRODUCT, {
                  productId: params.productId,
                })}
                className="box__content aside-header__content"
              >
                <img
                  src={logo || logoDefault}
                  className="aside-header__img"
                  alt=""
                />
                <div className="aside-header__info">
                  <div className="aside-header__name">
                    {this.props.products.currentProduct.name}
                  </div>
                  <div className="aside-header__help">back to product</div>
                </div>
              </Link>
            </div>
          )}
          <VerticalMenu menuItems={menuItems} />
        </aside>
      </div>
    )
  }
}

const mapStateToProps = ({ auth, products }) => ({ auth, products })
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getProduct }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(ProductSettings))
