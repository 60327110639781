import React from "react"
import "./loggedInLayout.scss"
import { Route, Switch } from "react-router-dom"
import {
  ACCOUNTS_INFO,
  ACCOUNTS_INFO_AGENT,
  ACCOUNTS_INFO_EDUCATOR,
  ACCOUNTS_INFO_LEARNER,
  ACCOUNTS_INFO_ORGANIZATION,
  TEACHER_INTRO,
  APP_PROMOTE,
  BOOK_MEET_AUTHORIZED,
  bookmarksRoutes,
  calendarRoutes,
  chatsRoutes,
  CONTACT_FORM,
  friendsRoutes,
  groupsRoutes,
  HOME,
  HOW_TO,
  HOW_TO_ARTICLE,
  HOW_TO_CATEGORY,
  invenstoryRoutes,
  knowcredRoutes,
  knowmapRoutes,
  knowmixRoutes,
  marketplaceRoutes,
  newsRoutes,
  notificationsRoutes,
  PRICING,
  searchRoutes,
  standardsRoutes,
  TERMS_AND_CONDITIONS,
  userRoutes,
  INCOMING_SOLICIT_APPOINTMENT_AUTHORIZED,
} from "../../../library/constants/routes"
import { bindActionCreators } from "redux"
import {
  getData,
  getUserSubscriptions,
  signOut,
} from "../../../library/store/actions/creators/authCreators"
import { connect, useDispatch, useSelector } from "react-redux"
import HeaderLoggedIn from "../../../components/headerLoggedIn/headerLoggedIn"
import InfoPage from "../../info/infoPage/infoPage"
import LayoutWithSidebar from "./layoutWithSidebar/layoutWithSidebar"
import getErrorText from "../../../library/constants/errorTypes"
import {
  getChatsCount,
  getNotificationCount,
  incrementNotificationCount,
} from "../../../library/store/actions/creators/notificationCreators"
import { wsUrl } from "../../../library/networking/API"
import { withSnackbar } from "notistack"
import AccountsInfoMain from "../../info/accounts/accountsInfoMain"
import AccountsInfoLearner from "../../info/accounts/accountsInfoLearner"
import AccountsInfoEducator from "../../info/accounts/accountsInfoEducator"
import AccountsInfoAgent from "../../info/accounts/accountsInfoAgent"
import AccountsInfoOrganization from "../../info/accounts/accountsInfoOrganization"
import UserCalendarProvider from "../calendar/UserCalendarProvider"
import TeacherIntro from "../../info/TeacherIntro"
import IncomingSolicitAppointmentFormModal from "../solicitAppointments/IncomingSolicitAppointmentFormModal"
import useCreator from "../../../hooks/useCreator"
import { useAlert } from "../../../hooks/useAlert"
import { useQueryClient } from "@tanstack/react-query"
import { useCurrentUser } from "../../../hooks/data/user/useUser"
import Loader from "../../../components/ui/loader"
import * as routes from "../../../library/constants/routes"
import HomePage from "../homePage/homePage"
import UserProfile from "../user/userProfile"

const LoggedInLayout = () => {
  const dispatch = useDispatch()
  const { errorAlert } = useAlert()
  const { userData } = useSelector(({ auth }) => auth)

  const socket = React.useRef(null)

  const queryClient = useQueryClient()

  const { user } = useCurrentUser({ enabled: false })

  React.useEffect(() => {
    const connectSocket = () => {
      socket.current = new WebSocket(`${wsUrl}notifications.subscribe`)

      socket.current.onopen = (e) => {
        heartbeat()
      }
      socket.current.onmessage = (event) => {
        const data = JSON.parse(event.data)
        dispatch(
          incrementNotificationCount(data.type, data.increment, data.chat)
        )
      }
      socket.current.onclose = (e) => {
        setTimeout(() => {
          connectSocket()
        }, 1000)
      }
    }

    const heartbeat = () => {
      if (!socket.current || socket.current.readyState !== 1) return

      socket.current.send(
        JSON.stringify({
          token: userData.token,
        })
      )
      setTimeout(heartbeat, 3000)
    }

    ;(async () => {
      try {
        const currentUser = await dispatch(getData())
        queryClient.setQueryData(["currentUser"], currentUser)

        const currentUserSubscriptions = await dispatch(getUserSubscriptions())
        queryClient.setQueryData(
          ["USER_SUBSCRIPTIONS"],
          currentUserSubscriptions
        )

        await Promise.all([
          dispatch(getNotificationCount()),
          dispatch(getChatsCount()),
        ])
      } catch (e) {
        if (e.error.code === 20005) return
        errorAlert(e)
      }

      try {
        connectSocket()
      } catch (e) {
        console.error(e)
      }
    })()

    return () => {
      if (socket.current) {
        socket.current.close()
      }
    }
  }, [])

  return !!user ? (
    <UserCalendarProvider>
      <HeaderLoggedIn />
      <Switch>
        <Route
          exact
          path={[
            HOME,
            ...userRoutes,
            ...newsRoutes,
            ...searchRoutes,
            ...friendsRoutes,
            ...knowcredRoutes,
            ...groupsRoutes,
            ...chatsRoutes,
            ...marketplaceRoutes,
            ...bookmarksRoutes,
            ...standardsRoutes,
            ...notificationsRoutes,
            ...knowmixRoutes,
            ...knowmapRoutes,
          ]}
        >
          <LayoutWithSidebar />
        </Route>

        <Route
          exact
          path={INCOMING_SOLICIT_APPOINTMENT_AUTHORIZED}
          component={IncomingSolicitAppointmentFormModal}
        />

        <Route
          exact
          path={[
            BOOK_MEET_AUTHORIZED,
            // INCOMING_SOLICIT_APPOINTMENT_AUTHORIZED,
          ]}
          component={LayoutWithSidebar}
        />

        <Route
          exact
          path={invenstoryRoutes}
          render={(props) => (
            <InfoPage {...props} isLoggedIn pageType="invenstory" />
          )}
        />

        <Route
          exact
          path={calendarRoutes}
          render={(props) => <InfoPage {...props} pageType="calendar" />}
        />

        <Route
          exact
          path={TERMS_AND_CONDITIONS}
          render={(props) => (
            <InfoPage {...props} isLoggedIn pageType="terms" />
          )}
        />
        <Route
          exact
          path={CONTACT_FORM}
          render={(props) => <InfoPage {...props} pageType={"feed-back"} />}
        />
        <Route
          exact
          path={APP_PROMOTE}
          render={(props) => (
            <InfoPage {...props} isLoggedIn pageType={"app-promote"} />
          )}
        />
        <Route
          exact
          path={PRICING}
          render={(props) => <InfoPage {...props} pageType={"pricing"} />}
        />
        <Route exact path={ACCOUNTS_INFO} component={AccountsInfoMain} />
        <Route
          exact
          path={ACCOUNTS_INFO_LEARNER}
          component={AccountsInfoLearner}
        />
        <Route
          exact
          path={ACCOUNTS_INFO_EDUCATOR}
          component={AccountsInfoEducator}
        />
        <Route exact path={ACCOUNTS_INFO_AGENT} component={AccountsInfoAgent} />
        <Route
          exact
          path={ACCOUNTS_INFO_ORGANIZATION}
          component={AccountsInfoOrganization}
        />
        <Route exact path={TEACHER_INTRO} component={TeacherIntro} />
        <Route
          exact
          path={[HOW_TO, HOW_TO_CATEGORY, HOW_TO_ARTICLE]}
          render={(props) => <InfoPage {...props} pageType={"how-to"} />}
        />
      </Switch>
    </UserCalendarProvider>
  ) : (
    <Loader />
  )
}

export default LoggedInLayout
