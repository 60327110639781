import React from "react"
import InfoLayout from "./infoLayout"
import { SectionHeading } from "./uknow/uknowInfoPage"

const TeacherIntro = () => {
  return (
    <InfoLayout>
      <section className={"bg-white w-full py-8"}>
        <div className="container">
          <SectionHeading>Hey, School Teachers!</SectionHeading>
          image
          <div className="grid grid-cols-12 gap-4">
            <div className="col-start-2 col-span-10">
              <SectionHeading>
                <span className={"italic"}>Magically Create more Time…</span>
                <br />
                More Time for You, More Learning for Them
              </SectionHeading>
              <p className={"text-center"}>
                It’s time to own your teaching time with easy cohorting that
                gives you more time for individual instruction, so every student
                achieves.
                <br /> You just give lessons a time “frame” with a new kind of
                calendar meeting. Now your whole group will naturally split into
                smaller cohorts that are easier to teach.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className={"py-8 bg-grey7"}>
        <div className="container">
          <SectionHeading>
            Splitting Formal vs. Informal Teaching
          </SectionHeading>
          <p className={"text-center mx-auto w-5/6 mb-4"}>
            To make more time for you and more learning for all your students,
            divide your job into three parts for each lesson:
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 md:grid-rows-subgrid md:row-span-2 gap-4">
            <div>
              <img
                src={"#"}
                alt={""}
                className={"h-16 w-full mb-4 object-center object-cover"}
              />
              <div className={"mb-4"}>
                <h3 className={"text-2xl font-medium"}>Informal Instruction</h3>
                <p className={"italic"}>Set a Meeting</p>
              </div>
              <div>
                <p className={"mb-4"}>
                  <b>Formal</b> instruction is time spent delivering verbally to
                  a group. It is usually lecture, presentation, hands-on project
                  time such as a lab, or discussion-style. It's anytime you are
                  verbalizing knowledge and giving guidance.
                </p>
                <p>
                  <i>Difference from 5-Day Week of 45-minute Classes:</i>
                  <br />
                  Now that the whole group is divided into cohorts, to keep your
                  calendaring optimum, * the lessons each week of formal
                  instruction time should be up to two :20 or :30-minute
                  meetings a week to accommodate multiple cohorts, one
                  :45-minute meeting, and the other two days check-ins only for
                  students. Every week of a course should be planned so that
                  cohorts may pace independently and rearrange members as some
                  pace faster or slower at any given time.
                </p>
              </div>
            </div>

            <div>
              <img
                src={"#"}
                alt={""}
                className={"h-16 w-full mb-4 object-center object-cover"}
              />
              <div className={"mb-4"}>
                <h3 className={"text-2xl font-medium"}>Study Time</h3>
                <p className={"italic"}>Issue Resources & Assignments</p>
              </div>
              <div>
                <p className={"mb-4"}>
                  <b>Study time</b> is when students are doing the work assigned
                  with any resources independently. They could be working singly
                  or in pairs quietly, from anywhere. This time would optimally
                  be spent in a space separately from the teacher with another
                  adult having oversight and calling in the teacher as needed -
                  or the teacher roams doing informal instruction but can
                  proceed at any time to manage another Meeting.
                </p>
                <div>
                  <p>
                    When teachers are not doing formal or informal instruction,
                    they can:
                  </p>
                  <ol className="list-decimal ml-0 pl-8">
                    <li>Plan and issue additional personalizations</li>
                    <li>
                      Adjust individual student time spent on which subjects
                    </li>
                  </ol>
                </div>
              </div>
            </div>

            <div>
              <img
                src={"#"}
                alt={""}
                className={"h-16 w-full mb-4 object-center object-cover"}
              />
              <div className={"mb-4"}>
                <h3 className={"text-2xl font-medium"}>Informal Instruction</h3>
                <p className={"italic"}>Roaming Time & Check-ins</p>
              </div>
              <div>
                <p className={"mb-4"}>
                  <span className={"font-medium"}>
                    Roaming to do individual instruction, subject understanding
                    check-ins and motivation.
                  </span>{" "}
                  No calendaring is necessary for check-ins and can be done by
                  watching tracking of students across a course - paying
                  attention to individual students as needed for instruction or
                  motivation by roaming to them. This time toggles between the
                  spaces for formal instruction meetings, and purely study time
                  spent in homerooms or remotely with other adult oversight or a
                  rotation of teachers.
                </p>
                <p>
                  Informal instruction for remote students can use video
                  conferencing and messaging. Homerooms where students are
                  studying should have "quiet zones" for study and corners for
                  teachers to meet with students informally.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={"py-8 bg-white"}>
        <div className="container">
          <div className="grid grid-cols-12 gap-4">
            <div className="col-start-2 col-span-10">
              <SectionHeading>
                More Time for You, More Learning for Them
              </SectionHeading>
              <p className={"mb-4"}>
                Get Started at Knowstory free or with a Teacher Premium account
                for any schoolteacher or gig independent teacher. Next, make a
                One-Teacher-School Group. The Group account will grant all your
                students similar advanced calendaring features while their
                personal profile is affiliated with your group.
              </p>
              <ol className={"list-decimal ml-0 pl-8"}>
                <li>
                  Create Account, Profile, and Manage Settings to Integrate
                  Calendars{" "}
                </li>
                <li>Create One-Teacher-School Group (3 months Free)</li>
                <li>Create Courses and AI Cohorting Class Meets</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className={"py-8 bg-grey7"}>
        <div className="container">
          <div className="grid grid-cols-6 gap-8">
            <div className="col-span-1">arrow</div>
            <div className="col-span-5 col-start-2">
              <h3 className={"mb-4"}>
                Find out about other features in the How To section in the
                Knowstory site including:
              </h3>
              <ul className={"list-disc ml-0 pl-8"}>
                <li className={"mb-2 marker:text-primary"}>
                  Sending dated appointments to others.
                </li>
                <li className={"mb-2 marker:text-primary"}>
                  Giving your link out for others to book with you.
                </li>
                <li className={"mb-2 marker:text-primary"}>
                  Creating "request appointment" invitations which only ask for
                  an amount of time and allow the system to book you and your
                  invitees.
                </li>
                <li className={"mb-2 marker:text-primary"}>Using Bookmarks.</li>
                <li className={"mb-2 marker:text-primary"}>
                  Create Cred or Badges.
                </li>
                <li className={"mb-2 marker:text-primary"}>
                  Messaging, notifications.
                </li>
                <li className={"mb-2 marker:text-primary"}>Posting.</li>
                <li className={"mb-2 marker:text-primary"}>Invenstory.</li>
                <li className={"mb-2 marker:text-primary"}>
                  Academic Standards Library.
                </li>
                <li className={"mb-2 marker:text-primary"}>
                  Featured Products Catalog.
                </li>
                <li className={"mb-2 marker:text-primary"}>
                  Creating schedules and assigning them to members of your
                  group.
                </li>
                <li className={"mb-2 marker:text-primary"}>
                  Posting your Courses to the marketplace.
                </li>
                <li className={"marker:text-primary"}>
                  Other forthcoming features like Knowplan and more.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="py-8 bg-white">
        <div className="container">
          <div className="grid grid-cols-2 gap-16 mb-8">
            <div>
              <h3 className={"text-xl font-medium mb-8"}>
                1. Create Educator Account & Profile
              </h3>
              <p>
                When registering on the site, you need to select the role of
                educator.
              </p>
            </div>
            <div>image</div>
          </div>
          <div className="grid grid-cols-2 gap-16 items-center mb-8">
            <div>image</div>
            <p>
              After you have registered on the site, you need to fill out a
              profile. You should go to “Edit your profile.”{" "}
            </p>
          </div>
          <div className="grid grid-cols-2 gap-16 items-center mb-8">
            <p>
              Here you can change or fill in the main information, your
              contacts, education, work and personal information. You can change
              your photo and background.{" "}
            </p>
            <div>image</div>
          </div>
          <div className="grid grid-cols-2 gap-16 items-center mb-8">
            <div>image</div>
            <div>
              <p className={"font-medium mb-4"}>In settings you can:</p>
              <ul>
                <li className={"mb-4"}>
                  Change password, email, and your role. In some instances, the
                  Role is locked and you will need to contact us to change it.
                </li>
                <li className={"mb-4"}>
                  In Holds, you can add your personal holds which are a way for
                  you to bracket open time but make it just your class periods
                  or time you will work as a gig teacher. A Hold is special
                  because inside of it you can also have minus times out of it
                  such as a lunch time or a break time.
                </li>
                <li className={"mb-4"}>You can block unwanted users.</li>
                <li className={"mb-4"}>
                  Under verification is where you go to request being verified
                  if you want to add students to a Course.
                </li>
                <li className={"mb-4"}>
                  Under integrate calendar you can integrate Google and Outlook
                  calendar.
                </li>
                <li>You can add appointment links and types</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="py-8 bg-grey-7">
        <div className="container">
          <h3 className={"text-xl font-medium mb-8"}>
            2. Create One-Teacher-School Group
          </h3>
          <p className="mb-2">
            To create a group, you need to go to the groups tab, then the Create
            Group button, and select “One-Teacher-School.”
          </p>
          <p className="mb-2">
            On the main page of the group, you can change or add a group picture
            and background.
          </p>
          <p className="mb-2">
            To go to the group settings, you need to go to Manage on the
            right-hand side. This is your door to behind-the-scenes of managing
            your group.
          </p>
          <p className="mb-2">
            In the settings you can add main information and add related groups.
          </p>
          <p className="mb-2">
            In attachments you can add links, documents, and attachment
            products.
          </p>
          <p className="mb-2">
            In members you can see list of group members, invite new members and
            change occupation to:
          </p>
          <ul className={"list-disc  ml-0 pl-8 mb-2"}>
            <li className={"mb-2 marker:text-primary"}>
              follower, which is someone you are allowing to see into the
              group’s posts such as a parent, but who will not gain full
              calendar functions with affiliation,
            </li>
            <li className={"mb-2 marker:text-primary"}>
              teacher, which is someone who can be marked a part of Courses and
              will automatically gain full calendar functions with affiliation,
            </li>
            <li className={"mb-2 marker:text-primary"}>
              learner, which is someone who can be assigned Courses and will
              automatically gain full calendar functions with affiliation,
            </li>
            <li className={"mb-2 marker:text-primary"}>
              staff, which is someone who will automatically gain full calendar
              functions with affiliation,
            </li>
            <li className={"mb-2 marker:text-primary"}>
              sales manager, which is someone who will automatically gain full
              calendar functions with affiliation.
            </li>
          </ul>
          <p>
            You can also give certain Members different Roles like
            Administrator, which will give them all the same access as you have.
            We suggest you always have at least one other person have this sort
            of full access to cover for you if needed.
          </p>
        </div>
      </section>
      <section className="py-8 bg-white">
        <div className="container">
          <div className="grid grid-cols-2 gap-16 mb-8">
            <div>
              <h3 className={"text-xl font-medium mb-8"}>
                3. Create Courses and AI Auto-Cohorting Class Meets
              </h3>
              <p>
                Under “Manage” for your group will be Courses. Use the “Create
                Course” button to do the time framing for a course.
              </p>
            </div>
            <div>image</div>
          </div>
          <div className="grid grid-cols-2 gap-16 items-center mb-8">
            <div>image</div>
            <p>
              After creating a course, you will see the following page. This is
              where to edit the image that will go on the course tile in the
              library for your group. To edit and supplement information about
              the course, you need to go to the Manage tab for this course.
            </p>
          </div>
          <div className="grid grid-cols-2 gap-16 items-center mb-8">
            <div>
              In the course main information, you can add or edit the course
              name, info, goal, measurement, teacher, schedule, subjects, links,
              and attachments.{" "}
            </div>
            <div>image</div>
          </div>
          <div className="grid grid-cols-2 gap-16 items-center mb-8">
            <div>image</div>
            <div>
              To add steps to the course, you need to go to the steps tab and
              add as many steps as needed. At each step you can add academic
              standards that match the step.{" "}
            </div>
          </div>
          <div className="grid grid-cols-2 gap-16 items-center mb-8">
            <div>
              You can also add any number of questions at the end of the step.
              Completion of the last question will enroll the student into any
              follow-on class Meet. If no questions are asked, the student will
              see a “Finish Step” button instead. On any questions, be sure to
              put in the right answer for easy grading later. The student does
              not see the answer.{" "}
            </div>
            <div>image</div>
          </div>
          <div className="grid grid-cols-2 gap-16 items-center mb-8">
            <div>image</div>
            <div>
              To start your course, you can have an initial Course Meet for new
              students to accept that is at the front of, or over the whole
              course. Step Meets are assigned after specific steps. You can add
              a new Course meet over the whole course at any time.{" "}
            </div>
          </div>
          <div className="grid grid-cols-2 gap-16 items-center mb-8">
            <div>
              A course step must be made for it to appear in “For Steps” under
              Meets so that you can make a Meet to go with that step.{" "}
            </div>
            <div>image</div>
          </div>
          <div className="grid grid-cols-2 gap-16 items-center mb-8">
            <div>image</div>
            <div>
              <p className={"mb-2"}>
                Hit the “Create Meet” button to see the form for creation and
                select creating the Meet for either the whole Course or a Step.
              </p>
              <p>
                Note that the Target Date is not the scheduled firm date. It is
                merely a target. The interval is the length of time between the
                first target and a new target you want your students to have if
                they missed the first one.
              </p>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-16 items-center mb-8">
            <div>
              <p className={"mb-2"}>
                If you do not have any Holds, the Meet will use the default time
                of 8:00am – 5:00pm weekdays Monday – Friday.
              </p>
              <p className={"mb-2"}>
                Selecting Space is optional as you may instead provide a virtual
                conferencing link, or both.{" "}
              </p>
              <p className={"mb-2"}>
                The Reminder is not seen by students, only by you when the
                meeting arrives on your calendar for each cohort.
              </p>
              <p>
                To finish building a Course, next you add your members from an
                automatic roster that will be based on the Course’s grade or age
                group you selected.
              </p>
            </div>
            <div>image</div>
          </div>
          <div className="grid grid-cols-2 gap-16 items-center">
            <div>image</div>
            <div>
              Finally, you go to Release to give the students the course in
              their own accounts. It will also appear in your list of Managed
              courses under Tracking on the left-side navigation.{" "}
            </div>
          </div>
        </div>
      </section>
      <section className="py-8 bg-grey7">
        <div className="container">
          <div className="grid grid-cols-2 gap-16 mb-8">
            <div>
              <h3 className={"text-2xl font-medium mb-8"}>
                4. Manage and Track Courses
              </h3>
              <p className={"mb-2"}>
                On the site’s left-side navigation will be a “Tracking” tab. It
                is here you manage appointments coming in or outgoing,
                non-course related Meets coming in or ones you created called
                “managed,” the meets that are part of Course Frames or Course
                Steps, course grading and creds that have been given with your
                course grading as part of this Group. Note that under your
                Profile is another way to locate Creds which has a Shared tab
                for creds you have given to others as well.
              </p>
              <p>
                This first image shows non-course Meets under “Managed,” which
                you would see if you have issued any non-course Meets to do some
                auto-cohorting with multiple meetings setting based on each
                individual’s timing in accepting the meeting. The “T” will show
                the meeting’s Target, “S” will show any Set Date. If there is a
                Set Date, you can assume a new Target has been issued for
                participants who are not in the upcoming cohort. To open one of
                these Managed Meets, click on the name of it.
              </p>
            </div>
            <div>image</div>
          </div>
          <div className="grid grid-cols-2 gap-16 items-center mb-8">
            <div>image</div>
            <div>
              <p>
                Course managed meets are in a separate place and show different
                information.{" "}
              </p>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-16 items-center mb-8">
            <div>
              <p>
                You can one meet by clicking on the name of it to see a pop-up.
                Hovering over the green linked Step name will allow you to edit
                the Meet by clicking on the edit icon.{" "}
              </p>
            </div>
            <div>image</div>
          </div>
          <div className="grid grid-cols-2 gap-16 items-center mb-8">
            <div>image</div>
            <div>
              <p>
                Course Grading can be done for a Whole Course once it is
                completed by clicking to the right of the Grade blank to see
                “Examine.”{" "}
              </p>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-16 items-center">
            <div>image</div>
            <div>
              <p className={"mb-2"}>
                Steps are similarly examined with optional Grades and Comments:{" "}
              </p>
              <p>
                Teachers will automatically see the option to grant Cred to a
                student at the end of a Course. Go to the How To pages to
                discover how to use Cred.{" "}
              </p>
            </div>
          </div>
        </div>
      </section>
    </InfoLayout>
  )
}

export default TeacherIntro
