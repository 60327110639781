import React, { useEffect } from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import * as routes from "../../../../library/constants/routes"
import ProfileEditMain from "./profileEditMain/profileEditMain"
import ProfileEditContacts from "./profileEditContacts/profileEditContacts"
import ProfileEditEducation from "./profileEditEducation/profileEditEducation"
import ProfileEditWork from "./profileEditWork/profileEditWork"
import ProfileEditPersonal from "./profileEditPersonal/profileEditPersonal"
import VerticalMenu from "../../../../components/ui/verticalMenu/verticalMenu"
import { useDispatch } from "react-redux"
import { getData } from "../../../../library/store/actions/creators/authCreators"
import { useAlert } from "../../../../hooks/useAlert"

const menuItems = [
  { path: routes.PROFILE_EDIT, label: "Main" },
  { path: routes.PROFILE_EDIT_CONTACTS, label: "Contacts" },
  { path: routes.PROFILE_EDIT_EDUCATION, label: "Education" },
  { path: routes.PROFILE_EDIT_WORK, label: "Work" },
  { path: routes.PROFILE_EDIT_PERSONAL, label: "Personal" },
]

const UserProfileEdit = () => {
  const dispatch = useDispatch()
  const { errorAlert } = useAlert()

  useEffect(() => {
    const getUserData = async () => {
      try {
        await dispatch(getData())
      } catch (error) {
        errorAlert(error)
      }
    }

    getUserData()
  }, [])

  return (
    <Router>
      <div className="common-page__content">
        <main className="common-page__main">
          <Switch>
            <Route exact path={routes.PROFILE_EDIT}>
              <ProfileEditMain />
            </Route>
            <Route exact path={routes.PROFILE_EDIT_CONTACTS}>
              <ProfileEditContacts />
            </Route>
            <Route exact path={routes.PROFILE_EDIT_EDUCATION}>
              <ProfileEditEducation />
            </Route>
            <Route exact path={routes.PROFILE_EDIT_WORK}>
              <ProfileEditWork />
            </Route>
            <Route exact path={routes.PROFILE_EDIT_PERSONAL}>
              <ProfileEditPersonal />
            </Route>
          </Switch>
        </main>
        <aside className="common-page__sidebar">
          <VerticalMenu menuItems={menuItems} />
        </aside>
      </div>
    </Router>
  )
}

export default UserProfileEdit
