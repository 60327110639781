import React from "react"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import Button from "@material-ui/core/Button/Button"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import { useSelector } from "react-redux"
import { FormDatePicker } from "../../../../../../components/formComponents"
import { call } from "../../../../../../library/networking/API"
import { FRIENDS } from "../../../../../../library/store/actions/types/friendsTypes"
import {
  createCalendarEvent,
  getCalendarEventsList,
  updateCalendarEvent,
} from "../../../../../../library/store/actions/creators/calendarCreators"
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons"
import { calendarEventsColors } from "../../../../../../library/constants/calendar"
import * as Yup from "yup"
import {
  MAX_DATE,
  MIN_DATE,
} from "../../../../../../library/constants/defaultDates"
import { useAlert } from "../../../../../../hooks/useAlert"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { CALENDARS } from "../../../../../../library/store/actions/types/calendarTypes"
import { Formik } from "formik"
import Form from "../../../../../../components/v2/layout/forms/form/form"
import UserPicker from "../../../../../../components/v2/inputs/pickers/userPicker"
import { PlainButton } from "../../../../../../components/v2/controls"
import OutsiderInviteeFormModal from "../../../../../bookMeet/component/OutsiderInviteeFormModal"
import FormRadioGroup from "../../../../../../components/formComponents/formRadioGroup"
import ContactLinkPicker from "../../../../../../components/v2/inputs/pickers/ContactLinkPicker"
import useCreator from "../../../../../../hooks/useCreator"
import { getUserName } from "../../../../../../library/utils/user/userUtils"

const validationSchema = Yup.object({
  title: Yup.string().max(200).required(),
  location: Yup.string().max(200),
  description: Yup.string().max(2000),
  conference_link: Yup.string().url(),
  date_from: Yup.date().min(MIN_DATE).nullable(),
  date_to: Yup.date().max(MAX_DATE).nullable(),
  is_all_day: Yup.boolean(),
  color: Yup.string(),
  is_holiday: Yup.boolean(),
})

const DatedAppointmentFormModal = ({
  activeAppointmentId,
  chosenTime,
  open,
  handleClose,
  onSubmit,
  activeRange,
  groupId,
  contextType = "calendar",
}) => {
  const { userData } = useSelector((state) => state.auth)
  const { errorAlert } = useAlert()

  const creator = useCreator()

  const queryClient = useQueryClient()

  const { data, isSuccess } = useQuery({
    queryKey: [CALENDARS.APPOINTMENTS.GET, { id: activeAppointmentId }],
    queryFn: async () => {
      const response = await call(CALENDARS.APPOINTMENTS.GET, {
        id: activeAppointmentId,
      })
      return response.data
    },
    enabled: !!activeAppointmentId,
  })

  const [initialValues, setInitialValues] = React.useState({
    name: data?.name || "",
    description: data?.description || "",
    is_all_day: data?.is_all_day || false,
    date_from: data?.date_from || chosenTime?.date_from || null,
    date_to: data?.date_to || chosenTime?.date_to || null,
    color: data?.color || "",
    is_holiday: data?.is_holiday || false,
    conference_link: data?.conference_link || "",
    location: data?.location || "",
  })

  const [attachments, setAttachments] = React.useState([])

  const [linkOrLocation, setLinkOrLocation] = React.useState("link")

  const [friendInvitees, setFriendInvitees] = React.useState([])
  const [nonMemberInvitees, setNonMemberInvitees] = React.useState([])
  const [activeNonMemberInvitee, setActiveNonMemberInvitee] = React.useState({
    data: null,
    idx: null,
  })
  const [isNonMemberInviteeFormOpen, setIsNonMemberInviteeFormOpen] =
    React.useState(false)

  React.useEffect(() => {
    if (isSuccess) {
      setInitialValues({
        name: data?.name,
        description: data?.description,
        is_all_day: data?.is_all_day,
        date_from: data?.date_from,
        date_to: data?.date_to,
        color: data?.color,
        is_holiday: data?.is_holiday,
        conference_link: data?.conference_link,
        location: data?.location,
      })
      setFriendInvitees(data?.participants?.filter((i) => i.user_id))
      setNonMemberInvitees(data?.participants?.filter((i) => !i.user_id))
      setAttachments(data?.attachments?.blobs)
    }
  }, [isSuccess])

  const onSaveNonMemberInvitee = (value) => {
    if (activeNonMemberInvitee.idx !== null) {
      setNonMemberInvitees((prevState) => {
        const newState = [...prevState]
        newState[activeNonMemberInvitee.idx] = value
        return newState
      })
      setActiveNonMemberInvitee({ data: null, idx: null })
    } else {
      setNonMemberInvitees((prevState) => [...prevState, value])
    }
  }

  const onDeleteNonMemberInvitee = (idx) => {
    setNonMemberInvitees((prevState) => {
      const newState = [...prevState]
      newState.splice(idx, 1)
      return newState
    })
  }

  const handleSubmit = async (values) => {
    const dates = !values.is_all_day
      ? {
          date_from: values.date_from,
          date_to: values.date_to,
        }
      : {
          date_from: new Date(
            new Date(values.date_from).setHours(0, 0, 1)
          ).toISOString(),
          date_to: new Date(
            new Date(values.date_from).setHours(23, 59, 59)
          ).toISOString(),
        }
    const _data = {
      id: activeAppointmentId || undefined,
      name: values.title,
      description: values.description,
      is_all_day: values.is_all_day,
      invitees: [
        ...friendInvitees.map((u) => ({ id: u.id, hold_ids: [] })),
        ...nonMemberInvitees.map((i) => ({
          name_first: i.first_name,
          name_last: i.last_name,
          email: i.email,
          id: 0,
        })),
      ],
      attachments: attachments.map((a) => a.id),
      color: values.color,
      is_holiday: values.is_holiday,
      conference_link: linkOrLocation === "link" ? values.conference_link : "",
      location: linkOrLocation === "location" ? values.location : "",
      ...dates,
    }

    try {
      if (activeAppointmentId) {
        await creator(updateCalendarEvent(_data))
      } else {
        await creator(
          createCalendarEvent({
            group_id: groupId,
            ..._data,
          })
        )
      }

      if (contextType === "calendar") {
        await creator(
          getCalendarEventsList({
            group_id: groupId,
            ...activeRange,
          })
        )
      } else {
        await queryClient.invalidateQueries([CALENDARS.EVENTS.LIST])
      }
      onSubmit()
      handleClose()
    } catch (e) {
      errorAlert(e)
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <DialogTitle>
        {activeAppointmentId ? "Edit appointment" : "Create appointment"}
      </DialogTitle>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {(fk) => (
          <>
            <DialogContent>
              <Form isModal>
                <Form.Row label={"Title"} name={"title"}>
                  <Form.TextField
                    name={"title"}
                    placeholder={"Type meet title"}
                  />
                </Form.Row>

                <UserPicker
                  multiple
                  value={friendInvitees}
                  onChange={setFriendInvitees}
                  requestConfig={{
                    fetchConfig: {
                      apiUrl: FRIENDS.LIST,
                      params: {
                        user_id: userData?.id,
                      },
                    },
                    key: "users",
                  }}
                  fieldName={"users"}
                  userLabel={"Invited friends"}
                />

                <Form.Row label={"Invited Non-Members"}>
                  <div className="form__fieldbox">
                    <div className="form__input form__input--select">
                      {nonMemberInvitees?.map((u, index) => (
                        <div key={index} className={"mb15 f aifs"}>
                          <div className="f1">
                            <p>{getUserName(u)}</p>
                            <p className={"color-black-54 font-13"}>
                              {u.email}
                            </p>
                          </div>
                          <div className="f jcfe f1">
                            <PlainButton
                              onClick={() => {
                                setActiveNonMemberInvitee({
                                  data: u,
                                  idx: index,
                                })
                                setIsNonMemberInviteeFormOpen(true)
                              }}
                              actionType={"edit"}
                              className={"mr10"}
                            />
                            <PlainButton
                              onClick={() => onDeleteNonMemberInvitee(index)}
                              actionType={"delete"}
                            />
                          </div>
                        </div>
                      ))}
                      <PlainButton
                        onClick={() => setIsNonMemberInviteeFormOpen(true)}
                        actionType={"add"}
                      />
                      {isNonMemberInviteeFormOpen && (
                        <OutsiderInviteeFormModal
                          invitee={activeNonMemberInvitee.data}
                          onSave={onSaveNonMemberInvitee}
                          isOpen={isNonMemberInviteeFormOpen}
                          onClose={() => setIsNonMemberInviteeFormOpen(false)}
                        />
                      )}
                    </div>
                  </div>
                </Form.Row>

                <FormDatePicker
                  withTimePicker={!fk.values.is_all_day}
                  // minDate={}
                  maxDate={new Date().setFullYear(
                    new Date().getFullYear() + 1,
                    11,
                    31
                  )}
                  dateFrom={{
                    label: "Start date",
                    value: fk.values.date_from,
                    minDate: new Date().setFullYear(
                      new Date().getFullYear(),
                      0,
                      1
                    ),
                    required: true,
                    error: fk.errors.date_from,
                    handleChange: (value) =>
                      fk.setFieldValue("date_from", value),
                  }}
                  dateTo={
                    !fk.values.is_all_day && {
                      label: "End date",
                      value: fk.values.date_to,
                      required: true,
                      error: fk.errors.date_to,
                      handleChange: (value) =>
                        fk.setFieldValue("date_to", value),
                    }
                  }
                />

                <Form.Row name={"is_all_day"} label={""}>
                  <Form.Checkbox
                    name={"is_all_day"}
                    label={"All day"}
                    onChange={(e) => {
                      fk.setFieldValue("is_all_day", e.target.checked)
                    }}
                  />
                </Form.Row>

                {!!groupId && (
                  <Form.Row name={"is_holiday"} label={""}>
                    <Form.Checkbox
                      name={"is_holiday"}
                      label={"Mark as holiday(s)"}
                      onChange={(e) => {
                        fk.setFieldValue("is_holiday", e.target.checked)
                      }}
                    />
                  </Form.Row>
                )}

                <Form.Row label={"Message"} name={"description"}>
                  <Form.TextField
                    name={"description"}
                    placeholder={"Type invitation message"}
                    multiline
                  />
                </Form.Row>

                <Form.Row name={"color"} label={"Color"}>
                  <div className="flex items-center h-full w-formField">
                    {calendarEventsColors.map((color) => (
                      <div className="flex" key={color.hex}>
                        {fk.values.color !== color.hex ? (
                          <CheckBoxOutlineBlank
                            style={{ color: color.hex }}
                            className="pointer"
                            onClick={() => fk.setFieldValue("color", color.hex)}
                          />
                        ) : (
                          <CheckBox
                            style={{ color: color.hex }}
                            className="pointer"
                            onClick={() => fk.setFieldValue("color", "")}
                          />
                        )}
                      </div>
                    ))}
                  </div>
                </Form.Row>

                <Form.Row
                  name={"link_or_location"}
                  label={"Conference link or physical location"}
                >
                  <div>
                    <FormRadioGroup
                      leftLabel={false}
                      options={[
                        { label: "Conference link", value: "link" },
                        { label: "Physical location", value: "location" },
                      ]}
                      value={linkOrLocation}
                      onChange={setLinkOrLocation}
                      name={"conferenceLink"}
                    />
                  </div>
                </Form.Row>
                {linkOrLocation === "link" ? (
                  <ContactLinkPicker
                    value={fk.values.conference_link}
                    onChange={(v) => {
                      fk.setFieldValue("conference_link", v)
                    }}
                    error={fk.errors.conference_link}
                  />
                ) : (
                  <Form.Row label={"Physical location"}>
                    <Form.TextField
                      name={"location"}
                      placeholder={"Type meet location"}
                    />
                  </Form.Row>
                )}
                <Form.Row label={"Attachments"} name={"attachments"}>
                  <Form.Attachments
                    token={userData.token}
                    attachments={attachments}
                    setAttachments={({ blobs }) => setAttachments(blobs)}
                    isModal
                  />
                </Form.Row>
              </Form>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button color="primary" onClick={() => fk.handleSubmit()}>
                Save
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  )
}

export default DatedAppointmentFormModal
