import React from "react"
import BoxPage from "../../../../../components/v2/layout/boxPage/boxPage"
import { useIdParam } from "../../../../../hooks/useIdParam"
import { useCourse } from "../../../../../hooks/data/courses/courses"
import { useCourseSteps } from "../../../../../hooks/data/courses/steps"
import CourseStepForm from "./CourseStepForm"
import Loader from "../../../../../components/ui/loader"
import { PlainButton } from "../../../../../components/v2/controls"
import AddOutlined from "@material-ui/icons/AddOutlined"
import * as qs from "query-string"
import { useLocation } from "react-router-dom"

const sharedUnit = JSON.parse(localStorage.getItem("unit"))

const CourseSettingsSteps = () => {
  const knowmixId = useIdParam("knowmixId")
  const location = useLocation()

  const { unitId } = qs.parse(location.search)

  const { course, queryKey } = useCourse()
  const { steps, isSuccess, isLoading } = useCourseSteps()

  const [initiatedWithUnit, setInitiatedWithUnit] = React.useState(false)
  const [isCreatingStep, setIsCreatingStep] = React.useState(false)

  const onCreatedStep = () => {
    setIsCreatingStep(false)
    if (sharedUnit) {
      localStorage.removeItem("unit")
    }
  }

  if (!isSuccess && isLoading) return <Loader />

  return (
    <BoxPage title={"Steps"}>
      {steps.map((step, idx) => (
        <CourseStepForm key={step.id} step={step} stepIdx={idx} />
      ))}
      {isCreatingStep ? (
        <CourseStepForm
          stepIdx={steps?.length}
          onCreated={onCreatedStep}
          unit={Number(unitId) === sharedUnit?.id ? sharedUnit : null}
        />
      ) : (
        <div className="flex items-center justify-center">
          <PlainButton onClick={() => setIsCreatingStep(true)}>
            <AddOutlined /> Add another step
          </PlainButton>
        </div>
      )}
    </BoxPage>
  )
}

export default CourseSettingsSteps
