import React, { Component } from "react"
import ConversationsList from "./conversationsList"
import * as routes from "../../../library/constants/routes"
import { generatePath, Link, Route, Switch, withRouter } from "react-router-dom"
import Conversation from "./conversation"
import VerticalMenu from "../../../components/ui/verticalMenu"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import avatarDefault from "../../../assets/img/user_avatar.svg"
import ConversationStart from "./conversationStart"
import { clearMessages } from "../../../library/store/actions/creators/messagesCreators"
import unavailableAvatar from "../../../assets/img/user_unavailable.svg"

class Conversations extends Component {
  state = {
    userReceived: false,
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve)
    })
  }

  setUser = async () => {
    await this.setState({ userReceived: true })
  }

  clearUser = () => {
    this.setState({ userReceived: false })
  }

  componentWillUnmount() {
    this.props.clearMessages()
  }

  render() {
    const { userReceived } = this.state
    const {
      person: { personData },
    } = this.props

    const menuItems = [
      {
        path: routes.CONVERSATIONS,
        label: "All chats",
      },
      {
        path: routes.CONVERSATIONS_UNREAD,
        label: "Unread chats",
      },
    ]

    return (
      <div className="common-page__content">
        <main className="common-page__main">
          <Switch>
            <Route
              exact
              path={routes.CONVERSATIONS}
              render={(props) => {
                return <ConversationsList {...props} type="all" />
              }}
            />
            <Route
              exact
              path={routes.CONVERSATIONS_NEW}
              component={ConversationStart}
            />
            <Route
              exact
              path={routes.CONVERSATIONS_UNREAD}
              render={(props) => {
                return <ConversationsList {...props} type="unread" />
              }}
            />
            <Route
              exact
              path={routes.CONVERSATIONS_FAVORITE}
              render={(props) => {
                return <ConversationsList {...props} type="favorite" />
              }}
            />
            <Route
              exact
              path={routes.CONVERSATION}
              render={(props) => {
                return (
                  <Conversation
                    {...props}
                    setUser={this.setUser}
                    clearUser={this.clearUser}
                  />
                )
              }}
            />
          </Switch>
        </main>
        <aside className="common-page__sidebar">
          {userReceived && this.props.match.path === routes.CONVERSATION && (
            <div className="box aside-header">
              <Link
                to={generatePath(routes.USER, { userId: personData.id })}
                className="box__content aside-header__content"
              >
                <img
                  src={
                    personData.deleted || personData.blocked
                      ? unavailableAvatar
                      : personData.photo
                      ? personData.photo
                      : avatarDefault
                  }
                  className="aside-header__img"
                  alt=""
                />
                <div className="aside-header__info">
                  <div className="aside-header__name">
                    {`${personData.first_name} ${personData.last_name}`}
                  </div>
                  <div className="aside-header__help">go to profile</div>
                </div>
              </Link>
            </div>
          )}
          <VerticalMenu menuItems={menuItems} />
          {/*<div className="order-0-xs">*/}
          {/*    <Ads/>*/}
          {/*</div>*/}
        </aside>
      </div>
    )
  }
}

const mapStateToProps = ({ auth, person }) => ({ auth, person })
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ clearMessages }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withSnackbar(Conversations)))
