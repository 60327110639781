import { useQuery } from "@tanstack/react-query"
import { GROUPS } from "../../../library/store/actions/types/groupsTypes"
import { useIdParam } from "../../useIdParam";

export const useGroup = (id = null, options = {}) => {
  const groupIdParam = useIdParam("groupId")

  const { data, isLoading, isError, isSuccess } = useQuery({
    queryKey: [
      GROUPS.GET,
      {
        id: id || groupIdParam,
        participants_limit: 1,
        connections_limit: 1,
        products_limit: 1,
      },
    ],
    staleTime: Infinity,
    ...options,
  })

  return {
    group: data,
    isLoading,
    isError,
    isSuccess,
  }
}
