import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query"
import { call } from "../../../library/networking/API"
import { useIdParam } from "../../useIdParam"
import { LIMIT } from "../../../library/constants/limits"
import { POSTS } from "../../../library/store/actions/types/postsTypes"

export const usePost = ({ id, options = {} } = {}) => {
  const postId = useIdParam("postId")
  const __id = id || postId

  const { data: post, ...query } = useQuery([POSTS.GET], {
    queryFn: async (ctx) => {
      return await call(ctx.queryKey[0], {
        id: __id,
        comments_limit: 3,
        replies_limit: 3,
      })
    },
    ...options,
  })

  return {
    post,
    ...query,
  }
}

export const usePostsList = ({ userId, groupId, queryKey }) => {
  const query = useInfiniteQuery({
    staleTime: 30000,
    queryKey,
    queryFn: async (ctx) => {
      const response = await call(ctx.queryKey[0], {
        offset: ctx.pageParam || 0,
        limit: LIMIT,
        comments_limit: 3,
        replies_limit: 3,
        user_id: userId || undefined,
        group_id: groupId || undefined,
      })

      return response.data?.posts || []
    },
    getNextPageParam: (prevPage, allPages) => {
      if (prevPage.length === LIMIT) return allPages.length * LIMIT
    },
    refetchOnWindowFocus: false,
  })

  return {
    ...query,
  }
}

export const usePostsMutations = ({
  queryKey,
  isMutatingInfinite = true,
} = {}) => {
  const queryClient = useQueryClient()

  const createPost = useMutation({
    mutationFn: async ({ user_id, group_id, message, attachments, personal }) =>
      await call(POSTS.CREATE, {
        user_id: !group_id && !!user_id ? user_id : undefined,
        group_id: group_id || undefined,
        message,
        attachments,
        personal,
      }),
    onSuccess: async () => {
      await queryClient.resetQueries({ queryKey, exact: true })
    },
  })

  const updatePost = useMutation({
    mutationFn: async ({ id, message, attachments }) => {
      await call(POSTS.UPDATE, { id, message, attachments })
    },
    onSuccess: async (_, variables) => {
      await queryClient.invalidateQueries({ queryKey })
    },
  })

  const deletePost = useMutation({
    mutationFn: async ({ id }) => {
      await call(POSTS.DELETE, { id })
    },
  })

  const archivePost = useMutation({
    mutationFn: async ({ id }) => {
      await call(POSTS.ARCHIVE, { id })
    },
  })

  const restorePost = useMutation({
    mutationFn: async ({ id }) => {
      await call(POSTS.RESTORE, { id })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey })
    },
  })

  const pinPost = useMutation({
    mutationFn: async ({ id }) => {
      await call(POSTS.PIN, { id })
    },
    onSuccess: async () => {
      await queryClient.resetQueries({ queryKey, exact: true })
    },
  })

  const unpinPost = useMutation({
    mutationFn: async ({ id }) => {
      await call(POSTS.UNPIN, { id })
    },
    onSuccess: async () => {
      await queryClient.resetQueries({ queryKey, exact: true })
    },
  })

  const share = useMutation({
    mutationFn: async ({
      post_id,
      user_id,
      group_id,
      product_id,
      knowcred_id,
      standard_id,
    }) => {
      await call(POSTS.SHARE, {
        post_id,
        user_id,
        group_id,
        product_id,
        knowcred_id,
        standard_id,
      })
    },
    onSuccess: async () => {
      if (queryKey) {
        await queryClient.invalidateQueries({ queryKey })
      }
    },
  })

  return {
    share,
    createPost,
    updatePost,
    deletePost,
    archivePost,
    restorePost,
    pinPost,
    unpinPost,
  }
}
