import React, { useEffect, useState } from "react"
import { generatePath, useParams, useRouteMatch } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useAlert } from "../../../../../hooks/useAlert"
import { KNOWMAP_INCOMING_MEET } from "../../../../../library/constants/routes"
import {
  acceptMeet,
  clearMeet,
  declineMeet,
  forwardMeet,
  getMeet,
  markMeetParticipantAttendance,
} from "../../../../../library/store/actions/creators/uknowCreators"
import Dialog from "@material-ui/core/Dialog/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import { safeFormatDate } from "../../../../../library/utils/dateConvert"
import { minutesToString } from "../../../../../library/utils/timeConvert"
import returnExternalUrl from "../../../../../library/utils/returnExternalUrl"
import InsertDriveFile from "@material-ui/icons/InsertDriveFile"
import { formatBytes } from "../../../../../library/utils/fileSizeConverter"
import Loader from "../../../../../components/ui/loader"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import Button from "@material-ui/core/Button/Button"
import UpdateIcon from "@material-ui/icons/UpdateOutlined"
import { Tooltip } from "@material-ui/core"
import withErrorBoundary from "../../../../../hocs/guards/withErrorBoundary"
import UserLink from "../../../../../components/v2/utils/UserLink"
import { useMeetModals } from "../../../../../hooks/entities/useMeetsModals"
import CheckIcon from "@material-ui/icons/CheckCircle"
import CloseIcon from "@material-ui/icons/Close"

const MeetDialog = ({
  isConfirmable = false,
  activeMeet,
  open,
  handleClose,
}) => {
  console.log("activeMeet------", activeMeet)
  const params = useParams()
  const { path } = useRouteMatch()
  const dispatch = useDispatch()
  const { errorAlert } = useAlert()
  const { currentMeet } = useSelector(({ uknow }) => uknow)

  const { userData } = useSelector(({ auth }) => auth)

  const { successAlert } = useAlert()

  const meetId = path === KNOWMAP_INCOMING_MEET ? params.meetId : activeMeet

  const link =
    document.location.origin + generatePath(KNOWMAP_INCOMING_MEET, { meetId })

  const copyLink = async () => {
    await navigator.clipboard.writeText(link)
    successAlert("Link copied to clipboard")
  }

  const { onAssignHoldOpen } = useMeetModals()

  const [dataReceived, setDataReceived] = useState(false)

  const getData = async () => {
    try {
      await dispatch(getMeet({ id: meetId }))

      setDataReceived(true)
    } catch (e) {
      if (e.error.code === 20004) {
        errorAlert("You don't have access to this Meet")
      } else {
        errorAlert(e)
      }

      return handleClose(true)
    }
  }

  useEffect(() => {
    getData()

    return () => {
      dispatch(clearMeet())
    }
  }, [])

  const onForward = async ({ user }) => {
    try {
      await dispatch(forwardMeet({ id: currentMeet.id, user_id: user.id }))

      await getData()

      successAlert(`${user.first_name} been moved to the next cohort`)
    } catch (e) {
      errorAlert(e)
    }
  }

  const onForwardCurrentUser = async () => {
    try {
      await dispatch(forwardMeet({ id: currentMeet.id, user_id: userData.id }))

      await getData()
    } catch (e) {
      errorAlert(e)
    }
  }

  const onAccept = async () => {
    try {
      await dispatch(
        acceptMeet({
          public_meeting_id: currentMeet.id,
          public_meeting_participant_id:
            currentMeet.public_meeting_participant_id,
        })
      )

      await getData()
    } catch (e) {
      errorAlert(e)
    }
  }

  const onDecline = async () => {
    try {
      await dispatch(declineMeet({ id: currentMeet.public_meeting_id }))

      await getData()
    } catch (e) {
      errorAlert(e)
    }
  }

  const onAttended = async ({ user }) => {
    try {
      await dispatch(
        markMeetParticipantAttendance({
          public_meeting_participant_id: user.public_meeting_participant_id,
        })
      )

      await getData()

      successAlert(`${user.first_name} been marked as attended`)
    } catch (e) {
      errorAlert(e)
    }
  }

  const onRemove = async ({ user }) => {
    try {
      // await dispatch(
      //   forwardMeet({ id: currentMeet.id, user_id: user.id })
      // )
      //
      // await getData()
      //
      // successAlert(`${user.first_name} been moved to the next cohort`)
    } catch (e) {
      errorAlert(e)
    }
  }

  return (
    <Dialog
      aria-labelledby="create-record-dialog"
      onClose={handleClose}
      open={open}
    >
      <DialogTitle id="alert-dialog-title" className="text-center">
        Meet details
      </DialogTitle>
      <DialogContent>
        {dataReceived ? (
          <div className="f jcc full-width">
            <table className="product-details product-details--invenstory-record">
              <tbody>
                <tr className="product-details__row">
                  <td className="product-details__label">Name</td>
                  <td className="product-details__value">{currentMeet.name}</td>
                </tr>

                <tr className="product-details__row">
                  <td className="product-details__label">Creator</td>
                  <td className="product-details__value">
                    <UserLink user={currentMeet.user} />
                  </td>
                </tr>

                {!!currentMeet.cohort_size && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Cohort size</td>
                    <td className="product-details__value">
                      {currentMeet.cohort_size}
                    </td>
                  </tr>
                )}

                {!!currentMeet?.target_datetime && (
                  <tr className="product-details__row">
                    <td className="product-details__label">
                      Target date and time
                    </td>
                    <td className="product-details__value">
                      {safeFormatDate(currentMeet.target_datetime, {
                        withTime: true,
                      })}
                    </td>
                  </tr>
                )}

                {!!currentMeet?.target_interval && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Target interval</td>
                    <td className="product-details__value">
                      {minutesToString(currentMeet?.target_interval)}
                    </td>
                  </tr>
                )}

                {currentMeet?.creator && !!currentMeet?.require_full_cohort && (
                  <tr className="product-details__row">
                    <td className="product-details__label"></td>
                    <td className="product-details__value">
                      <span>
                        * Require full cohort, rescheduling for all if a
                        participant who accepted in that cohort pushes it
                        forward
                      </span>

                      {/*{minutesToString(currentMeet?.target_interval)}*/}
                    </td>
                  </tr>
                )}

                {currentMeet?.creator && !!currentMeet?.allow_over_cohort && (
                  <tr className="product-details__row">
                    <td className="product-details__label"></td>
                    <td className="product-details__value">
                      <span>
                        * If a full cohort sets a Meet and then more
                        participants accept, add them if it is within an 15
                        minutes prior
                      </span>

                      {/*{minutesToString(currentMeet?.target_interval)}*/}
                    </td>
                  </tr>
                )}

                {!!(
                  currentMeet?.cohort_current_datetime &&
                  currentMeet?.cohort_current
                ) && (
                  <tr className="product-details__row">
                    <td className="product-details__label">
                      Set date and time
                    </td>
                    <td className="product-details__value">
                      {safeFormatDate(currentMeet.cohort_current_datetime, {
                        withTime: true,
                      })}
                    </td>
                  </tr>
                )}

                {/*<tr className="product-details__row">*/}
                {/*  <td className="product-details__label">Auto cancel</td>*/}
                {/*  <td className="product-details__value">*/}
                {/*    {parseBool(currentMeet?.auto_cancel)}*/}
                {/*  </td>*/}
                {/*</tr>*/}

                {!!currentMeet?.duration && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Duration</td>
                    <td className="product-details__value">
                      {minutesToString(currentMeet?.duration)}
                    </td>
                  </tr>
                )}

                {!!currentMeet.location && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Location</td>
                    <td className="product-details__value">
                      {currentMeet.location}
                    </td>
                  </tr>
                )}

                {!!currentMeet?.conference_link && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Conference link</td>
                    <td className="product-details__value">
                      <ul className="text-list">
                        <a
                          href={`${returnExternalUrl(
                            currentMeet.conference_link
                          )}`}
                          className="link"
                        >
                          {currentMeet.conference_link}
                        </a>
                      </ul>
                    </td>
                  </tr>
                )}

                {!!currentMeet.instructions && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Instructions</td>
                    <td className="product-details__value">
                      {currentMeet.instructions}
                    </td>
                  </tr>
                )}

                {!!currentMeet.reminder && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Reminder</td>
                    <td className="product-details__value">
                      {currentMeet.reminder}
                    </td>
                  </tr>
                )}

                {!!currentMeet?.participants?.length && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Participants</td>
                    <td className="product-details__value">
                      <div className={"full-width"}>
                        {currentMeet.participants.map((user) => (
                          <div className="mb5 full-width f jcsb" key={user.id}>
                            <div>
                              <UserLink user={user} />
                              {currentMeet?.creator && (
                                <>
                                  {!!user.accepted && (
                                    <span className={"font-12 color-black-54"}>
                                      Accepted at{" "}
                                      {safeFormatDate(user.updated_accepted, {
                                        withTime: true,
                                      })}
                                    </span>
                                  )}
                                  {!!user.forwarded && (
                                    <span className={"font-12 color-black-54"}>
                                      Forwarded at{" "}
                                      {safeFormatDate(user.updated_forwarded, {
                                        withTime: true,
                                      })}
                                    </span>
                                  )}
                                  {!!user.declined && (
                                    <span className={"font-12 color-black-54"}>
                                      Declined at{" "}
                                      {safeFormatDate(user.updated_declined, {
                                        withTime: true,
                                      })}
                                    </span>
                                  )}
                                  {!!user.removed && (
                                    <span className={"font-12 color-black-54"}>
                                      Removed at{" "}
                                      {safeFormatDate(user.updated_removed, {
                                        withTime: true,
                                      })}
                                    </span>
                                  )}
                                  {!!user.attended && (
                                    <span className={"font-12 color-black-54"}>
                                      Attended at{" "}
                                      {safeFormatDate(user.updated_attended, {
                                        withTime: true,
                                      })}
                                    </span>
                                  )}
                                </>
                              )}
                            </div>

                            {currentMeet?.creator && (
                              <div className="f aic">
                                {!user.forwarded && user.accepted && (
                                  <Tooltip title="Move to next cohort" arrow>
                                    <button
                                      type={"button"}
                                      onClick={() => onForward({ user })}
                                      className={"f"}
                                    >
                                      <UpdateIcon color={"primary"} />
                                    </button>
                                  </Tooltip>
                                )}
                                {!user.removed &&
                                  !user.attended &&
                                  !user.forwarded && (
                                    <Tooltip title="Mark as present" arrow>
                                      <button
                                        type={"button"}
                                        onClick={() => onAttended({ user })}
                                        className={"f"}
                                      >
                                        <CheckIcon color={"primary"} />
                                      </button>
                                    </Tooltip>
                                  )}
                                {/*{!user.removed && !user.attended && (*/}
                                {/*  <Tooltip title="Remove from meet" arrow>*/}
                                {/*    <button*/}
                                {/*      type={"button"}*/}
                                {/*      onClick={() =>*/}
                                {/*        onRemove({user})*/}
                                {/*      }*/}
                                {/*      className={'f'}*/}
                                {/*    >*/}
                                {/*      <CloseIcon color={"primary"} />*/}
                                {/*    </button>*/}
                                {/*  </Tooltip>*/}
                                {/*)}*/}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </td>
                  </tr>
                )}

                {!!currentMeet?.other_links?.length && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Links</td>
                    <td className="product-details__value">
                      <ul className="text-list">
                        {currentMeet.other_links.map((link) => (
                          <li key={link}>
                            <a
                              href={`${returnExternalUrl(link)}`}
                              className="link"
                            >
                              {link}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </td>
                  </tr>
                )}

                {!!currentMeet?.blobs?.length && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Attachments</td>
                    <td className="product-details__value">
                      <div className="post__files">
                        {currentMeet.blobs.map(({ id, name, size, url }) => {
                          return (
                            <a
                              href={url}
                              target="_blank"
                              className="post__file"
                              key={id}
                            >
                              <InsertDriveFile
                                className="post__file-icon"
                                style={{ fontSize: "30px" }}
                              />
                              <div className="post__file-info">
                                <span className="link link--inline">
                                  {name}
                                </span>
                                <span className="post__file-size">
                                  {formatBytes(size)}
                                </span>
                              </div>
                            </a>
                          )
                        })}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        ) : (
          <Loader />
        )}
      </DialogContent>
      <DialogActions>
        <div className="f jcsb full-width">
          <div>
            {dataReceived && (
              <>
                {isConfirmable && !currentMeet?.creator && (
                  <>
                    {!currentMeet.accepted && !currentMeet.declined && (
                      <Button color="primary" onClick={onAccept}>
                        Accept
                      </Button>
                    )}
                    {!currentMeet.accepted && !currentMeet.declined && (
                      <Button
                        color="primary"
                        onClick={() => onAssignHoldOpen(meetId)}
                      >
                        Assign own hold
                      </Button>
                    )}
                    {!currentMeet.accepted && !currentMeet.declined && (
                      <Button color="primary" onClick={onDecline}>
                        Decline
                      </Button>
                    )}
                    {currentMeet.accepted && !currentMeet.declined && (
                      <Button color="primary" onClick={onForwardCurrentUser}>
                        Move to next cohort
                      </Button>
                    )}
                  </>
                )}

                {!!currentMeet.creator && (
                  <Button color="primary" onClick={copyLink}>
                    Copy link
                  </Button>
                )}
              </>
            )}
          </div>
          <Button color="primary" onClick={handleClose}>
            Close
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default withErrorBoundary(MeetDialog)
