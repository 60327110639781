import React from "react"
import SuperListPage from "../../../components/v2/layout/superListPage/superListPage"
import { NOTIFICATION } from "../../../library/store/actions/types/notificationTypes"
import NotificationCard from "./notificationCard"
import { getCardSize } from "../../../library/constants/styles/cardSizes"

const Notifications = () => {
  return (
    <div className="common-page__content common-page__content--static">
      <main className="common-page__main">
        <SuperListPage
          Header={() => <div className={"box__heading"}>Notifications</div>}
          queryConfig={{
            key: [NOTIFICATION.LIST],
            listKey: "notifications",
          }}
          getListItemComponent={({ item, index }) => (
            <NotificationCard
              notification={item}
              index={index}
              queryKey={[NOTIFICATION.LIST]}
              isMutationInfinite
              key={item.id}
            />
          )}
          listItemHeight={getCardSize(6).numValPx}
        />
      </main>
    </div>
  )
}

export default Notifications
