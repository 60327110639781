import React from "react"
import styled from "@emotion/styled"
import isPropValid from "@emotion/is-prop-valid"
import { colors } from "../../../library/constants/styles/colors"
import { Link } from "react-router-dom"
import returnExternalUrl from "../../../library/utils/returnExternalUrl"

const A = styled(Link, {
  shouldForwardProp: (prop) => isPropValid(prop),
})`
  display: ${(props) => (props.ellipsis || props.inline ? "inline" : "flex")};
  align-items: center;
  transition: all 0.2s;
  color: ${colors.primary};
  text-align: left;
  font-size: ${(props) => (props.fontSize ? props.fontSize + "px" : "inherit")};
  font-weight: ${(props) => (props.bold && props.bold !== "false" ? 500 : 400)};
  word-break: break-all;

  @supports (display: grid) {
    word-break: break-word;
  }

  :hover {
    text-decoration: underline;
  }
`

const PlainLink = ({
  children,
  native = false,
  to,
  fontSize,
  bold = false,
  ellipsis = false,
  inline = false,
  newTab = false,
  phone = false,
  ...rest
}) => {
  return (
    <A
      as={native ? "a" : undefined}
      href={native ? (phone ? `tel:${to}` : returnExternalUrl(to)) : undefined}
      to={!native ? to : undefined}
      fontSize={fontSize}
      bold={bold}
      ellipsis={ellipsis}
      inline={inline}
      target={newTab || native ? "_blank" : undefined}
      {...rest}
    >
      {children}
    </A>
  )
}

export default PlainLink
