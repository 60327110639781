import React, { Component } from "react"
import * as routes from "../../../library/constants/routes"
import { generatePath, Link, Route, Switch, withRouter } from "react-router-dom"
import VerticalMenu from "../../../components/ui/verticalMenu/verticalMenu"
import FriendsList from "./friendsList/friendsList"
import FriendsRequests from "./friendsRequests/friendsRequests"
import FriendsSearch from "./friendsSearch/friendsSearch"
import { bindActionCreators } from "redux"
import { getPerson } from "../../../library/store/actions/creators/personCreators"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import avatarDefault from "../../../assets/img/user_avatar.svg"
import getErrorText from "../../../library/constants/errorTypes"

const publicRoutes = [routes.FRIENDS, routes.FRIENDS_ONLINE]
const privateRoutes = [
    routes.FRIENDS_REQUESTS_OUTGOING,
    routes.FRIENDS_REQUESTS_PENDING,
    routes.FRIENDS_RECOMMENDED,
];

class Friends extends Component {

    state = {
        dataReceived: false,
    };

    async componentDidMount() {
        await this.getPersonData();
    };

    async componentDidUpdate(prevProps, prevState, snapshot) {
        const {match: {params}} = this.props;

        if (params.userId !== prevProps.match.params.userId) {
            this.setState({dataReceived: false});

            await this.getPersonData();
        }
    };

    getPersonData = async () => {
        const {match: {path, params}, auth: {userData}} = this.props;

        if (publicRoutes.includes(path) && parseInt(params.userId) !== userData.id) {
            try {
                await this.props.getPerson(userData.token, parseInt(params.userId));
                this.setState({dataReceived: true});
            } catch ({error}) {
                this.props.enqueueSnackbar(getErrorText(error.code), {variant: "error"});
            }
        }
    };

    render() {
        const {
            match: {path, params},
            auth: {userData},
            person: {personData},
        } = this.props;

        const {dataReceived} = this.state;

        const menuItems = [
            {
                path: generatePath(routes.FRIENDS, {userId: userData.id}),
                label: "My Friends",
            },
            {
                path: routes.FRIENDS_REQUESTS_PENDING,
                label: "Friend requests",
            },
            {
                path: routes.FRIENDS_SEARCH,
                label: "Find friends",
            },
        ];

        const showNav = parseInt(params.userId) === userData.id || path === routes.FRIENDS_SEARCH || privateRoutes.includes(path);

        return (
            <div className="common-page__content">
                <main className="common-page__main">
                    <Switch>
                        <Route exact path={routes.FRIENDS} component={FriendsList}/>
                        <Route exact path={routes.FRIENDS_REQUESTS_PENDING} component={FriendsRequests}/>
                        <Route exact path={routes.FRIENDS_REQUESTS_OUTGOING} component={FriendsRequests}/>
                        <Route exact path={routes.FRIENDS_SEARCH} component={FriendsSearch}/>
                    </Switch>
                </main>
                <aside className="common-page__sidebar">
                    {(dataReceived && (parseInt(params.userId) !== userData.id) && publicRoutes.includes(path)) && (
                        <div className="box aside-header">
                            <Link to={generatePath(routes.USER, {userId: params.userId})}
                                  className="box__content aside-header__content">
                                <img src={personData.photo || avatarDefault} className="aside-header__img" alt=""/>
                                <div className="aside-header__info">
                                    <div className="aside-header__name">
                                        {`${personData.first_name} ${personData.last_name}`}
                                    </div>
                                    <div className="aside-header__help">back to page</div>
                                </div>
                            </Link>
                        </div>
                    )}

                    {showNav && <VerticalMenu menuItems={menuItems}/>}

                    {/*{path === routes.FRIENDS_SEARCH && <UsersFilter />}*/}
                </aside>
            </div>
        );
    }
}

const mapStateToProps = ({auth, person}) => ({auth, person});
const mapDispatchToProps = dispatch => bindActionCreators({getPerson}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withSnackbar(Friends)));
