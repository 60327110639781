import React from "react"
import styled from "@emotion/styled"
import { usePostCtx } from "../PostContext"
import { PostHeader } from "./index"
import { devices } from "../../../../../library/constants/styles/responsive"
import { PostText } from "../components"
import SharedPostContent from "../components/sharedPostContent/sharedPostContent"
import PostImages from "../components/postImages"
import PostVideos from "../components/postVideos"
import PostFiles from "../components/postFiles"

const Container = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: ${(props) => (props.isRepost ? "15px" : 0)};
  border-left: ${(props) =>
    props.isRepost ? "1px solid rgba(210, 210, 210, 0.5)" : 0};

  @media ${devices.xs} {
    padding-left: ${(props) => (props.isRepost ? "5px" : 0)};
  }
`

const PostContent = ({ isRepost = false }) => {
  const { post } = usePostCtx()

  return (
    <Container isRepost={isRepost}>
      {isRepost && <PostHeader isRepost />}

      {!!post.message.length && (
        <div className={"xl:max-w-[75%]"}>
          <PostText message={post.message} />
        </div>
      )}
      {!!post.share_type && <SharedPostContent type={post.share_type} />}
      {!!post.images.length && <PostImages images={post.images} />}
      {!!post.videos.length && <PostVideos videos={post.videos} />}
      {!!post.blobs.length && <PostFiles files={post.blobs} />}
    </Container>
  )
}

export default PostContent
