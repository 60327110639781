import React from "react"
import "./userProfileSettings.scss"
import { Route, Switch } from "react-router-dom"
import * as routes from "../../../../library/constants/routes"
import ProfileSettingsGeneral from "./profileSettingsGeneral/profileSettingsGeneral"
import ProfileSettingsBlacklist from "./profileSettingsBlacklist/profileSettingsBlacklist"
import VerticalMenu from "../../../../components/ui/verticalMenu/verticalMenu"
import ProfileVerification from "./profileVerification/profileVerification"
import ProfileSettingsHolds from "./profileSettingsHolds/ProfileSettingsHolds"
import ProfileSettingsIntegrations from "./profileSettingsIntegrations/ProfileSettingsIntegrations"
import ProfileSettingsAppointmentLinks from "./profileSettingsAppointmentLinks/ProfileSettingsAppointmentLinks"
import ProfileSettingsAppointmentTypes from "./profileSettingsAppointmentTypes/ProfileSettingsAppointmentTypes"
import ProfileSettingsSubscriptions from "./profileSettingsSubscriptions/profileSettingsSubscriptions"
import ProfileSettingsNotifications from "./profileSettingsNotifications"

const menuItems = [
  { path: routes.PROFILE_SETTINGS, label: "General" },
  { path: routes.PROFILE_SETTINGS_HOLDS, label: "Holds" },
  { path: routes.PROFILE_SETTINGS_BLACKLIST, label: "Blocked users" },
  { path: routes.PROFILE_SETTINGS_VERIFICATION, label: "Verification" },
  { path: routes.PROFILE_SETTINGS_INTEGRATIONS, label: "Integrate calendar" },
  {
    path: routes.PROFILE_SETTINGS_APPOINTMENT_LINKS,
    label: "Appointment links",
  },
  {
    path: routes.PROFILE_SETTINGS_APPOINTMENT_TYPES,
    label: "Appointment types",
  },
  {
    path: routes.PROFILE_SETTINGS_SUBSCRIPTIONS,
    label: "Subscriptions",
  },
  { path: routes.PROFILE_SETTINGS_NOTIFICATIONS, label: "Notifications" },
]

const UserProfileSettings = () => {
  return (
    <div className="common-page__content">
      <main className="common-page__main">
        <Switch>
          <Route
            exact
            path={routes.PROFILE_SETTINGS}
            component={ProfileSettingsGeneral}
          />
          <Route
            exact
            path={routes.PROFILE_SETTINGS_BLACKLIST}
            component={ProfileSettingsBlacklist}
          />
          <Route
            exact
            path={routes.PROFILE_SETTINGS_VERIFICATION}
            component={ProfileVerification}
          />
          <Route
            exact
            path={routes.PROFILE_SETTINGS_HOLDS}
            component={ProfileSettingsHolds}
          />
          <Route
            exact
            path={routes.PROFILE_SETTINGS_INTEGRATIONS}
            component={ProfileSettingsIntegrations}
          />
          <Route
            exact
            path={routes.PROFILE_SETTINGS_APPOINTMENT_TYPES}
            component={ProfileSettingsAppointmentTypes}
          />
          <Route
            exact
            path={routes.PROFILE_SETTINGS_APPOINTMENT_LINKS}
            component={ProfileSettingsAppointmentLinks}
          />
          <Route
            exact
            path={routes.PROFILE_SETTINGS_SUBSCRIPTIONS}
            component={ProfileSettingsSubscriptions}
          />
          <Route
            exact
            path={routes.PROFILE_SETTINGS_NOTIFICATIONS}
            component={ProfileSettingsNotifications}
          />
        </Switch>
      </main>
      <aside className="common-page__sidebar">
        <VerticalMenu menuItems={menuItems} />
      </aside>
    </div>
  )
}

export default UserProfileSettings
