import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import Dialog from "@material-ui/core/Dialog"
import Button from "@material-ui/core/Button/Button"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import Select from "@material-ui/core/Select/Select"
import MenuItem from "@material-ui/core/MenuItem/MenuItem"
import Loader from "../../../../../../../../../../../../../../components/ui/loader"
import FormHelperText from "@material-ui/core/FormHelperText"
import FormControl from "@material-ui/core/FormControl/FormControl"
import { useAlert } from "../../../../../../../../../../../../../../hooks/useAlert"
import { weekDaysArray } from "../../../../../../../../../../../../../../library/utils/timeConvert"
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox/Checkbox"
import { localTimeToUTC } from "../../../../../../../../../../../../../../library/utils/timeString/timeString"
import TimePeriodPicker from "../../../../../../../../../../../../../../components/formComponents/TimePeriodPicker"
import {
  useBlock,
  useBlockMutations,
} from "../../../../../../../../../../../../../../hooks/data/calendar/schedules"
import { useQuery } from "@tanstack/react-query"
import { SEGMENTS } from "../../../../../../../../../../../../../../library/store/actions/types/groupsTypes"
import StaticDataSelect from "../../../../../../../../../../../../../../components/v2/inputs/select/staticDataSelect"
import { call } from "../../../../../../../../../../../../../../library/networking/API"

const BlockFormModal = ({
  open,
  onClose,
  activeBlock,
  queryKey,
  chosenRotation,
}) => {
  const { errorAlert } = useAlert()
  const params = useParams()

  const { data: currentBlock, isSuccess } = useBlock(activeBlock, {
    enabled: !!activeBlock,
  })

  const { createBlock, updateBlock } = useBlockMutations(queryKey)

  const { data: tracksList, isSuccess: tracksListSuccess } = useQuery({
    queryKey: [
      SEGMENTS.SECTIONS.TRACKS.LIST,
      { section_id: Number(params.sectionId) },
    ],
    queryFn: async (ctx) => {
      try {
        const response = await call(ctx.queryKey[0], ctx.queryKey[1])
        const data = await response.data
        return data.tracks
      } catch (e) {
        errorAlert("Can't fetch tracks")
      }
    },
  })

  const [values, setValues] = useState({
    name: "",
    rotation: Number(chosenRotation) || new Date().getDay(),
    school_id: "",
    is_all_day: false,
    tracks: [],
  })
  const [timeFrom, setTimeFrom] = useState(localTimeToUTC("10:00"))
  const [timeTo, setTimeTo] = useState(localTimeToUTC("11:00"))
  const [daysError, setDaysError] = useState(false)

  useEffect(async () => {
    if (!!activeBlock && currentBlock) {
      setValues({
        ...values,
        name: currentBlock.name,
        rotation: currentBlock.rotations[0],
        school_id: currentBlock.school_id,
        is_all_day: currentBlock.is_all_day,
        tracks: currentBlock.tracks,
      })
      setTimeFrom(currentBlock.time_from)
      setTimeTo(currentBlock.time_to)
    }
  }, [currentBlock])

  const handleChangeRequired = (name) => (event) => {
    switch (name) {
      case "rotation":
        setDaysError(!event.target.value)
        return setValues({ ...values, rotation: event.target.value })

      default:
        return
    }
  }

  const handleChange = (name) => (event) => {
    if (name === "is_all_day") {
      return setValues({ ...values, [name]: event.target.checked })
    }

    setValues({ ...values, [name]: event.target.value })
  }

  const processInfoSave = async () => {
    const data = {
      id: activeBlock ? activeBlock : undefined,
      section_id: !activeBlock ? parseInt(params.sectionId, 10) : undefined,
      name: values.name,
      is_all_day: values.is_all_day,
      rotations: [values.rotation],
      time_from: values.is_all_day ? undefined : timeFrom,
      time_to: values.is_all_day ? undefined : timeTo,
      tracks: values.tracks.map((track) => track.id),
    }

    try {
      if (!!activeBlock) {
        await updateBlock.mutateAsync(data)
      } else {
        await createBlock.mutateAsync(data)
      }
      onClose()
    } catch (e) {
      errorAlert(e)
    }
  }

  return (
    <Dialog
      aria-labelledby="subject-form-dialog"
      onClose={onClose}
      open={open}
      maxWidth="md"
    >
      <ValidatorForm onSubmit={processInfoSave}>
        <DialogTitle id="alert-dialog-title" className="text-center">
          {!!activeBlock ? "Edit block" : "Create block"}
        </DialogTitle>
        {(!!activeBlock && isSuccess) || !activeBlock ? (
          <>
            <DialogContent>
              <div className="form form--modal">
                <div className="form__fieldset">
                  <label htmlFor="name" className="form__label">
                    Title *
                  </label>
                  <div className="form__fieldbox">
                    <div className="form__input">
                      <TextValidator
                        id="name"
                        name="name"
                        value={values.name}
                        placeholder="Type block title"
                        className="edit-form__input"
                        margin="normal"
                        fullWidth
                        validators={[
                          "required",
                          "minStringLength:3",
                          "maxStringLength:200",
                        ]}
                        errorMessages={[
                          "Field is required",
                          "Min length is 3 characters",
                          "Max length is 200 characters",
                        ]}
                        withRequiredValidator
                        onChange={handleChange("name")}
                      />
                    </div>
                  </div>
                </div>

                <div className="form__fieldset">
                  <label htmlFor="day" className="form__label">
                    Rotation *
                  </label>
                  <div className="form__fieldbox">
                    <div className="form__input form__input--select">
                      <FormControl error={daysError} fullWidth>
                        <Select
                          id="day"
                          name="day"
                          fullWidth
                          displayEmpty
                          value={values.rotation}
                          onChange={handleChangeRequired("rotation")}
                        >
                          {weekDaysArray.map((item, i) => (
                            <MenuItem value={i} key={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                        {daysError && (
                          <FormHelperText className="error-message">
                            Field is required
                          </FormHelperText>
                        )}
                      </FormControl>
                    </div>
                  </div>
                </div>

                <div className="form__fieldset">
                  <span className="form__label" />
                  <div className="form__fieldbox">
                    <div className="form__input">
                      <FormControl component="fieldset" id="is_all_day">
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={"is_all_day"}
                              checked={values.is_all_day}
                              onChange={handleChange("is_all_day")}
                            />
                          }
                          label={"All day"}
                        />
                      </FormControl>
                    </div>
                  </div>
                </div>

                {!values.is_all_day && (
                  <TimePeriodPicker
                    label={"Duration"}
                    required
                    startTime={timeFrom}
                    endTime={timeTo}
                    onChange={({ start, end }) => {
                      setTimeFrom(start)
                      setTimeTo(end)
                    }}
                  />
                )}

                {tracksListSuccess && (
                  <StaticDataSelect
                    fieldName={"tracks"}
                    entityName={"Tracks"}
                    data={tracksList}
                    value={values.tracks}
                    label="Tracks"
                    onChange={(tracks) => setValues({ ...values, tracks })}
                    multiple
                  />
                )}
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Save
              </Button>
            </DialogActions>
          </>
        ) : (
          <Loader />
        )}
      </ValidatorForm>
    </Dialog>
  )
}

export default BlockFormModal
