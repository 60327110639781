import React from "react"
import VerticalMenu from "../../../../../components/ui/verticalMenu"
import {
  generatePath,
  Link,
  Route,
  Switch,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom"
import * as routes from "../../../../../library/constants/routes"
import { useSelector } from "react-redux"
import GroupSettingsMain from "./groupSettingsMain/groupSettingsMain"
import GroupSettingsKnowmix from "./groupSettingsKnowmix/groupSettingsKnowmix"
import GroupSettingsComments from "./groupSettingsComments/groupSettingsComments"
import GroupSettingsPosts from "./groupSettingsPosts/groupSettingsPosts"
import GroupSettingsProducts from "./groupSettingsProducts/groupSettingsProducts"
import GroupSettingsRelatedGroups from "./groupSettingsRelatedGroups/groupSettingsRelatedGroups"
import GroupSettingsMembersList from "./groupSettingsMembers/groupSettingsMembersList/groupSettingsMembersList"
import GroupSettingsAttachments from "./groupSettingsAttachments"
import GroupSettingsInvenstory from "./groupsSettingsInvenstory"
import GroupSettingsCalendars from "./groupSettingsCalendar"
import GroupSettingsSpaces from "./groupSettingsSpaces/groupSettingsSpaces"
import PrivateSchoolCalendars from "./groupSettingsCalendar/calendarsList/privateSchoolCalendars"
import GroupSettingsPacingCalendars from "./groupSettingsCalendar/pacingCalendars/groupSettingsPacingCalendars"
import withGroupSettingsGuard from "../../../../../hocs/guards/withGroupSettingsGuard"
import { getGroupLogo } from "../../../../../library/utils/getGroupLogo"
import SchoolPacingCalendars from "./groupSettingsCalendar/pacingCalendars/schoolPacingCalendars"
import GroupSettingsSchoolsRostering from "./groupSettingsSchoolsRostering/groupSettingsSchoolsRostering"
import ProductsListFilter from "../../../marketplace/productsList/productsListFilter/productsListFilter"
import GroupSettingsSuperAdmins from "./groupSettingsMembers/groupSettingsSuperAdmins/GroupSettingsSuperAdmins"
import GroupSettingsSubscription from "./groupSettingsSubscription/GroupSettingsSubscription"
import SchoolScheduleTeachers from "./schoolSchedule/SchoolScheduleTeachers"
import SchoolScheduleLearners from "./schoolSchedule/SchoolScheduleLearners"
import SchoolScheduleLearnerSubjectTime from "./schoolSchedule/SchoolScheduleLearnerSubjectTime"

const GroupSettings = () => {
  const { currentGroup } = useSelector(({ groups }) => groups)
  const { userData } = useSelector(({ auth }) => auth)
  const params = useParams()
  const match = useRouteMatch()
  const location = useLocation()

  const hideMainSettingsMenuChildren = ![
    routes.GROUP_SETTINGS,
    routes.GROUP_SETTINGS_COMMENTS,
    routes.GROUP_SETTINGS_POSTS,
    routes.GROUP_SETTINGS_RELATED_GROUPS,
    routes.GROUP_SETTINGS_SUBSCRIPTION,
    // routes.GROUP_SETTINGS_KNOWMIX,
  ].includes(match.path)

  const hideMembersSettingsMenuChildren = ![
    routes.GROUP_SETTINGS_MEMBERS,
    routes.GROUP_SETTINGS_MANAGEMENT,
    routes.GROUP_SETTINGS_REQUESTS,
    routes.GROUP_SETTINGS_INVITE,
    routes.GROUP_SETTINGS_SUPER_ADMINS,
  ].includes(match.path)

  const hideAttachmentSettingsMenuChildren = ![
    routes.GROUP_SETTINGS_ATTACHMENTS,
    routes.GROUP_SETTINGS_ATTACHMENTS_FILES,
    routes.GROUP_SETTINGS_ATTACHMENTS_LINKS,
    routes.GROUP_SETTINGS_ATTACHMENTS_PRODUCTS,
  ].includes(match.path)

  const hideInvenstorySettingsMenuChildren = ![
    routes.GROUP_SETTINGS_INVENSTORY,
    routes.GROUP_SETTINGS_INVENSTORY_OUTGOING,
  ].includes(match.path)

  const hideCalendarSettingsMenuChildren = ![
    routes.GROUP_SETTINGS_DISTRICT_CALENDARS,
    routes.GROUP_SETTINGS_SCHOOL_CALENDARS,
    routes.GROUP_SETTINGS_PACING_CALENDARS,
  ].includes(match.path)

  const hideScheduleSettingsMenuChildren = ![
    routes.SCHOOL_SCHEDULE_TEACHERS,
    routes.SCHOOL_SCHEDULE_LEARNERS,
    routes.SCHOOL_SCHEDULE_SUBJECT_TIME,
  ].includes(match.path)

  let menuItems = [
    {
      path: generatePath(routes.GROUP_SETTINGS, { groupId: params.groupId }),
      type: "parent",
      label: "Settings",
      markAsActive: false,
    },

    {
      path: generatePath(routes.GROUP_SETTINGS, { groupId: params.groupId }),
      type: "child",
      label: "Main information",
      hidden: hideMainSettingsMenuChildren,
    },

    currentGroup.is_creator &&
      [3, 4, 5].includes(currentGroup.type) && {
        path: generatePath(routes.GROUP_SETTINGS_SUBSCRIPTION, {
          groupId: params.groupId,
        }),
        type: "child",
        label: "Subscription",
        hidden: hideMainSettingsMenuChildren,
      },

    {
      path: generatePath(routes.GROUP_SETTINGS_RELATED_GROUPS, {
        groupId: params.groupId,
      }),
      type: "child",
      label: "Related groups",
      hidden: hideMainSettingsMenuChildren,
    },

    {
      path: generatePath(routes.GROUP_SETTINGS_ATTACHMENTS, {
        groupId: params.groupId,
      }),
      type: "parent",
      label: "Attachments",
      markAsActive: false,
    },

    {
      path: generatePath(routes.GROUP_SETTINGS_ATTACHMENTS, {
        groupId: params.groupId,
      }),
      type: "child",
      label: "Links",
      hidden: hideAttachmentSettingsMenuChildren,
    },

    {
      path: generatePath(routes.GROUP_SETTINGS_ATTACHMENTS_FILES, {
        groupId: params.groupId,
      }),
      type: "child",
      label: "Documents",
      hidden: hideAttachmentSettingsMenuChildren,
    },

    {
      path: generatePath(routes.GROUP_SETTINGS_ATTACHMENTS_PRODUCTS, {
        groupId: params.groupId,
      }),
      type: "child",
      label: "Products Used",
      hidden: hideAttachmentSettingsMenuChildren,
    },

    {
      path: generatePath(routes.GROUP_SETTINGS_MEMBERS, {
        groupId: params.groupId,
      }),
      type: "parent",
      label: "Members",
    },

    {
      path: generatePath(routes.GROUP_SETTINGS_MANAGEMENT, {
        groupId: params.groupId,
      }),
      type: "child",
      label: "Management",
      hidden: hideMembersSettingsMenuChildren,
    },
    currentGroup.is_creator &&
      [3, 4, 5].includes(currentGroup.type) && {
        path: generatePath(routes.GROUP_SETTINGS_SUPER_ADMINS, {
          groupId: params.groupId,
        }),
        type: "child",
        label: "Transfer Super Admin Rights",
        hidden: hideMembersSettingsMenuChildren,
      },

    {
      path: generatePath(routes.GROUP_SETTINGS_INVITE, {
        groupId: params.groupId,
      }),
      type: "child",
      label: "Invite members",
      hidden: hideMembersSettingsMenuChildren,
    },

    currentGroup?.closed && {
      path: generatePath(routes.GROUP_SETTINGS_REQUESTS, {
        groupId: params.groupId,
      }),
      type: "child",
      label: "Requests",
      hidden: hideMembersSettingsMenuChildren,
    },

    currentGroup?.type === 5 && {
      path: generatePath(routes.GROUP_SETTINGS_SCHOOLS_ROSTERING, {
        groupId: params.groupId,
      }),
      type: "parent",
      label: "Schools rostering",
    },

    {
      path: generatePath(routes.GROUP_SETTINGS_PRODUCTS, {
        groupId: params.groupId,
      }),
      type: "parent",
      label: "Products",
    },

    [4, 7].includes(currentGroup?.type) && {
      path: generatePath(routes.GROUP_SETTINGS_KNOWMIX, {
        groupId: params.groupId,
      }),
      type: "parent",
      label: "Courses",
    },

    // [4, 5].includes(currentGroup?.type) && {
    //   path: generatePath(routes.GROUP_SETTINGS_INVENSTORY, {
    //     groupId: params.groupId,
    //   }),
    //   type: "parent",
    //   label: "InvenStory",
    //   markAsActive: false,
    // },

    // [4, 5].includes(currentGroup?.type) && {
    //   path:
    //     match.path === routes.GROUP_SETTINGS_INVENSTORY_OUTGOING
    //       ? generatePath(routes.GROUP_SETTINGS_INVENSTORY_OUTGOING, {
    //           groupId: params.groupId,
    //         })
    //       : generatePath(routes.GROUP_SETTINGS_INVENSTORY, {
    //           groupId: params.groupId,
    //         }),
    //   type: "child",
    //   label: "Access requests",
    //   hidden: hideInvenstorySettingsMenuChildren,
    // },

    [4, 7].includes(currentGroup?.type) && {
      path: generatePath(routes.GROUP_SETTINGS_SPACES, {
        groupId: params.groupId,
      }),
      label: "Spaces",
    },

    // [4, 7].includes(currentGroup?.type) && {
    //   path: generatePath(routes.GROUP_SETTINGS_HOLDS, {
    //     groupId: params.groupId,
    //   }),
    //   label: "Holds",
    // },

    [4, 5, 7].includes(currentGroup.type) && {
      path:
        currentGroup.type === 5
          ? generatePath(routes.GROUP_SETTINGS_DISTRICT_CALENDARS, {
              groupId: params.groupId,
            })
          : generatePath(routes.GROUP_SETTINGS_SCHOOL_CALENDARS, {
              groupId: params.groupId,
            }),
      type: "parent",
      label: "Calendar",
    },

    // [4, 5, 7].includes(currentGroup.type) && {
    [4, 5].includes(currentGroup?.type) && {
      path: generatePath(routes.GROUP_SETTINGS_PACING_CALENDARS, {
        groupId: params.groupId,
      }),
      type: "child",
      label: "Pacing Calendar",
      hidden: hideCalendarSettingsMenuChildren,
    },
    currentGroup.type === 4 && {
      path: generatePath(routes.SCHOOL_SCHEDULE_TEACHERS, {
        groupId: params.groupId,
      }),
      type: "parent",
      label: "Schedule",
      markAsActive: false,
    },
    currentGroup.type === 4 && {
      path: generatePath(routes.SCHOOL_SCHEDULE_TEACHERS, {
        groupId: params.groupId,
      }),
      label: "Teacher schedule",
      type: "child",
      hidden: hideScheduleSettingsMenuChildren,
    },
    currentGroup.type === 4 && {
      path: generatePath(routes.SCHOOL_SCHEDULE_LEARNERS, {
        groupId: params.groupId,
      }),
      label: "Learner schedule",
      type: "child",
      hidden: hideScheduleSettingsMenuChildren,
    },
    // currentGroup.type === 4 && {
    //   path: generatePath(routes.SCHOOL_SCHEDULE_SUBJECT_TIME, {
    //     groupId: params.groupId,
    //   }),
    //   label: "Learner subject time",
    //   type: "child",
    //   hidden: hideScheduleSettingsMenuChildren,
    // },
  ].filter(Boolean)

  return (
    <div className="common-page__content">
      <main className="common-page__main">
        <Switch>
          <Route
            exact
            path={routes.GROUP_SETTINGS_MAIN_INFO}
            component={GroupSettingsMain}
          />
          <Route
            exact
            path={routes.GROUP_SETTINGS_SUBSCRIPTION}
            component={GroupSettingsSubscription}
          />
          <Route
            exact
            path={routes.GROUP_SETTINGS_COMMENTS}
            component={GroupSettingsComments}
          />
          <Route
            exact
            path={routes.GROUP_SETTINGS_POSTS}
            component={GroupSettingsPosts}
          />
          <Route
            exact
            path={routes.GROUP_SETTINGS_RELATED_GROUPS}
            component={GroupSettingsRelatedGroups}
          />
          <Route
            exact
            path={routes.GROUP_SETTINGS_KNOWMIX}
            component={GroupSettingsKnowmix}
          />
          <Route
            exact
            path={routes.GROUP_SETTINGS_PRODUCTS}
            component={GroupSettingsProducts}
          />
          <Route
            exact
            path={routes.GROUP_SETTINGS_MEMBERS}
            render={(props) => {
              return <GroupSettingsMembersList type="members" {...props} />
            }}
          />
          <Route
            exact
            path={routes.GROUP_SETTINGS_MANAGEMENT}
            render={(props) => {
              return <GroupSettingsMembersList type="management" {...props} />
            }}
          />
          <Route
            exact
            path={routes.GROUP_SETTINGS_SUPER_ADMINS}
            component={GroupSettingsSuperAdmins}
          />
          {currentGroup?.closed && (
            <Route
              exact
              path={routes.GROUP_SETTINGS_REQUESTS}
              render={(props) => {
                return <GroupSettingsMembersList type="requests" {...props} />
              }}
            />
          )}
          <Route
            exact
            path={routes.GROUP_SETTINGS_INVITE}
            render={(props) => {
              return <GroupSettingsMembersList type="invite" {...props} />
            }}
          />
          <Route
            exact
            path={routes.GROUP_SETTINGS_SCHOOLS_ROSTERING}
            component={GroupSettingsSchoolsRostering}
          />
          <Route
            exact
            path={routes.GROUP_SETTINGS_ATTACHMENTS}
            render={(props) => {
              return <GroupSettingsAttachments type="links" {...props} />
            }}
          />
          <Route
            exact
            path={routes.GROUP_SETTINGS_ATTACHMENTS_LINKS}
            render={(props) => {
              return <GroupSettingsAttachments type="links" {...props} />
            }}
          />
          <Route
            exact
            path={routes.GROUP_SETTINGS_ATTACHMENTS_FILES}
            render={(props) => {
              return <GroupSettingsAttachments type="files" {...props} />
            }}
          />
          <Route
            exact
            path={routes.GROUP_SETTINGS_ATTACHMENTS_PRODUCTS}
            render={(props) => {
              return <GroupSettingsAttachments type="products" {...props} />
            }}
          />
          <Route
            exact
            path={routes.GROUP_SETTINGS_INVENSTORY}
            render={(props) => {
              return <GroupSettingsInvenstory {...props} subTub={1} />
            }}
          />
          <Route
            exact
            path={routes.GROUP_SETTINGS_INVENSTORY_OUTGOING}
            render={(props) => {
              return <GroupSettingsInvenstory {...props} subTub={2} />
            }}
          />
          <Route
            exact
            path={routes.GROUP_SETTINGS_SPACES}
            component={GroupSettingsSpaces}
          />
          <Route
            exact
            path={routes.GROUP_SETTINGS_DISTRICT_CALENDARS}
            render={(props) => {
              return (
                <GroupSettingsCalendars {...props} calendarType="district" />
              )
            }}
          />

          <Route
            exact
            path={routes.GROUP_SETTINGS_PACING_CALENDARS}
            render={(props) => {
              if (currentGroup.type === 4) {
                return <SchoolPacingCalendars {...props} />
              } else {
                return <GroupSettingsPacingCalendars {...props} />
              }
            }}
          />

          <Route
            exact
            path={routes.GROUP_SETTINGS_SCHOOL_CALENDARS}
            render={(props) => {
              if (currentGroup.type === 4) {
                return (
                  <GroupSettingsCalendars {...props} calendarType="school" />
                )
              } else {
                return (
                  <PrivateSchoolCalendars {...props} calendarType="school" />
                )
              }
            }}
          />
          <Route
            exact
            path={routes.SCHOOL_SCHEDULE_TEACHERS}
            component={SchoolScheduleTeachers}
          />
          <Route
            exact
            path={routes.SCHOOL_SCHEDULE_LEARNERS}
            component={SchoolScheduleLearners}
          />
          <Route
            exact
            path={routes.SCHOOL_SCHEDULE_SUBJECT_TIME}
            component={SchoolScheduleLearnerSubjectTime}
          />
        </Switch>
      </main>
      <aside className="common-page__sidebar">
        <div className="box aside-header">
          <Link
            to={generatePath(routes.GROUP, { groupId: params.groupId })}
            className="box__content aside-header__content"
          >
            <img
              src={getGroupLogo(currentGroup)}
              className="aside-header__img"
              alt=""
            />
            <div className="aside-header__info">
              <div className="aside-header__name">{currentGroup?.name}</div>
              <div className="aside-header__help">back to page</div>
            </div>
          </Link>
        </div>
        <VerticalMenu menuItems={menuItems} />

        {match.path === routes.GROUP_SETTINGS_KNOWMIX && (
          <ProductsListFilter
            pathname={location.pathname}
            hiddenFields={[
              "states",
              "standardSubjects",
              "contentTypes",
              "standardsGrades",
              "subjects",
            ]}
            calendarGrades
          />
        )}
        {/*<Ads/>*/}
      </aside>
    </div>
  )
}

export default withGroupSettingsGuard(GroupSettings)
