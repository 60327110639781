import React from "react"
import MainCalendar from "../calendar/subpages/mainCalendar"
import CalendarProvider from "../calendar/CalendarContext"
import { useSelector } from "react-redux"
import { generatePath, Redirect } from "react-router-dom"
import * as routes from "../../../library/constants/routes"

const HomePage = () => {
  const { deleted, blocked, id } = useSelector(({ auth }) => auth.userData)

  return <Redirect to={generatePath(routes.USER, { userId: id })} />

  if (deleted || blocked) {
    return <Redirect to={generatePath(routes.USER, { userId: id })} />
  }

  return (
    <CalendarProvider>
      <MainCalendar />
    </CalendarProvider>
  )
}

export default HomePage
