export const BASE = "/"
export const HOME = "/home"
export const PROMO = "/promo"

export const SIGN_IN = "/sign-in"
export const SIGN_UP = "/sign-up"
export const COMPLETE_SIGNUP = "/complete-signup"
export const CONFIRM_SIGNUP = "/confirmSignUp"
export const DENY_SIGNUP = "/denySignUp"

export const RESET_PASSWORD = "/reset-password"
export const CONFIRM_PASSWORD_RESET = "/confirmPasswordReset"
export const DENY_PASSWORD_RESET = "/denyPasswordReset"

export const CONFIRM_EMAIL_CHANGE = "/confirmEmailChange"
export const DENY_EMAIL_CHANGE = "/denyEmailChange"

export const AZURE_AUTH_SUCCESS = "/microsoft-auth-success"
export const GOOGLE_AUTH_SUCCESS = "/google-auth-success"

export const SEARCH = "/search"
export const SEARCH_USERS = `${SEARCH}/people`
export const SEARCH_GROUPS = `${SEARCH}/groups`
export const SEARCH_GROUPS_INTERESTS = `${SEARCH}/groups/interests`
export const SEARCH_GROUPS_DISTRICTS = `${SEARCH}/groups/districts`
export const SEARCH_GROUPS_SCHOOLS = `${SEARCH}/groups/schools`
export const SEARCH_GROUPS_COMPANIES = `${SEARCH}/groups/companies`
export const SEARCH_GROUPS_EVENTS = `${SEARCH}/groups/events`
export const SEARCH_GROUPS_ONE_TEACHER_SCHOOLS = `${SEARCH}/groups/one-teacher-schools`
export const SEARCH_PRODUCTS = `${SEARCH}/products`

export const USER = "/users/:userId"
export const PROFILE_EDIT = "/profile/edit"
export const PROFILE_EDIT_CONTACTS = "/profile/edit/contacts"
export const PROFILE_EDIT_EDUCATION = "/profile/edit/education"
export const PROFILE_EDIT_WORK = "/profile/edit/work"
export const PROFILE_EDIT_PERSONAL = "/profile/edit/personal"
export const PROFILE_SETTINGS = "/profile/settings"
export const PROFILE_SETTINGS_NOTIFICATIONS = "/profile/settings/notifications"
export const PROFILE_SETTINGS_BLACKLIST = "/profile/settings/blacklist"
export const PROFILE_SETTINGS_VERIFICATION = "/profile/settings/verification"
export const PROFILE_SETTINGS_INTEGRATIONS = "/profile/settings/integrations"
export const PROFILE_SETTINGS_CONFERENCE_LINK =
  "/profile/settings/conference-link"
export const PROFILE_SETTINGS_HOLDS = "/profile/settings/holds"
export const PROFILE_SETTINGS_APPOINTMENT_LINKS =
  "/profile/settings/appointment-links"
export const PROFILE_SETTINGS_APPOINTMENT_TYPES =
  "/profile/settings/appointment-types"
export const PROFILE_SETTINGS_SUBSCRIPTIONS = "/profile/settings/subscriptions"

export const NEWS = "/feed"
export const NEWS_POST = "feed/posts/:postId"

export const USER_POST = `${USER}/posts/:postId`
export const USER_PRODUCTS = `${USER}/products`

export const FRIENDS = `${USER}/friends`
export const FRIENDS_ONLINE = `${USER}/friends/online`
export const FRIENDS_REQUESTS_PENDING = "/friends/requests/pending"
export const FRIENDS_REQUESTS_OUTGOING = "/friends/requests/outgoing"
export const FRIENDS_RECOMMENDED = "/friends/recommended"
export const FRIENDS_SEARCH = "/friends/new"

const knowcredList = `${USER}/creds`
const grantedKnowcredList = `${USER}/granted-creds`
const awardedKnowcredList = `${USER}/awarded-creds`
const validKnowcredList = `${USER}/valid-creds`
const validatedKnowcredList = "/shared-creds"
export const KNOWCRED = `${knowcredList}/all`
export const KNOWCRED_VALIDATED = `${validatedKnowcredList}/all`
export const KNOWCRED_GRANTED = `${grantedKnowcredList}/all`
export const KNOWCRED_AWARDED = `${awardedKnowcredList}/all`
export const KNOWCRED_VALID = `${validKnowcredList}/all`
export const KNOWCRED_SOCIAL = `${knowcredList}/social`
export const KNOWCRED_SOCIAL_VALIDATED = `${validatedKnowcredList}/social`
export const KNOWCRED_SOCIAL_GRANTED = `${grantedKnowcredList}/social`
export const KNOWCRED_EXTRA = `${knowcredList}/extra-curricular`
export const KNOWCRED_EXTRA_VALIDATED = `${validatedKnowcredList}/extra-curricular`
export const KNOWCRED_EXTRA_GRANTED = `${grantedKnowcredList}/extra-curricular`
export const KNOWCRED_EXTRA_AWARDED = `${awardedKnowcredList}/extra-curricular`
export const KNOWCRED_EXTRA_VALID = `${validKnowcredList}/extra-curricular`
export const KNOWCRED_GRADES = `${knowcredList}/grades-marks`
export const KNOWCRED_GRADES_VALIDATED = `${validatedKnowcredList}/grades-marks`
export const KNOWCRED_GRADES_GRANTED = `${grantedKnowcredList}/grades-marks`
export const KNOWCRED_GRADES_AWARDED = `${awardedKnowcredList}/grades-marks`
export const KNOWCRED_GRADES_VALID = `${validKnowcredList}/grades-marks`
export const KNOWCRED_CERTIFICATES = `${knowcredList}/certificates`
export const KNOWCRED_CERTIFICATES_VALIDATED = `${validatedKnowcredList}/certificates`
export const KNOWCRED_CERTIFICATES_GRANTED = `${grantedKnowcredList}/certificates`
export const KNOWCRED_CERTIFICATES_AWARDED = `${awardedKnowcredList}/certificates`
export const KNOWCRED_CERTIFICATES_VALID = `${validKnowcredList}/certificates`
export const KNOWCRED_DIPLOMAS = `${knowcredList}/diplomas-degrees`
export const KNOWCRED_DIPLOMAS_VALIDATED = `${validatedKnowcredList}/diplomas-degrees`
export const KNOWCRED_DIPLOMAS_GRANTED = `${grantedKnowcredList}/diplomas-degrees`
export const KNOWCRED_DIPLOMAS_AWARDED = `${awardedKnowcredList}/diplomas-degrees`
export const KNOWCRED_DIPLOMAS_VALID = `${validKnowcredList}/diplomas-degrees`
export const KNOWCRED_CREATE = "/new-cred"
export const KNOWCRED_SINGLE = "/knowcred/v/:knowCredId"
export const KNOWCRED_EDIT = `${KNOWCRED_SINGLE}/edit`

const groupsList = `${USER}/groups`
const managedGroupsList = "/groups/managed"
export const GROUPS = `${groupsList}/all`
export const GROUPS_MANAGED = `${managedGroupsList}/all`
export const GROUPS_INTERESTS = `${groupsList}/interests`
export const GROUPS_INTERESTS_MANAGED = `${managedGroupsList}/interests`
export const GROUPS_DISTRICTS = `${groupsList}/districts`
export const GROUPS_DISTRICTS_MANAGED = `${managedGroupsList}/districts`
export const GROUPS_SCHOOLS = `${groupsList}/schools`
export const GROUPS_SCHOOLS_MANAGED = `${managedGroupsList}/schools`
export const GROUPS_COMPANIES = `${groupsList}/companies`
export const GROUPS_COMPANIES_MANAGED = `${managedGroupsList}/companies`
export const GROUPS_EVENTS = `${groupsList}/events`
export const GROUPS_EVENTS_MANAGED = `${managedGroupsList}/events`
export const GROUPS_ONE_TEACHER_SCHOOLS = `${groupsList}/one-teacher-schools`
export const GROUPS_ONE_TEACHER_SCHOOLS_MANAGED = `${managedGroupsList}/one-teacher-schools`
export const GROUPS_REQUESTS_PENDING = `/groups/requests/pending`
export const GROUPS_REQUESTS_OUTGOING = `/groups/requests/outgoing`
export const GROUPS_FAVORITE = `${groupsList}/favorite`
export const GROUPS_RECOMMENDED = `${groupsList}/recommended`
export const GROUPS_SEARCH = `${groupsList}/search`
export const GROUPS_SEARCH_INTERESTS = `${GROUPS_SEARCH}/interests`
export const GROUPS_SEARCH_EVENTS = `${GROUPS_SEARCH}/events`
export const GROUPS_SEARCH_COMPANIES = `${GROUPS_SEARCH}/companies`
export const GROUPS_SEARCH_SCHOOLS = `${GROUPS_SEARCH}/schools`
export const GROUPS_SEARCH_DISTRICTS = `${GROUPS_SEARCH}/districts`
export const GROUPS_SEARCH_ONE_TEACHER_SCHOOLS = `${GROUPS_SEARCH}/one-teacher-schools`

export const GROUP = `/groups/:groupId`
export const GROUP_PRODUCTS = `${GROUP}/products`
export const GROUP_ATTACHMENTS = `${GROUP}/attachments`
export const GROUP_ATTACHMENTS_LINKS = `${GROUP_ATTACHMENTS}/links`
export const GROUP_ATTACHMENTS_FILES = `${GROUP_ATTACHMENTS}/documents`
export const GROUP_ATTACHMENTS_PRODUCTS = `${GROUP_ATTACHMENTS}/products`
export const GROUP_SETTINGS = `${GROUP}/settings`
export const GROUP_SETTINGS_MAIN_INFO = `${GROUP}/settings`
export const GROUP_SETTINGS_ATTACHMENTS = `${GROUP_SETTINGS}/attachments`
export const GROUP_SETTINGS_INVENSTORY = `${GROUP_SETTINGS}/invenstory`
export const GROUP_SETTINGS_INVENSTORY_OUTGOING = `${GROUP_SETTINGS}/invenstory/outgoing`
export const GROUP_SETTINGS_COMMENTS = `${GROUP}/settings/comments`
export const GROUP_SETTINGS_POSTS = `${GROUP}/settings/posts`
export const GROUP_SETTINGS_PRODUCTS = `${GROUP}/settings/products`
export const GROUP_SETTINGS_RELATED_GROUPS = `${GROUP}/settings/related-groups`
export const GROUP_SETTINGS_KNOWMIX = `${GROUP}/settings/courses`
export const GROUP_SETTINGS_MEMBERS = `${GROUP}/settings/members`
export const GROUP_SETTINGS_MANAGEMENT = `${GROUP_SETTINGS_MEMBERS}/management`
export const GROUP_SETTINGS_SUPER_ADMINS = `${GROUP_SETTINGS_MEMBERS}/super-admins`
export const GROUP_SETTINGS_REQUESTS = `${GROUP_SETTINGS_MEMBERS}/requests`
export const GROUP_SETTINGS_INVITE = `${GROUP_SETTINGS_MEMBERS}/invite`
export const GROUP_SETTINGS_SCHOOLS_ROSTERING = `${GROUP_SETTINGS}/schools-rostering`
export const GROUP_SETTINGS_ATTACHMENTS_LINKS = `${GROUP_SETTINGS_ATTACHMENTS}/links`
export const GROUP_SETTINGS_ATTACHMENTS_FILES = `${GROUP_SETTINGS_ATTACHMENTS}/documents`
export const GROUP_SETTINGS_ATTACHMENTS_PRODUCTS = `${GROUP_SETTINGS_ATTACHMENTS}/products`
export const GROUP_SETTINGS_SPACES = `${GROUP_SETTINGS}/spaces`
export const GROUP_SETTINGS_HOLDS = `${GROUP_SETTINGS}/holds`

export const GROUP_SETTINGS_KOURSES = `${GROUP_SETTINGS}/kourses`

export const GROUP_SETTINGS_DISTRICT_CALENDARS = `${GROUP_SETTINGS}/district-calendars`
export const GROUP_SETTINGS_DISTRICT_PACING_CALENDARS = `${GROUP_SETTINGS}/pacing-calendars`
export const GROUP_SETTINGS_SCHOOL_CALENDARS = `${GROUP_SETTINGS}/school-calendars`
export const GROUP_SETTINGS_PACING_CALENDARS = `${GROUP_SETTINGS}/pacing-calendars`
export const GROUP_SETTINGS_PACING_CALENDAR = `${GROUP_SETTINGS_PACING_CALENDARS}/:subjectId`
export const GROUP_SETTINGS_PACING_CALENDAR_UNITS = `${GROUP_SETTINGS_PACING_CALENDAR}/units`
export const GROUP_SETTINGS_PACING_CALENDAR_UNITS_CALENDAR = `${GROUP_SETTINGS_PACING_CALENDAR}/units-calendar`
export const GROUP_SETTINGS_DISTRICT_CALENDAR = `${GROUP_SETTINGS_DISTRICT_CALENDARS}/:calendarId`
export const GROUP_SETTINGS_SCHOOL_CALENDAR = `${GROUP_SETTINGS_SCHOOL_CALENDARS}/:calendarId`
export const GROUP_SETTINGS_DISTRICT_CALENDAR_SUBJECTS = `${GROUP_SETTINGS_DISTRICT_CALENDAR}/subjects`
export const GROUP_SETTINGS_SCHOOL_CALENDAR_SUBJECTS = `${GROUP_SETTINGS_SCHOOL_CALENDAR}/subjects`
export const GROUP_SETTINGS_DISTRICT_CALENDAR_SUBJECT = `${GROUP_SETTINGS_DISTRICT_CALENDAR_SUBJECTS}/:subjectId`
export const GROUP_SETTINGS_SCHOOL_CALENDAR_SUBJECT = `${GROUP_SETTINGS_SCHOOL_CALENDAR_SUBJECTS}/:subjectId`
export const GROUP_SETTINGS_SCHOOL_CALENDAR_UNITS = `${GROUP_SETTINGS_SCHOOL_CALENDAR_SUBJECT}/units`
export const GROUP_SETTINGS_DISTRICT_CALENDAR_UNITS = `${GROUP_SETTINGS_DISTRICT_CALENDAR_SUBJECT}/units`

export const GROUP_SETTINGS_CALENDAR_KOURSES = `${GROUP_SETTINGS_SCHOOL_CALENDAR}/kourses`
export const GROUP_SETTINGS_CALENDAR_KOURSE = `${GROUP_SETTINGS_CALENDAR_KOURSES}/:classId`
export const GROUP_SETTINGS_CALENDAR_KOURSE_UKNOW = `${GROUP_SETTINGS_CALENDAR_KOURSE}/knowmeets`

export const GROUP_SETTINGS_SUBSCRIPTION = `${GROUP_SETTINGS}/subscription`

export const GROUP_SETTINGS_CALENDAR_GRADES = `${GROUP_SETTINGS_SCHOOL_CALENDAR}/grades`
export const GROUP_SETTINGS_CALENDAR_GRADE = `${GROUP_SETTINGS_CALENDAR_GRADES}/:gradeId`
export const GROUP_SETTINGS_CALENDAR_GRADE_MEMBERS = `${GROUP_SETTINGS_CALENDAR_GRADE}/members`
export const GROUP_SETTINGS_CALENDAR_GRADE_INVITES = `${GROUP_SETTINGS_CALENDAR_GRADE}/invites`

export const GROUP_SETTINGS_SCHOOL_CALENDAR_SEGMENTS = `${GROUP_SETTINGS_SCHOOL_CALENDAR}/segments`
export const GROUP_SETTINGS_SCHOOL_CALENDAR_SEGMENT = `${GROUP_SETTINGS_SCHOOL_CALENDAR_SEGMENTS}/:segmentId`
export const GROUP_SETTINGS_SCHOOL_CALENDAR_SEGMENT_SECTIONS = `${GROUP_SETTINGS_SCHOOL_CALENDAR_SEGMENT}/sections`
export const GROUP_SETTINGS_SCHOOL_CALENDAR_SEGMENT_SECTION = `${GROUP_SETTINGS_SCHOOL_CALENDAR_SEGMENT_SECTIONS}/:sectionId`
export const GROUP_SETTINGS_SCHOOL_CALENDAR_SEGMENT_SECTION_ROTATIONS = `${GROUP_SETTINGS_SCHOOL_CALENDAR_SEGMENT_SECTION}/rotations`
export const GROUP_SETTINGS_SCHOOL_CALENDAR_SEGMENT_SECTION_BLOCKS = `${GROUP_SETTINGS_SCHOOL_CALENDAR_SEGMENT_SECTION}/blocks`
export const GROUP_SETTINGS_SCHOOL_CALENDAR_SEGMENT_SECTION_TRACKS = `${GROUP_SETTINGS_SCHOOL_CALENDAR_SEGMENT_SECTION}/tracks`

export const GROUP_SETTINGS_SCHOOL_CALENDAR_SCHEDULE = `${GROUP_SETTINGS_SCHOOL_CALENDAR}/schedule`
export const SCHOOL_SCHEDULE_TEACHERS = `${GROUP_SETTINGS}/teachers-schedule-assignment`
export const SCHOOL_SCHEDULE_LEARNERS = `${GROUP_SETTINGS}/learners-schedule-assignment`
export const SCHOOL_SCHEDULE_SUBJECT_TIME = `${GROUP_SETTINGS}/learner-subject-time`

export const GROUP_MEMBERS = `${GROUP}/members`
export const GROUP_RELATED_GROUPS = `${GROUP}/related-groups`
export const GROUP_RELATED_SCHOOLS = `${GROUP}/related-schools`
export const GROUP_TEACHERS = `${GROUP}/teachers`
export const GROUP_CALENDARS = `${GROUP}/calendars`
export const GROUP_CLASSES = `${GROUP}/classes`
export const GROUP_CLASS = `${GROUP_CLASSES}/:classId`

export const GROUP_SETTINGS_SCHOOL_CALENDAR_EVENTS = `${GROUP_SETTINGS_SCHOOL_CALENDAR}/events`
export const GROUP_SETTINGS_DISTRICT_CALENDAR_EVENTS = `${GROUP_SETTINGS_DISTRICT_CALENDAR}/events`

export const GROUP_POST = `${GROUP}/posts/:postId`
export const GROUP_PUBLIC_CALENDAR = `${GROUP}/Calendar`

export const CONVERSATIONS = "/chats/all"
export const CONVERSATIONS_NEW = "/chats/new"
export const CONVERSATIONS_UNREAD = "/chats/unread"
export const CONVERSATIONS_FAVORITE = "/chats/favorite-conversations"
export const CONVERSATION = "/chat/"

export const MARKETPLACE = "/marketplace"
export const MARKETPLACE_FAVORITES = `${MARKETPLACE}/favorite-products`
export const MARKETPLACE_PURCHASE_HISTORY = `${MARKETPLACE}/purchase-history`
export const MARKETPLACE_MY_PRODUCTS = `${MARKETPLACE}/my-products`
export const PRODUCT = "/products/:productId"
export const PRODUCT_SETTINGS = `${PRODUCT}/settings`
export const PRODUCT_SETTINGS_MAIN_INFO = `${PRODUCT_SETTINGS}`
export const PRODUCT_SETTINGS_ATTACHMENTS = `${PRODUCT_SETTINGS}/attachments`
export const PRODUCT_SETTINGS_USAGE_DETAILS = `${PRODUCT_SETTINGS}/usage-details`
export const PRODUCT_SETTINGS_ADDITIONAL_INFO = `${PRODUCT_SETTINGS}/additional-info`
export const PRODUCT_SETTINGS_MARKET_SPECIFICS = `${PRODUCT_SETTINGS}/market-specifics`
export const PRODUCT_SETTINGS_REVIEWER_INFO = `${PRODUCT_SETTINGS}/reviewer-info`
export const PRODUCT_SETTINGS_SALES = `${PRODUCT_SETTINGS}/sales`
export const PRODUCT_SETTINGS_DEMO_REQUESTS = `${PRODUCT_SETTINGS}/demo-requests`

export const STANDARDS = "/standards"
export const STANDARD = `${STANDARDS}/:standardId`

export const BOOKMARKS = "/bookmarks"
export const BOOKMARKS_PEOPLE = `${BOOKMARKS}/people`
export const BOOKMARKS_GROUPS = `${BOOKMARKS}/groups`
export const BOOKMARKS_POSTS = `${BOOKMARKS}/posts`
export const BOOKMARKS_KNOWCRED = `${BOOKMARKS}/knowcred`
export const BOOKMARKS_PRODUCTS = `${BOOKMARKS}/products`
export const BOOKMARKS_STANDARDS = `${BOOKMARKS}/standards`
export const BOOKMARKS_KNOWMIX = `${BOOKMARKS}/courses`
export const BOOKMARKS_RECORDS = `${BOOKMARKS}/records`
export const FOLDER = "/folder/:folderId"
export const FOLDER_PEOPLE = `${FOLDER}/people`
export const FOLDER_GROUPS = `${FOLDER}/groups`
export const FOLDER_POSTS = `${FOLDER}/posts`
export const FOLDER_KNOWCRED = `${FOLDER}/knowcred`
export const FOLDER_PRODUCTS = `${FOLDER}/products`
export const FOLDER_RECORDS = `${FOLDER}/records`
export const FOLDER_STANDARDS = `${FOLDER}/standards`

export const FEED = "/feed"

export const KNOWMIX_LIST = "/courses"
export const KNOWMIX_LIST_MANAGED = `${KNOWMIX_LIST}/managed`
export const KNOWMIX_LIST_STARTED = `${KNOWMIX_LIST}/started`
export const KNOWMIX_LIST_STARTED_MANAGED = `${KNOWMIX_LIST}/started/managed`
export const KNOWMIX_LIST_NOT_STARTED = `${KNOWMIX_LIST}/not-started`
export const KNOWMIX_LIST_NOT_STARTED_MANAGED = `${KNOWMIX_LIST}/not-started/managed`
export const KNOWMIX_LIST_FINISHED = `${KNOWMIX_LIST}/finished`
export const KNOWMIX_LIST_FINISHED_MANAGED = `${KNOWMIX_LIST}/finished/managed`
export const KNOWMIX_LIST_MY_GRADES = `${KNOWMIX_LIST}/my-grades`
export const KNOWMIX_LIST_MY_GRADES_STARTED = `${KNOWMIX_LIST}/my-grades/started`
export const KNOWMIX_LIST_MY_GRADES_FINISHED = `${KNOWMIX_LIST}/my-grades/finished`
export const SUBMITTED_WORKS = `${KNOWMIX_LIST}/submitted-works`
export const SUBMITTED_WORKS_EXAMINED = `${KNOWMIX_LIST}/submitted-works/examined`
export const SUBMITTED_WORKS_STEPS = `${KNOWMIX_LIST}/submitted-works/steps`
export const SUBMITTED_WORKS_STEPS_EXAMINED = `${KNOWMIX_LIST}/submitted-works/steps/examined`
export const SUBMITTED_WORKS_KNOWMIX = `${KNOWMIX_LIST}/submitted-works/courses`
export const SUBMITTED_WORKS_KNOWMIX_EXAMINED = `${KNOWMIX_LIST}/submitted-works/courses/examined`
export const SUBMITTED_WORK = `${SUBMITTED_WORKS}/:workId`
export const KNOWMIX = "/course/:knowmixId"
export const KNOWMIX_SETTINGS = `${KNOWMIX}/settings`
export const KNOWMIX_SETTINGS_MAIN_INFO = `${KNOWMIX_SETTINGS}`
export const KNOWMIX_SETTINGS_STEPS = `${KNOWMIX_SETTINGS}/steps`
export const KNOWMIX_SETTINGS_UKNOWS = `${KNOWMIX_SETTINGS}/knowmeets`
export const KNOWMIX_SETTINGS_STEP_UKNOWS = `${KNOWMIX_SETTINGS}/knowmeets/steps`
export const KNOWMIX_SETTINGS_EXAMINERS = `${KNOWMIX_SETTINGS}/examiners`
export const KNOWMIX_SETTINGS_MEMBERS = `${KNOWMIX_SETTINGS}/members`
export const KNOWMIX_SETTINGS_ADD_MEMBERS = `${KNOWMIX_SETTINGS_MEMBERS}/add`
export const KNOWMIX_SETTINGS_PUBLIC = `${KNOWMIX_SETTINGS}/public`
export const KNOWMIX_QUIZ = `${KNOWMIX}/quiz/:quizId`
export const KNOWMIX_QUIZ_REVIEW = `${KNOWMIX_QUIZ}/review`
export const KNOWMIX_GRADES = `${KNOWMIX}/grades`
export const KNOWMIX_GRADES_CHECK = `${KNOWMIX}/grades/check/:learnerId`
export const KNOWMIX_CREATE_KNOWCRED = `${KNOWMIX}/create-cred`
export const KNOWMIX_WALKTHROUGH = `${KNOWMIX}/step/:stepId`
export const KNOWMIX_CHECK = `${KNOWMIX}/check/:checkId`

export const INVENSTORY = "/invenstory"
export const INVENSTORY_GROUPS = `${INVENSTORY}/groups`
export const INVENSTORY_ACCESS = `${INVENSTORY}/requests`

export const CALENDAR = "/Calendar"
export const CALENDAR_KOURSES = `${CALENDAR}/kourses`
export const CALENDAR_BLOCKS = `${CALENDAR}/blocks`
export const CALENDAR_HOLDS = `${CALENDAR}/holds`
export const CALENDAR_TEACHER_SCHEDULE = `${CALENDAR}/teacher-schedule`

export const KNOWMAP = `/tracking`
export const KNOWMAP_UKNOW = `${KNOWMAP}/knowmeets`
export const KNOWMAP_UKNOW_STEPS = `${KNOWMAP}/steps-knowmeets`
export const KNOWMAP_UKNOW_KNOWMIX = `${KNOWMAP}/courses-knowmeets`
export const KNOWMAP_INCOMING_KNOWMEET = `${KNOWMAP_UKNOW_KNOWMIX}/:knowmeetId`
export const KNOWMAP_KNOWMIX = `${KNOWMAP}/courses`
export const KNOWMAP_KNOWMIX_STEPS = `${KNOWMAP}/course-steps`
export const KNOWMAP_KNOWCRED = `${KNOWMAP}/knowcred`
export const KNOWMAP_KNOWCRED_VALID = `${KNOWMAP_KNOWCRED}/valid`
export const KNOWMAP_KNOWCRED_AWARDED = `${KNOWMAP_KNOWCRED}/awarded`

export const KNOWMAP_MEETS = `${KNOWMAP}/managed-meets`
export const KNOWMAP_INCOMING_MEETS = `${KNOWMAP}/incoming-meets`
export const KNOWMAP_INCOMING_MEET = `${KNOWMAP_INCOMING_MEETS}/:meetId`
export const KNOWMAP_INCOMING_APPOINTMENT_BOOKINGS = `${KNOWMAP}/incoming-appointment-bookings`
export const KNOWMAP_OUTGOING_APPOINTMENT_BOOKINGS = `${KNOWMAP}/outgoing-appointment-bookings`

export const TRACKING_COURSES = `${KNOWMAP}/courses`
export const TRACKING_COURSES_MY_PROGRESS = `${TRACKING_COURSES}/my-progress`
export const TRACKING_COURSES_MY_GRADES = `${TRACKING_COURSES}/my-grades`
export const TRACKING_COURSES_SUBMITTED_WORKS = `${TRACKING_COURSES}/submitted-works`
export const TRACKING_COURSES_MANAGED = `${TRACKING_COURSES}/managed`
export const TRACKING_COURSES_STUDENT_PROGRESS = `${TRACKING_COURSES}/student-progress`
export const TRACKING_COURSES_GRADING = `${TRACKING_COURSES}/grading`
export const TRACKING_COURSES_COURSE_GRADING = `${TRACKING_COURSES}/course-grading`
export const TRACKING_COURSES_STEPS_GRADING = `${TRACKING_COURSES}/steps-grading`
export const TRACKING_COURSES_CREDS = `${TRACKING_COURSES}/creds`

export const NOTIFICATIONS = `/notifications`

export const TERMS_AND_CONDITIONS = "/terms-and-conditions"
export const CONTACT_FORM = "/contact-us"
export const PRICING = "/pricing"
export const APP_PROMOTE = "/app"
// export const ABOUT_US = "/about-us"
// export const PROMOTE = "/info-old"
// export const UKNOW_INFO = "/info"
export const ACCOUNTS_INFO = "/accounts-info"
export const ACCOUNTS_INFO_LEARNER = `/learner-account-info`
export const ACCOUNTS_INFO_EDUCATOR = `/educator-account-info`
export const ACCOUNTS_INFO_AGENT = `/company-representative-account-info`
export const ACCOUNTS_INFO_ORGANIZATION = `/school-and-district-account-info`
export const TEACHER_INTRO = "/teacher-intro"

export const HOW_TO = `/how-to`
export const HOW_TO_CATEGORY = `${HOW_TO}/:categoryId`
export const HOW_TO_ARTICLE = `${HOW_TO_CATEGORY}/:articleId`

export const BOOK_MEET = "/book-meet/:userId"
export const BOOK_MEET_AUTHORIZED = `${USER}/book-meet/`
export const BOOK_MEET_UNAUTHORIZED = "/book-meet-as-guest/:userId"

export const INCOMING_SOLICIT_APPOINTMENT = "/request-appointments/:requestId"
export const INCOMING_SOLICIT_APPOINTMENT_UNAUTHORIZED = `${INCOMING_SOLICIT_APPOINTMENT}/guest`
export const INCOMING_SOLICIT_APPOINTMENT_AUTHORIZED = `${INCOMING_SOLICIT_APPOINTMENT}/member`

export const blankPageRoutes = [
  CONFIRM_SIGNUP,
  DENY_SIGNUP,
  DENY_PASSWORD_RESET,
  CONFIRM_EMAIL_CHANGE,
  DENY_EMAIL_CHANGE,
  AZURE_AUTH_SUCCESS,
  GOOGLE_AUTH_SUCCESS,
  BOOK_MEET,
  INCOMING_SOLICIT_APPOINTMENT,
]

export const searchRoutes = [
  SEARCH,
  SEARCH_USERS,
  SEARCH_GROUPS,
  SEARCH_GROUPS_INTERESTS,
  SEARCH_GROUPS_DISTRICTS,
  SEARCH_GROUPS_SCHOOLS,
  SEARCH_GROUPS_COMPANIES,
  SEARCH_GROUPS_EVENTS,
  SEARCH_GROUPS_ONE_TEACHER_SCHOOLS,
  // SEARCH_PRODUCTS,
]

export const userRoutes = [
  USER,
  PROFILE_EDIT,
  PROFILE_EDIT_CONTACTS,
  PROFILE_EDIT_EDUCATION,
  PROFILE_EDIT_WORK,
  PROFILE_EDIT_PERSONAL,
  PROFILE_SETTINGS,
  PROFILE_SETTINGS_NOTIFICATIONS,
  PROFILE_SETTINGS_BLACKLIST,
  PROFILE_SETTINGS_VERIFICATION,
  USER_POST,
  // USER_PRODUCTS,
  PROFILE_SETTINGS_INTEGRATIONS,
  PROFILE_SETTINGS_CONFERENCE_LINK,
  PROFILE_SETTINGS_HOLDS,
  PROFILE_SETTINGS_APPOINTMENT_TYPES,
  PROFILE_SETTINGS_APPOINTMENT_LINKS,
  // BOOK_MEET_AUTHORIZED,
  PROFILE_SETTINGS_SUBSCRIPTIONS,
]

export const newsRoutes = [
  NEWS,
  // NEWS_POST,
  FEED,
]

export const friendsRoutes = [
  FRIENDS,
  FRIENDS_ONLINE,
  FRIENDS_REQUESTS_PENDING,
  FRIENDS_REQUESTS_OUTGOING,
  FRIENDS_RECOMMENDED,
  FRIENDS_SEARCH,
]

export const knowcredRoutes = [
  KNOWCRED,
  KNOWCRED_VALIDATED,
  KNOWCRED_GRANTED,
  KNOWCRED_AWARDED,
  KNOWCRED_VALID,
  KNOWCRED_SOCIAL,
  KNOWCRED_SOCIAL_VALIDATED,
  KNOWCRED_SOCIAL_GRANTED,
  KNOWCRED_EXTRA,
  KNOWCRED_EXTRA_VALIDATED,
  KNOWCRED_EXTRA_GRANTED,
  KNOWCRED_EXTRA_AWARDED,
  KNOWCRED_EXTRA_VALID,
  KNOWCRED_GRADES,
  KNOWCRED_GRADES_VALIDATED,
  KNOWCRED_GRADES_GRANTED,
  KNOWCRED_GRADES_AWARDED,
  KNOWCRED_GRADES_VALID,
  KNOWCRED_CERTIFICATES,
  KNOWCRED_CERTIFICATES_VALIDATED,
  KNOWCRED_CERTIFICATES_GRANTED,
  KNOWCRED_CERTIFICATES_AWARDED,
  KNOWCRED_CERTIFICATES_VALID,
  KNOWCRED_DIPLOMAS,
  KNOWCRED_DIPLOMAS_VALIDATED,
  KNOWCRED_DIPLOMAS_GRANTED,
  KNOWCRED_DIPLOMAS_AWARDED,
  KNOWCRED_DIPLOMAS_VALID,
  KNOWCRED_CREATE,
  KNOWCRED_SINGLE,
  KNOWCRED_EDIT,
]

export const groupsRoutes = [
  GROUPS,
  GROUPS_MANAGED,
  GROUPS_INTERESTS,
  GROUPS_INTERESTS_MANAGED,
  GROUPS_DISTRICTS,
  GROUPS_DISTRICTS_MANAGED,
  GROUPS_SCHOOLS,
  GROUPS_SCHOOLS_MANAGED,
  GROUPS_ONE_TEACHER_SCHOOLS,
  GROUPS_ONE_TEACHER_SCHOOLS_MANAGED,
  GROUPS_COMPANIES,
  GROUPS_COMPANIES_MANAGED,
  GROUPS_EVENTS,
  GROUPS_EVENTS_MANAGED,
  GROUPS_REQUESTS_PENDING,
  GROUPS_REQUESTS_OUTGOING,
  GROUPS_FAVORITE,
  GROUPS_RECOMMENDED,
  GROUPS_SEARCH,
  GROUPS_SEARCH_INTERESTS,
  GROUPS_SEARCH_EVENTS,
  GROUPS_SEARCH_COMPANIES,
  GROUPS_SEARCH_SCHOOLS,
  GROUPS_SEARCH_DISTRICTS,
  GROUPS_SEARCH_ONE_TEACHER_SCHOOLS,
  GROUP,
  GROUP_PRODUCTS,
  GROUP_ATTACHMENTS,
  GROUP_ATTACHMENTS_LINKS,
  GROUP_ATTACHMENTS_FILES,
  GROUP_ATTACHMENTS_PRODUCTS,
  GROUP_SETTINGS,
  GROUP_SETTINGS_MAIN_INFO,
  GROUP_SETTINGS_ATTACHMENTS,
  GROUP_SETTINGS_INVENSTORY,
  GROUP_SETTINGS_INVENSTORY_OUTGOING,
  GROUP_SETTINGS_COMMENTS,
  GROUP_SETTINGS_POSTS,
  GROUP_SETTINGS_PRODUCTS,
  GROUP_SETTINGS_RELATED_GROUPS,
  GROUP_SETTINGS_KNOWMIX,
  GROUP_SETTINGS_MEMBERS,
  GROUP_SETTINGS_MANAGEMENT,
  GROUP_SETTINGS_SUPER_ADMINS,
  GROUP_SETTINGS_REQUESTS,
  GROUP_SETTINGS_INVITE,
  GROUP_SETTINGS_SCHOOLS_ROSTERING,
  GROUP_SETTINGS_ATTACHMENTS_LINKS,
  GROUP_SETTINGS_ATTACHMENTS_FILES,
  GROUP_SETTINGS_ATTACHMENTS_PRODUCTS,
  GROUP_SETTINGS_SPACES,
  GROUP_SETTINGS_HOLDS,
  GROUP_SETTINGS_DISTRICT_CALENDARS,
  GROUP_SETTINGS_DISTRICT_PACING_CALENDARS,
  GROUP_SETTINGS_SCHOOL_CALENDARS,
  GROUP_SETTINGS_PACING_CALENDARS,
  GROUP_SETTINGS_PACING_CALENDAR,
  GROUP_SETTINGS_PACING_CALENDAR_UNITS,
  GROUP_SETTINGS_PACING_CALENDAR_UNITS_CALENDAR,
  GROUP_SETTINGS_DISTRICT_CALENDAR_SUBJECTS,
  GROUP_SETTINGS_SCHOOL_CALENDAR_SUBJECTS,
  GROUP_SETTINGS_DISTRICT_CALENDAR_SUBJECT,
  GROUP_SETTINGS_SCHOOL_CALENDAR_SUBJECT,
  GROUP_SETTINGS_SCHOOL_CALENDAR_UNITS,
  GROUP_SETTINGS_DISTRICT_CALENDAR_UNITS,
  GROUP_SETTINGS_CALENDAR_GRADES,
  GROUP_SETTINGS_CALENDAR_GRADE,
  GROUP_SETTINGS_CALENDAR_GRADE_MEMBERS,
  GROUP_SETTINGS_CALENDAR_GRADE_INVITES,
  GROUP_SETTINGS_SCHOOL_CALENDAR_SEGMENTS,
  GROUP_SETTINGS_SCHOOL_CALENDAR_SEGMENT_SECTIONS,
  GROUP_SETTINGS_SCHOOL_CALENDAR_SEGMENT_SECTION_ROTATIONS,
  GROUP_SETTINGS_SCHOOL_CALENDAR_SEGMENT_SECTION_BLOCKS,
  GROUP_SETTINGS_SCHOOL_CALENDAR_SEGMENT_SECTION_TRACKS,
  GROUP_SETTINGS_SCHOOL_CALENDAR_SCHEDULE,
  GROUP_MEMBERS,
  GROUP_RELATED_GROUPS,
  GROUP_RELATED_SCHOOLS,
  GROUP_TEACHERS,
  GROUP_CALENDARS,
  GROUP_CLASSES,
  GROUP_CLASS,
  GROUP_SETTINGS_SCHOOL_CALENDAR_EVENTS,
  GROUP_SETTINGS_DISTRICT_CALENDAR_EVENTS,
  GROUP_POST,
  GROUP_PUBLIC_CALENDAR,
  GROUP_SETTINGS_SUBSCRIPTION,
  SCHOOL_SCHEDULE_TEACHERS,
  SCHOOL_SCHEDULE_LEARNERS,
  SCHOOL_SCHEDULE_SUBJECT_TIME,
]

export const chatsRoutes = [
  CONVERSATIONS,
  CONVERSATIONS_NEW,
  CONVERSATIONS_UNREAD,
  CONVERSATIONS_FAVORITE,
  CONVERSATION,
]

export const marketplaceRoutes = [
  MARKETPLACE,
  MARKETPLACE_FAVORITES,
  MARKETPLACE_PURCHASE_HISTORY,
  MARKETPLACE_MY_PRODUCTS,
  PRODUCT,
  PRODUCT_SETTINGS,
  PRODUCT_SETTINGS_MAIN_INFO,
  PRODUCT_SETTINGS_ATTACHMENTS,
  PRODUCT_SETTINGS_USAGE_DETAILS,
  PRODUCT_SETTINGS_ADDITIONAL_INFO,
  PRODUCT_SETTINGS_MARKET_SPECIFICS,
  PRODUCT_SETTINGS_REVIEWER_INFO,
  PRODUCT_SETTINGS_SALES,
  PRODUCT_SETTINGS_DEMO_REQUESTS,
]

export const bookmarksRoutes = [
  BOOKMARKS,
  BOOKMARKS_PEOPLE,
  BOOKMARKS_GROUPS,
  BOOKMARKS_POSTS,
  BOOKMARKS_KNOWCRED,
  // BOOKMARKS_PRODUCTS,
  BOOKMARKS_STANDARDS,
  BOOKMARKS_RECORDS,
  FOLDER,
  FOLDER_PEOPLE,
  FOLDER_GROUPS,
  FOLDER_POSTS,
  FOLDER_KNOWCRED,
  // FOLDER_PRODUCTS,
  FOLDER_RECORDS,
  FOLDER_STANDARDS,
]

export const standardsRoutes = [STANDARDS, STANDARD]

export const notificationsRoutes = [NOTIFICATIONS]

export const knowmixRoutes = [
  KNOWMIX_LIST,
  KNOWMIX_LIST_MANAGED,
  KNOWMIX_LIST_STARTED,
  KNOWMIX_LIST_STARTED_MANAGED,
  KNOWMIX_LIST_NOT_STARTED,
  KNOWMIX_LIST_NOT_STARTED_MANAGED,
  KNOWMIX_LIST_FINISHED,
  KNOWMIX_LIST_FINISHED_MANAGED,
  KNOWMIX_LIST_MY_GRADES,
  KNOWMIX_LIST_MY_GRADES_STARTED,
  KNOWMIX_LIST_MY_GRADES_FINISHED,
  SUBMITTED_WORKS,
  SUBMITTED_WORKS_EXAMINED,
  SUBMITTED_WORKS_STEPS,
  SUBMITTED_WORKS_STEPS_EXAMINED,
  SUBMITTED_WORKS_KNOWMIX,
  SUBMITTED_WORKS_KNOWMIX_EXAMINED,
  SUBMITTED_WORK,
  KNOWMIX,
  KNOWMIX_SETTINGS,
  KNOWMIX_SETTINGS_MAIN_INFO,
  KNOWMIX_SETTINGS_STEPS,
  KNOWMIX_SETTINGS_UKNOWS,
  KNOWMIX_SETTINGS_STEP_UKNOWS,
  KNOWMIX_SETTINGS_EXAMINERS,
  KNOWMIX_SETTINGS_MEMBERS,
  KNOWMIX_SETTINGS_ADD_MEMBERS,
  KNOWMIX_SETTINGS_PUBLIC,
  KNOWMIX_QUIZ,
  KNOWMIX_QUIZ_REVIEW,
  KNOWMIX_GRADES,
  KNOWMIX_GRADES_CHECK,
  KNOWMIX_CREATE_KNOWCRED,
  KNOWMIX_WALKTHROUGH,
  KNOWMIX_CHECK,
]

export const invenstoryRoutes = [
  INVENSTORY,
  INVENSTORY_GROUPS,
  INVENSTORY_ACCESS,
]

export const calendarRoutes = [
  // HOME,
  CALENDAR,
  CALENDAR_KOURSES,
  CALENDAR_BLOCKS,
  // CALENDAR_HOLDS,
  CALENDAR_TEACHER_SCHEDULE,
]

export const knowmapRoutes = [
  KNOWMAP,
  // KNOWMAP_UKNOW,
  KNOWMAP_UKNOW_STEPS,
  KNOWMAP_UKNOW_KNOWMIX,
  KNOWMAP_KNOWMIX_STEPS,
  KNOWMAP_KNOWMIX,
  KNOWMAP_KNOWCRED,
  KNOWMAP_KNOWCRED_VALID,
  KNOWMAP_KNOWCRED_AWARDED,
  KNOWMAP_INCOMING_KNOWMEET,
  KNOWMAP_MEETS,
  KNOWMAP_INCOMING_MEETS,
  KNOWMAP_INCOMING_MEET,
  KNOWMAP_INCOMING_APPOINTMENT_BOOKINGS,
  KNOWMAP_OUTGOING_APPOINTMENT_BOOKINGS,
  TRACKING_COURSES,
  TRACKING_COURSES_MY_PROGRESS,
  TRACKING_COURSES_MY_GRADES,
  TRACKING_COURSES_SUBMITTED_WORKS,
  TRACKING_COURSES_MANAGED,
  TRACKING_COURSES_STUDENT_PROGRESS,
  TRACKING_COURSES_GRADING,
  TRACKING_COURSES_COURSE_GRADING,
  TRACKING_COURSES_STEPS_GRADING,
  TRACKING_COURSES_CREDS,
]

export const infoRoutes = [
  TERMS_AND_CONDITIONS,
  CONTACT_FORM,
  PRICING,
  APP_PROMOTE,
  ACCOUNTS_INFO,
  ACCOUNTS_INFO_LEARNER,
  ACCOUNTS_INFO_EDUCATOR,
  ACCOUNTS_INFO_AGENT,
  ACCOUNTS_INFO_ORGANIZATION,
  HOW_TO,
  HOW_TO_CATEGORY,
  HOW_TO_ARTICLE,
  TEACHER_INTRO,
]
