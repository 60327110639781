import axios from "axios"
import { store } from "../store/configureStore"
import { queryClient } from "../../index"

export const isDevMode = process.env.NODE_ENV === "development"

const currentTestAPIBase = "testapi.knowstory.com/"
export const isTestAPI = process.env.REACT_APP_API === currentTestAPIBase
export const baseUrl = isTestAPI
  ? "https://beta.knowstory.com"
  : "https://knowstory.com"
export const apiUrl = `https://${process.env.REACT_APP_API}`
// export const apiUrl = `http://localhost:8080/`
export const wsUrl = `wss://${process.env.REACT_APP_API}`
export const nestAPI = process.env.REACT_APP_NODE_API

export const call = async (endpoint, params = {}, n = 1) => {
  const token = store.getState()?.auth?.userData?.token
  delete params.token
  try {
    const response = await axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token || ""}`,
      },
      url: apiUrl + endpoint,
      data: JSON.stringify(params),
    })

    if (response.status === 200) {
      if (response.data.error) {
        throw response.data.error
      }

      if (
        !response.data ||
        !!(response.data.code && response.data.description)
      ) {
        throw "Server error. Unable to fetch data."
      }
    }

    return response
  } catch (error) {
    if (n === 1) {
      if (error?.code === 20003) {
        localStorage.clear()
        queryClient.removeQueries()
        window.location.href = "/"
      }

      if (error.code) {
        throw { error }
      }

      if (!error.response?.data) {
        throw "Server error. Unable to fetch data."
      }

      throw error.response.data
    } else {
      return await call(endpoint, params, n - 1)
    }
  }
}

export const fileCall = async (formData, type, onUploadProgress, token) => {
  try {
    return await axios({
      method: "post",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      onUploadProgress,
      url: apiUrl + "files.upload",
      data: formData,
    })
  } catch (error) {
    throw error
  }
}
