import React, { Component } from "react"
import "./groupHeader.scss"
import bgDefault from "../../../../../../assets/img/bg-default.png"
import CameraAltOutlined from "@material-ui/icons/CameraAltOutlined"
import LanguageOutlined from "@material-ui/icons/LanguageOutlined"
import PlaceOutlined from "@material-ui/icons/PlaceOutlined"
import LocalPhoneOutlined from "@material-ui/icons/LocalPhoneOutlined"
import AccessTime from "@material-ui/icons/AccessTime"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import { GROUP_TYPES } from "../../../../../../library/constants/groupTypes"
import MenuList from "@material-ui/core/MenuList/MenuList"
import MenuItem from "@material-ui/core/MenuItem/MenuItem"
import DropdownControl from "../../../../../../components/ui/dropdowns/dropdownControl/dropdownControl"
import Button from "@material-ui/core/es/Button/Button"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import Dialog from "@material-ui/core/Dialog"
import {
  deleteGroup,
  getGroup,
  groupRequestsCancel,
  joinGroup,
  leaveGroup,
  updateGroup,
} from "../../../../../../library/store/actions/creators/groupsCreators"
import returnExternalUrl from "../../../../../../library/utils/returnExternalUrl"
import ExpandableText from "../../../../../../components/expandableText/expandableText"
import { compact } from "lodash"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import { generatePath, Redirect } from "react-router-dom"
import * as routes from "../../../../../../library/constants/routes"
import { Tooltip, withStyles } from "@material-ui/core"
import { colors } from "../../../../../../library/constants/styles/colors"
import getErrorText from "../../../../../../library/constants/errorTypes"
import defaultDate, {
  defaultMaxDate,
} from "../../../../../../library/constants/defaultDates"
import ImagePickerModal from "../../../../../../components/imagePickerModal/imagePickerModal"
import { imageAspectRatios } from "../../../../../../library/constants/images"
import { format } from "date-fns"
import premiumIcon from "../../../../../../assets/img/icons/check-decagram-outline.svg"

const CustomMenuItem = withStyles({
  root: {
    color: colors.primary,
  },
})(MenuItem)

class GroupHeader extends Component {
  // #TODO is a test build
  state = {
    isMember: this.props.groups.currentGroup.is_member,
    isAdmin: this.props.groups.currentGroup.is_admin,
    isCreator: this.props.groups.currentGroup.is_creator,
    isRequesting: this.props.groups.currentGroup.is_requesting,
    isInvited: this.props.groups.currentGroup.is_invited,
    noRelations: !!(
      !this.props.groups.currentGroup.is_requesting &&
      !this.props.groups.currentGroup.is_member &&
      !this.props.groups.currentGroup.is_admin &&
      !this.props.groups.currentGroup.is_invited
    ),
    closed: this.props.groups.currentGroup.closed,
    header: null,
    openHeaderUpdate: false,
    uploadProgress: 0,
    isUploading: false,

    openGroupDelete: false,
    isGroupDeleted: false,
  }

  composeSchedule = () => {
    const { start_date, end_date } = this.props.groups.currentGroup
    let date = ""

    date +=
      !!start_date && start_date !== defaultDate
        ? format(new Date(start_date), "d MMMM 'at' h:mm aaa")
        : ""
    date += !!(
      end_date &&
      end_date !== start_date &&
      end_date !== defaultMaxDate
    )
      ? ` — ${format(new Date(end_date), "d MMMM 'at' h:mm aaa")}`
      : ""

    return date
  }

  composeAddress = () => {
    const { country, state, city, zip_code, address } =
      this.props.groups.currentGroup

    return compact([
      !!address && address !== "Unknown" && address,
      !!city && city !== "Unknown" && city,
      !!state && state !== "Unknown" && state,
      !!country && country !== "Unknown" && country,
      !!zip_code && zip_code !== "Unknown" && zip_code,
    ]).join(", ")
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve)
    })
  }

  onUploadProgress = (progressEvent) => {
    let percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    )
    this.setState({ uploadProgress: parseInt(percentCompleted) })
  }

  joinGroup = async (id) => {
    const { closed, is_invited } = this.props.groups.currentGroup
    try {
      await this.props.joinGroup({ id })
      await this.props.getGroup({ id })
      if (closed && !is_invited) {
        await this.setStateAsync({
          noRelations: false,
          isRequesting: true,
          isInvited: false,
        })
      } else {
        await this.setStateAsync({
          noRelations: false,
          isMember: true,
          isInvited: false,
        })
      }
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  groupRequestCancel = async (id) => {
    try {
      await this.props.groupRequestsCancel({ group_id: id })
      await this.props.getGroup({ id })
      await this.setStateAsync({ isMember: false, noRelations: true })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  leaveGroup = async (id) => {
    try {
      await this.props.leaveGroup({ id })
      await this.props.getGroup({ id })
      await this.setStateAsync({ isMember: false, noRelations: true })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  initiateGroupDelete = () => {
    this.setState({ openGroupDelete: true })
  }

  handleCloseGroupDelete = () => {
    this.setState({ openGroupDelete: false })
  }

  processDeleteGroup = async () => {
    const { id } = this.props.groups.currentGroup
    try {
      await this.props.deleteGroup({ id })
      this.setState({ isGroupDeleted: true })
      this.props.enqueueSnackbar("Group successfully deleted", {
        variant: "success",
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  handleClickOpen = () => {
    this.setState({ openHeaderUpdate: true })
  }

  handleClose = () => {
    this.setState({ openHeaderUpdate: false })
  }

  updateImage = async (img) => {
    const { id, images, videos, blobs } = this.props.groups.currentGroup
    const attachments = [
      ...videos.map((f) => f.id),
      ...blobs.map((f) => f.id),
      ...images.map((f) => f.id),
    ]

    this.setState({ header: img.cropped_image || bgDefault })
    await this.props.updateGroup({ id, full_header: img, attachments })
  }

  render() {
    const {
      isAdmin,
      isMember,
      isRequesting,
      isInvited,
      noRelations,
      header,
      isGroupDeleted,
      closed,
    } = this.state

    if (isGroupDeleted) {
      return (
        <Redirect
          to={generatePath(routes.GROUPS, {
            userId: this.props.auth.userData.id,
          })}
        />
      )
    }

    const { name, website, phone, type, id } = this.props.groups.currentGroup

    const groupHeader = !header
      ? this.props.groups.currentGroup.header || bgDefault
      : header

    const dropdownControl = () => {
      const buttonLabel = () => {
        if (isMember || isAdmin) {
          return "Member"
        } else if (isRequesting) {
          return "Request sent"
        } else {
          return null
        }
      }

      return (
        <DropdownControl buttonLabel={buttonLabel()}>
          <MenuList>
            {this.props.groups.currentGroup.is_creator && (
              <CustomMenuItem onClick={this.initiateGroupDelete}>
                Delete group
              </CustomMenuItem>
            )}
            {!this.props.groups.currentGroup.is_creator && isMember && (
              <CustomMenuItem onClick={() => this.leaveGroup(id)}>
                Leave the group
              </CustomMenuItem>
            )}
            {isRequesting && (
              <CustomMenuItem onClick={() => this.groupRequestCancel(id)}>
                Cancel request
              </CustomMenuItem>
            )}
          </MenuList>
        </DropdownControl>
      )
    }

    return (
      <div className="box group-profile-header-container">
        <div className="profile-header group-profile-header">
          {/*<div className="profile-header__top" style={{ backgroundImage: `url(${groupHeader})` }}>*/}
          <div
            className="profile-header__top !h-auto !justify-end aspect-page-banner"
            style={{
              backgroundImage: `url(${groupHeader.replace(/ /g, "%20")})`,
            }}
          >
            {isAdmin && (
              <div className="btn-update-bg" onClick={this.handleClickOpen}>
                <CameraAltOutlined />
                <span className="btn-update-bg__text">Edit background</span>
              </div>
            )}
          </div>
          <div className="profile-header__bottom">
            <div className="profile-info profile-info mb15">
              <div className="f fdc aic mb8">
                <div className={"flex items-center gap-1 mb-2"}>
                  {this.props.groups.currentGroup.paid && (
                    <Tooltip title="Paid group" arrow>
                      <img src={premiumIcon} alt="Paid group" />
                    </Tooltip>
                  )}
                  <span className="flex items-center py-1 px-2 rounded-full bg-primary5 text-primary text-lg font-medium leading-none">
                    {GROUP_TYPES[type].title}
                  </span>
                </div>

                <h1 className="font-medium text-2xl">{name}</h1>
              </div>
              <div className="mb8 light">{`This is ${
                closed ? "a closed" : "an open"
              } group`}</div>
              {this.props.groups.currentGroup.description !== "Unknown" && (
                <div className="profile-info__description">
                  <ExpandableText
                    text={this.props.groups.currentGroup.description}
                    limit={500}
                  />
                </div>
              )}
              <div className="profile-info__contact">
                {!!website && (
                  <a href={`${returnExternalUrl(website)}`} className="link">
                    <LanguageOutlined className="color-black-38 mr8" />{" "}
                    <span>{website}</span>
                  </a>
                )}

                {!!phone && (
                  <a href={`tel: ${phone}`} className="link">
                    <LocalPhoneOutlined className="color-black-38 mr8" />{" "}
                    <span>{phone}</span>
                  </a>
                )}

                {type === 2 && (
                  <span className="f aic">
                    <AccessTime className="color-black-38 mr8" />{" "}
                    <span>{this.composeSchedule()}</span>
                  </span>
                )}

                {this.composeAddress() !== "" && (
                  <span className="color-primary f aic jcfs">
                    <PlaceOutlined className="color-black-38 mr8" />{" "}
                    <span>{this.composeAddress()}</span>
                  </span>
                )}
              </div>
            </div>

            {noRelations || isInvited ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.joinGroup(id)}
              >
                Join group
              </Button>
            ) : (
              dropdownControl()
            )}

            {this.props.groups.currentGroup.tags.includes(1) && (
              <p className={"mt15 color-black-54"}>
                You are a follower. Some functions are not available for you.
              </p>
            )}
          </div>
        </div>

        <Dialog
          open={this.state.openGroupDelete}
          onClose={this.handleCloseGroupDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Delete group from site?
          </DialogTitle>
          <DialogContent>
            <p className="color-black-54 font-16">
              If you delete group you will not be able to view information on
              the site. You can restore the group only through the support site.
            </p>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseGroupDelete} color="primary">
              Decline
            </Button>
            <Button onClick={this.processDeleteGroup} color="primary" autoFocus>
              Accept
            </Button>
          </DialogActions>
        </Dialog>

        {this.state.openHeaderUpdate && (
          <ImagePickerModal
            // dimensions={{width: 50, x: 20, y: 20}}
            originImage={groupHeader}
            defaultImage={bgDefault}
            open={this.state.openHeaderUpdate}
            onSave={this.updateImage}
            onClose={this.handleClose}
            aspectRatio={imageAspectRatios.page_banner}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = ({ auth, groups }) => ({ auth, groups })
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getGroup,
      updateGroup,
      joinGroup,
      leaveGroup,
      deleteGroup,
      groupRequestsCancel,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(GroupHeader))
